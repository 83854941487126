import React, { useState } from "react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { FaSpinner, FaXTwitter } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../api/config";
import logo from "../assets/logo/logo.png";

interface SocialIconProps {
  icon: React.ElementType;
}

const SocialIcon: React.FC<SocialIconProps> = ({ icon: Icon }) => (
  <Icon className="social-icon hover:text-[#1889ca]" size={30} />
);

export const PreRegister: React.FC<{
  onClick?: () => void;
  toggleSideBar?: () => void;
}> = ({ onClick, toggleSideBar }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const items = [
    {
      type: "icon",
      icon: FaFacebookSquare,
      url: "https://www.facebook.com/people/RunnMe/61558840046598/",
    },
    {
      type: "icon",
      icon: FaInstagram,
      url: "https://www.instagram.com/runnmemedia/",
    },
    {
      type: "icon",
      icon: FaXTwitter,
      url: "https://twitter.com/RunnMeMedia",
    },
    {
      type: "icon",
      icon: FaLinkedinIn,
      url: "https://www.linkedin.com/company/runnme",
    },
    {
      type: "icon",
      icon: FaYoutube,
      url: "https://www.youtube.com/channel/UCTedqkYv9AW6eNOTIIC3HLQ",
    },
  ];

  const isValidName = (name: string) => {
    const trimmedName = name.trim();
    // Check for empty spaces, numbers, or special characters (allow only letters and spaces)
    return /^[A-Za-z\s]+$/.test(trimmedName) && trimmedName !== "";
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    // Validate name input
    if (!isValidName(name)) {
      toast.error(
        "Name cannot be numbers, special characters or empty spaces."
      );
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${BASE_URL}register-user-only?username=${name}&email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email }),
        }
      );
      const data = await response.json();

      if (response.status === 422) {
        toast.error(data.data.email);
        setIsLoading(false);
        return;
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setIsSubmitted(true);
      toast.success("User registered successfully!");

      setTimeout(() => {
        if (toggleSideBar) {
          toggleSideBar();
        }
        navigate("/");
      }, 3050);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to register. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-[100vh] bg-slate-300 overflow-hidden">
      <ToastContainer
        position="bottom-right"
        newestOnTop
        closeOnClick
        pauseOnHover
        draggable
      />{" "}
      {/* Add the ToastContainer here */}
      {/* Conditionally render the Home button if on /preregister route */}
      {location.pathname === "/preregister" && (
        <div className="w-full pt-8 px-6 text-center">
          <button
            onClick={() => navigate("/")}
            className="text-[#1889ca] hover:text-blue-600 font-semibold"
          >
            Home
          </button>
        </div>
      )}
      {/* Main Pre-register content */}
      <div className="flex flex-col md:items-center md:justify-center min-h-screen h-[100vh] bg-slate-300 p-6">
        <div className="max-w-md w-full text-center">
          {isSubmitted ? (
            <h1 className="text-5xl font-extrabold pb-10">
              Thank you for your submission, we will contact you when we launch.
            </h1>
          ) : (
            <>
              <h1
                className="text-5xl font-extrabold pb-10"
                style={{
                  background: "linear-gradient(to right, #1889ca, black)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                Coming Soon
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <img src={logo} alt="Company Logo" />
                  <div className="mt-8 mb-2 flex-column justify-start text-left">
                    <p className="text-xl text-center pb-2">
                      Get ready for exclusive benefits!
                    </p>
                    <p className="text-center">
                      Join our exclusive pre-registration list by adding your
                      email and get commission-free trips at launch!
                    </p>
                  </div>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                {isLoading ? (
                  <FaSpinner className="h-6 w-6 animate-spin w-full" />
                ) : (
                  <button
                    type="submit"
                    className="w-full bg-[#1889ca] text-white p-2 rounded-md hover:bg-blue-600"
                  >
                    Notify me
                  </button>
                )}
              </form>
            </>
          )}

          <div className="flex-column justify-between pt-[100px]">
            <small>
              Stay connected on social media for more launch updates and special
              offers!
            </small>
            <div className="flex justify-between mt-2">
              {items.map(
                (item, index) =>
                  item.type === "icon" &&
                  item.icon && (
                    <a
                      key={index}
                      target="_blank"
                      href={item.url}
                      rel="noreferrer"
                    >
                      <SocialIcon icon={item.icon} />
                    </a>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
