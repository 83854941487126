import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";

import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { Loader2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  CALCULATE_QUOTE,
  CALCULATE_QUOTE_WITH_PET,
  UPDATE_INSTANT_FREIGHT,
} from "src/api/config";
import { useEditInstantService } from "src/hooks/use-edit-instant-freight";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { z } from "zod";
import { GOOGLE_API_KEY } from "../../api/config";
import { Button } from "../ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Switch } from "../ui/switch";
interface ModalInstantServiceEditProps {
  refetch: () => void;
}
const pickupDropoffSchema = z.object({
  origin: z.any(),
  destination: z.any(),
});
const packageDimensionsSchema = z.object({
  height: z.string().min(1),
  width: z.string().min(1),
  length: z.string().min(1),
  weight: z.string().min(1),
});

const petSchema = z.object({
  petType: z.string().min(1),
  petImage: z.any().optional(),
  petWeight: z.string().min(1),
  pet_has_box: z.boolean(),
});
const ModalInstantServiceEdit: React.FC<ModalInstantServiceEditProps> = ({
  refetch,
}) => {
  const modalEditInstantService = useEditInstantService();
  const navigate = useNavigate();
  const token = useTokenStoreStorage((s) => s.token);
  const { user_id } = userInfoUserStore((s) => s);
  const [loadingQuote, setLoadingQuote] = useState(false);
  const [loadingQuotePet, setLoadingQuotePet] = useState(false);
  const [searchResult, setSearchResult] = useState<any>();
  const [searchDropoffResult, setSearchDropoffResult] = useState<any>();
  const [instantServiceLocation, setInstantSeviceLocation] = useState();
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });
  const [startAddress, setStartAddress] = useState<any>();
  const [endAddress, setEndAddress] = useState<any>();
  const [originPosition, setOriginPosition] = useState<{
    lat: number;
    lng: number;
  }>();
  const [destinationPosition, setDestinationPosition] = useState<{
    lat: number;
    lng: number;
  }>();
  const [startCityAddress, setStartCityAddress] = useState();
  const [endCityAddress, setEndCityAddress] = useState();
  const originRef = useRef<any>(null);
  const destinationRef = useRef<any>(null);
  const [zipCodeStartAddress, setZipCodeStartAddress] = useState("");
  const [zipCodeEndAddress, setZipCodeEndAddress] = useState("");
  const [normalQuote, setNormalQuote] = useState("");
  const [petQuote, setPetQuote] = useState("");
  const [dimensionsDetails, setDimensionsDetails] = useState<any>();
  const [petImage, setPetImage] = useState<any>();
  const [selectedPetDetails, setSelectedPetDetails] = useState<any>();

  function onLoad(autoComplete: any) {
    setSearchResult(autoComplete);
  }
  function onLoadDropoff(autocomplete: any) {
    setSearchDropoffResult(autocomplete);
  }
  const formOriginDestination = useForm<z.infer<typeof pickupDropoffSchema>>({
    resolver: zodResolver(pickupDropoffSchema),
    defaultValues: {
      destination: "",
      origin: "",
    },
  });

  const petDetails = useForm<z.infer<typeof petSchema>>({
    resolver: zodResolver(petSchema),
    defaultValues: {
      pet_has_box: false,
      petImage: "",
      petType: "",
      petWeight: "",
    },
  });
  const packageDimensionsDetails = useForm<
    z.infer<typeof packageDimensionsSchema>
  >({
    resolver: zodResolver(packageDimensionsSchema),
    defaultValues: {
      height: "",
      length: "",
      width: "",
      weight: "",
    },
  });

  function onPlaceChanged() {
    if (searchResult !== null && searchResult !== undefined) {
      formOriginDestination.setValue("origin", searchResult?.getPlace().name);
      setInstantSeviceLocation((prev: any) => ({
        ...prev,
        originLat: searchResult?.getPlace().geometry?.location.lat(),
        originLng: searchResult?.getPlace().geometry?.location.lng(),
        originName: searchResult?.getPlace().name,
        originAddress: searchResult?.getPlace().formatted_address,
        originCity: searchResult?.getPlace().name,
      }));
    }
  }

  function onPlaceChangedDropoff() {
    if (searchDropoffResult !== null && searchDropoffResult !== undefined) {
      formOriginDestination.setValue(
        "destination",
        searchDropoffResult?.getPlace().name
      );

      setInstantSeviceLocation((prev: any) => ({
        ...prev,
        destinationLat: searchDropoffResult
          ?.getPlace()
          .geometry?.location.lat(),
        destinationLng: searchDropoffResult
          ?.getPlace()
          .geometry?.location.lng(),
        destinationName: searchDropoffResult?.getPlace().name,
        destinationAddress: searchDropoffResult?.getPlace().formatted_address,
        destinationCity: searchDropoffResult?.getPlace().name,
      }));
    }
  }

  useEffect(() => {
    if (startAddress !== "") {
      //@ts-ignore
      setZipCodeStartAddress(`${startAddress}`.match(/\b\d{5}\b/g)?.[0]);
    }
    if (endAddress !== "") {
      //@ts-ignore
      setZipCodeEndAddress(`${endAddress}`.match(/\b\d{5}\b/g)?.[0]);
    }
  }, [startAddress, endAddress]);

  const calculateQuote = async (distance?: string) => {
    setLoadingQuote(true);
    const response = await fetch(`${BASE_URL}${CALCULATE_QUOTE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        weight: modalEditInstantService.data?.package_weight,
        width: modalEditInstantService.data?.package_width,
        height: modalEditInstantService.data?.package_height,
        length: modalEditInstantService.data?.package_length,
        kms: distance!.split(" ")?.[0],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // if (data.message === "Pet delivery cost generated successfully") {
        setNormalQuote(data.data.estimated_quote);
        // }
        updateInstantFreight();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingQuote(false);
      });

    return response;
  };

  const calculateQuoteWithPet = async (distance?: string) => {
    setLoadingQuotePet(true);
    const response = await fetch(
      `${BASE_URL}${CALCULATE_QUOTE_WITH_PET}?user_id=${user_id}&kms=${distance!
        .split(" ")?.[0]
        .replaceAll(",", "")}&pet_type=${
        modalEditInstantService.data?.pet_type
      }&has_cage=${modalEditInstantService.data?.has_cage}&pet_weight=${
        modalEditInstantService.data?.pet_weight
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Pet delivery cost generated successfully") {
          setPetQuote(data.data.estimated_quote);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingQuotePet(false);
      });

    return response;
  };
  const updateInstantFreight = async () => {
    const response = await fetch(`${BASE_URL}${UPDATE_INSTANT_FREIGHT}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },

      body: JSON.stringify({
        user_id: user_id,
        customer_id: user_id,
        instant_freight_id: modalEditInstantService.data?.id,
        // origin_address: instantServiceLocation.originAddress,
        // origin_zipcode: zipCodeStartAddress,
        // origin_lat: instantServiceLocation.originLat,
        // origin_lng: instantServiceLocation.originLng,
        // destination_lat: instantServiceLocation.destinationLat,
        // destination_lng: instantServiceLocation.destinationLng,
        // destination_city: instantServiceLocation.destinationName,
        // destination_address: instantServiceLocation.destinationAddress,
        // destination_zipcode: zipCodeEndAddress,
        // is_valuable: false,
        // package_size: packageDetails.dimensions,
        // package_weight: packageDetails.weight,
        // delivery_distance: distance,
        // delivery_duration: duration,
        // estimated_price: instantItem.estimated_price,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Instant freight updated successfully") {
          navigate(`/instant-freights/${modalEditInstantService.data?.id}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  async function calculateRuote() {
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: {
        lat: searchResult?.getPlace()?.geometry?.location.lat(),
        lng: searchResult?.getPlace()?.geometry?.location.lng(),
      },
      destination: {
        lat: searchDropoffResult?.getPlace()?.geometry?.location.lat(),
        lng: searchDropoffResult?.getPlace()?.geometry?.location.lng(),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    //@ts-ignore
    setDirectionsResponse(results);
    //@ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);

    setStartCityAddress(searchResult?.getPlace().formatted_address);
    setEndCityAddress(searchDropoffResult?.getPlace().formatted_address);

    //@ts-ignore
    setDuration(results.routes[0].legs[0].duration?.text);

    //@ts-ignore
    setStartAddress(results.routes[0].legs[0].start_address);
    setEndAddress(results.routes[0].legs[0].end_address);

    setOriginPosition({
      lat: searchResult?.getPlace().geometry?.location.lat(),
      lng: searchResult?.getPlace().geometry?.location.lng(),
    });

    setDestinationPosition({
      lat: searchDropoffResult?.getPlace().geometry?.location.lat(),
      lng: searchDropoffResult?.getPlace().geometry?.location.lng(),
    });

    calculateQuote(results.routes[0].legs[0].distance?.text);
    if (modalEditInstantService.data?.trip_type === "pet") {
      calculateQuoteWithPet(results.routes[0].legs[0].distance?.text);
    }
  }

  const onSubmitOriginDestination = async (
    values: z.infer<typeof pickupDropoffSchema>
  ) => {
    calculateRuote();
  };

  useEffect(() => {
    packageDimensionsDetails.reset({
      height: modalEditInstantService.data?.package_height!.toString(),
      length: modalEditInstantService.data?.package_length!.toString(),
      weight: modalEditInstantService.data?.package_weight!.toString(),
      width: modalEditInstantService.data?.package_width!.toString(),
    });
  }, [modalEditInstantService.data]);
  useEffect(() => {
    petDetails.reset({
      pet_has_box: modalEditInstantService.data?.has_cage === 1 ? true : false,
      petType: modalEditInstantService.data?.pet_type!.toString(),
      petWeight: modalEditInstantService.data?.pet_weight!.toString(),
      petImage: modalEditInstantService.data?.pet_img!.toString(),
    });
  }, [modalEditInstantService.data]);

  const onSubmitDimensions = async (
    values: z.infer<typeof packageDimensionsSchema>
  ) => {
    setDimensionsDetails({
      width: values.width,
      length: values.length,
      height: values.height,
      weight: values.weight,
    });
  };

  const onSubmitPets = async (values: z.infer<typeof petSchema>) => {
    setSelectedPetDetails({
      petWeight: values.petWeight,
      petType: values.petType,
      petHasBox: values.pet_has_box,
      petImage: values.petImage,
    });
  };
  return (
    <Dialog
      open={modalEditInstantService.isOpen}
      onOpenChange={modalEditInstantService.onClose}
    >
      <DialogContent className="w-[600px]">
        <DialogHeader>
          <div className="flex flex-col items-center">
            {modalEditInstantService.editType === "direction" && (
              <div className="sm:flex sm:flex-col mx-4 mt-6 w-full">
                <Form {...formOriginDestination}>
                  <form
                    onSubmit={formOriginDestination.handleSubmit(
                      onSubmitOriginDestination
                    )}
                  >
                    <div className="gap-10 w-full">
                      <div className="flex flex-col items-start w-full">
                        <h1 className="text-sm text-left font-medium border-b-2 mb-4 pb-2  w-full">
                          Direction
                        </h1>
                        <div className="w-full mb-4">
                          {isLoaded && (
                            <Controller
                              control={formOriginDestination.control}
                              name="origin"
                              render={({ field }) => (
                                <>
                                  <Autocomplete
                                    {...field}
                                    onPlaceChanged={() => {
                                      onPlaceChanged();
                                    }}
                                    onLoad={onLoad}
                                    options={{
                                      fields: [
                                        "address_components",
                                        "geometry",
                                        "formatted_address",
                                        "types",
                                      ],
                                      componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                                    }}
                                  >
                                    <Input
                                      placeholder={`${modalEditInstantService.data?.origin_city}`}
                                      ref={originRef}
                                    />
                                  </Autocomplete>
                                </>
                              )}
                            />
                          )}
                        </div>
                        <div className="w-full mb-4">
                          {isLoaded && (
                            <Controller
                              control={formOriginDestination.control}
                              name="destination"
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  onPlaceChanged={onPlaceChangedDropoff}
                                  onLoad={(val) => {
                                    formOriginDestination.setValue(
                                      "destination",
                                      val?.getPlace()?.name
                                    );
                                    onLoadDropoff(val);
                                  }}
                                >
                                  <Input
                                    placeholder={`${modalEditInstantService.data?.destination_city}`}
                                    ref={destinationRef}
                                  />
                                </Autocomplete>
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-full justify-end items-end flex space-x-2">
                      <Button
                        onClick={() => {
                          modalEditInstantService.onClose();
                        }}
                        variant={"outline"}
                      >
                        Back
                      </Button>
                      <Button type="submit" variant={"freightBlue"}>
                        {loadingQuote ? (
                          <>
                            <Loader2 className="h-5 w-5 animate-spin" />
                          </>
                        ) : (
                          <p className="text-sm font-medium">Save</p>
                        )}
                      </Button>
                    </div>
                    {/* <Button
                      type="submit"
                      variant={"freightBlue"}
                      className="mt-4 sm:justify-end sm:ml-auto mb-4"
                    >
                      {loadingQuote ? (
                        <>
                          <Loader2 className="h-5 w-5 animate-spin" />
                        </>
                      ) : (
                        <p className="text-sm font-medium">
                          Describe your package
                        </p>
                      )}
                    </Button> */}
                  </form>
                </Form>
              </div>
            )}
            {modalEditInstantService.editType === "package" && (
              <div className="flex flex-col">
                <h1 className="text-sm text-left font-medium border-b-2 mb-4 pb-2  w-full">
                  Package Details
                </h1>
                <Form {...packageDimensionsDetails}>
                  <form
                    className="w-full space-y-6"
                    onSubmit={packageDimensionsDetails.handleSubmit(
                      onSubmitDimensions
                    )}
                  >
                    <div className="flex flex-col space-y-6">
                      {/* Dimensions Inputs */}
                      <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
                        <FormField
                          name="height"
                          control={packageDimensionsDetails.control}
                          render={({ field, fieldState }) => (
                            <FormItem>
                              <FormLabel>Height (cm)</FormLabel>
                              <FormControl>
                                <Input
                                  className="w-full sm:w-52"
                                  type="number"
                                  max={30}
                                  min={0}
                                  {...field}
                                  placeholder="Parcel Height"
                                />
                              </FormControl>
                              {fieldState.error && (
                                <p className="text-xs text-red-500 mt-1">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </FormItem>
                          )}
                        />
                        <FormField
                          name="width"
                          control={packageDimensionsDetails.control}
                          render={({ field, fieldState }) => (
                            <FormItem>
                              <FormLabel>Width (cm)</FormLabel>
                              <FormControl>
                                <Input
                                  className="w-full sm:w-52"
                                  type="number"
                                  max={30}
                                  min={0}
                                  {...field}
                                  placeholder="Parcel Width"
                                />
                              </FormControl>
                              {fieldState.error && (
                                <p className="text-xs text-red-500 mt-1">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </FormItem>
                          )}
                        />
                      </div>{" "}
                      <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
                        <FormField
                          name="length"
                          control={packageDimensionsDetails.control}
                          render={({ field, fieldState }) => (
                            <FormItem>
                              <FormLabel>Length (cm)</FormLabel>
                              <FormControl>
                                <Input
                                  className="w-full sm:w-52"
                                  type="number"
                                  max={30}
                                  min={0}
                                  {...field}
                                  placeholder="Parcel Length"
                                />
                              </FormControl>
                              {fieldState.error && (
                                <p className="text-xs text-red-500 mt-1">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </FormItem>
                          )}
                        />

                        {/* Weight Input */}
                        <FormField
                          name="weight"
                          control={packageDimensionsDetails.control}
                          render={({ field, fieldState }) => (
                            <FormItem>
                              <FormLabel>Weight (kg)</FormLabel>
                              <FormControl>
                                <Input
                                  className="w-full sm:w-52"
                                  type="number"
                                  max={30}
                                  min={0}
                                  {...field}
                                  placeholder="Parcel Weight"
                                />
                              </FormControl>
                              {fieldState.error && (
                                <p className="text-xs text-red-500 mt-1">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="w-full justify-end items-end flex space-x-2">
                        <Button
                          onClick={() => {
                            modalEditInstantService.onClose();
                          }}
                          variant={"outline"}
                        >
                          Back
                        </Button>
                        <Button
                          disabled={
                            !packageDimensionsDetails.getValues("height") ||
                            !packageDimensionsDetails.getValues("width") ||
                            !packageDimensionsDetails.getValues("length") ||
                            !packageDimensionsDetails.getValues("weight")
                          }
                          type="submit"
                          variant={"freightBlue"}
                        >
                          {loadingQuote ? (
                            <>
                              <Loader2 className="h-5 w-5 animate-spin" />
                            </>
                          ) : (
                            <p className="text-sm font-medium">Save</p>
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                </Form>
              </div>
            )}
            {modalEditInstantService.editType === "pet" && (
              <div className="flex flex-col">
                <h1 className="text-sm text-left font-medium border-b-2 mb-4 pb-2  w-full">
                  Pet Details
                </h1>
                <Form {...petDetails}>
                  <form
                    onSubmit={petDetails.handleSubmit(onSubmitPets)}
                    className=" bg-white "
                  >
                    <div className="flex flex-col space-y-6">
                      {/* Pet Details Section */}
                      <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6">
                        <Controller
                          name="petType"
                          control={petDetails.control}
                          render={({ field, fieldState }) => (
                            <FormItem className="flex-1">
                              <FormLabel>Pet Type</FormLabel>
                              <Select
                                onValueChange={(value) =>
                                  petDetails.setValue("petType", value)
                                }
                                {...field}
                              >
                                <SelectTrigger className="w-full sm:w-60">
                                  <SelectValue placeholder="Ex: Dog" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Cat">Cat</SelectItem>
                                  <SelectItem value="Dog">Dog</SelectItem>
                                </SelectContent>
                              </Select>
                              {fieldState.error && (
                                <p className="text-xs text-red-500 mt-1">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </FormItem>
                          )}
                        />

                        <Controller
                          name="petWeight"
                          control={petDetails.control}
                          render={({ field }) => (
                            <FormItem className="flex-1">
                              <FormLabel>Weight (kg)</FormLabel>
                              <FormControl>
                                <Input
                                  className="w-full"
                                  type="number"
                                  {...field}
                                  placeholder="Pet Weight"
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                      <Controller
                        control={petDetails.control}
                        name="pet_has_box"
                        render={({ field }) => (
                          <div className="flex items-center space-x-2">
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                              color="#0022B6"
                            />
                            <h1 className="text-sm font-medium">
                              Box Available
                            </h1>
                          </div>
                        )}
                      />

                      {/* Pet Image Section */}
                      <div className="flex flex-col space-y-4 sm:flex-row items-start sm:space-x-6">
                        <div className="flex-1">
                          <p className="text-sm font-medium mb-2">Pet Image</p>
                          <Controller
                            control={petDetails.control}
                            name="petImage"
                            render={({ field }) => (
                              <div className="border border-dashed border-gray-300 rounded-lg p-6">
                                <div className="text-center">
                                  <svg
                                    className="mx-auto h-12 w-12 text-gray-300"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <div className="mt-4 flex flex-col items-center text-sm text-gray-600">
                                    <label className="cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600">
                                      <span>Upload a file</span>
                                      <Input
                                        accept="image/png, image/jpeg, image/gif"
                                        {...field}
                                        value={field.value.fileName}
                                        type="file"
                                        onChange={(event: any) => {
                                          field.onChange(
                                            event.target.files?.[0]
                                          );
                                          setPetImage(
                                            URL?.createObjectURL(
                                              event.target.files?.[0]
                                            )
                                          );
                                        }}
                                        className="sr-only"
                                      />
                                    </label>
                                    <p className="mt-1">or drag and drop</p>
                                    <p className="text-xs text-gray-500">
                                      PNG, JPG, GIF up to 10MB
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </div>

                        {petImage && (
                          <img
                            src={petImage}
                            className="h-32 w-[60%] object-cover rounded-lg mt-4 "
                            alt="pet"
                          />
                        )}
                      </div>

                      <div className="w-full justify-end items-end flex space-x-2">
                        <Button
                          onClick={() => {
                            modalEditInstantService.onClose();
                          }}
                          variant={"outline"}
                        >
                          Back
                        </Button>
                        <Button
                          disabled={
                            !petDetails.getValues("petType") ||
                            !petDetails.getValues("petWeight")
                          }
                          type="submit"
                          variant={"freightBlue"}
                        >
                          {loadingQuote ? (
                            <>
                              <Loader2 className="h-5 w-5 animate-spin" />
                            </>
                          ) : (
                            <p className="text-sm font-medium">Save</p>
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                </Form>
              </div>
            )}
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalInstantServiceEdit;
