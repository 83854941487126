import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { Check, ChevronLeft, Euro, Loader2 } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as z from "zod";
import {
  ALL_ROUTES,
  BASE_URL,
  GOOGLE_API_KEY,
  TRANSPORTER_CREATE_STOP,
} from "../api/config";
import Header from "../components/header/Header";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import useLocalStorage from "../hooks/use-local-storage";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

const pickupDropoffSchema = z.object({
  // origin: z.any(),
  destination: z.any(),
});
const containerStyle = {
  Maxwidth: "400px",
  height: "340px",
  borderRadius: "4px",
};

const packageSchema = z.object({
  packageWeight: z.string().min(1),
  packagePrice: z.string().min(1),
  packageSize: z.string().min(1),
});
const center = {
  lat: -3.745,
  lng: -38.523,
};

const TripPackageStops = () => {
  const params = useParams();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState<any>([]);
  const [numStops, setNumStops] = useState(1);
  //   const [stops, setStops] = useState<any>([]);
  const [tripPackageDetails, setTripPackageDetails] = useLocalStorage(
    "trip_package_details"
  );
  const originRef = useRef<any>(null);
  const destinationRef = useRef<any>(null);
  const [addPackage, setAddPackage] = useState(false);
  const [successfullyCreated, setSuccessfullyCreated] = useState(false);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [originZipcode, setOriginZipcode] = useState("");
  const [destinationZipcode, setDestinationZipcode] = useState("");
  // const [searchResult, setSearchResult] = useState<any>();
  const [searchDropoffResult, setSearchDropoffResult] = useState<any>();
  const [showAddPackage, setShowAddPackage] = useState(false);
  const [locationPackage, setLocationPackage] = useState({
    originName: "",
    originLat: "",
    originLng: "",
    originAddress: "",
    destinationName: "",
    destinationLat: "",
    destinationLng: "",
    destinationAddress: "",
  });
  const [directionResponse, setDirectionsResponse] = useState<any>(null);
  const autoCompleteRef = useRef();
  const [loadingCreationRoute, setLoadingCreationRoute] = useState(false);
  const [map, setMap] = useState(null);
  const [loadingCreatingStop, setLoadingCreatingStop] = useState(false);
  const [showDistance, setShowDistance] = useState(false);

  const onLoad = useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: Number(route?.origin_lat),
      lng: Number(route?.origin_lng),
    });
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const getRoute = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?sort=desc&route_id=${params.routeId}&user_id=${user_id}`,
      {
        headers: {},
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const formPackage = useForm<z.infer<typeof packageSchema>>({
    resolver: zodResolver(packageSchema),
    defaultValues: {
      packagePrice: "",
      packageSize: "",
      packageWeight: "",
    },
  });

  const formOriginDestinationStop = useForm<
    z.infer<typeof pickupDropoffSchema>
  >({
    resolver: zodResolver(pickupDropoffSchema),
    defaultValues: {
      destination: "",
      // origin: "",
    },
  });

  // function onLoad(autoComplete: any) {
  //   setSearchResult(autoComplete);
  // }

  function onLoadDropoff(autocomplete: any) {
    setSearchDropoffResult(autocomplete);
  }

  const {
    data: route,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["route", params.routeId],
    queryFn: getRoute,
  });

  useEffect(() => {
    refetch();
  }, []);

  // function onPlaceChanged() {
  //   if (searchResult !== null && searchResult !== undefined) {
  //     formOriginDestinationStop.setValue(
  //       "origin",
  //       searchResult?.getPlace().name
  //     );

  //   }
  // }

  // function onPlaceChangedDropoff() {
  //   if (searchDropoffResult !== null && searchDropoffResult !== undefined) {
  //     formOriginDestinationStop.setValue(
  //       "destination",
  //       searchDropoffResult?.getPlace().name
  //     );

  //     setLocationPackage((prev) => ({
  //       ...prev,
  //       destinationLat: searchDropoffResult
  //         ?.getPlace()
  //         .geometry??.location.lat(),
  //       destinationLng: searchDropoffResult
  //         ?.getPlace()
  //         .geometry??.location.lng(),
  //       destinationName: searchDropoffResult?.getPlace().name,
  //       destinationAddress: searchDropoffResult?.getPlace().formatted_address,
  //       destinationCity: searchDropoffResult?.getPlace().name,
  //     }));
  //   }
  // }

  const createStop = async (
    packageWeight: string,
    packageSize: string,
    packagePrice: string
  ) => {
    setLoadingCreatingStop(true);
    const response = await fetch(`${BASE_URL}${TRANSPORTER_CREATE_STOP}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        origin_lat: route?.data?.routes[0].origin_lat,
        origin_lng: route?.data?.routes[0].origin_lng,
        origin_address: route?.data.routes[0].origin_address,
        origin_city: route?.data?.routes[0].origin_city,
        origin_zipcode: route?.data?.routes[0].origin_zipcode,
        destination_lat: locationPackage.destinationLat,
        destination_lng: locationPackage.destinationLng,
        destination_zipcode: destinationZipcode,
        destination_address: locationPackage.destinationAddress,
        destination_city: locationPackage.destinationName,
        price: packagePrice,
        package_size: packageSize,
        package_weight: packageWeight,
        user_id: user_id,
        delivery_distance: distance,
        delivery_duration: duration,
        transporter_route_id: params.routeId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Customer stop created successfully") {
          setSuccessfullyCreated(true);
          setTimeout(() => {
            navigate(`/routes/trips/success/${params.routeId}`);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingCreatingStop(false);
      });

    return response;
  };

  async function calculateRouteForMap() {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(route?.data?.routes[0]?.origin_lat),
        lng: Number(route?.data?.routes[0]?.origin_lng),
      },
      destination: {
        lat: Number(route?.data?.routes[0]?.destination_lat),
        lng: Number(route?.data?.routes[0]?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
  }

  useEffect(() => {
    if (isLoaded) {
      calculateRouteForMap();
    }
  }, [route]);

  useEffect(() => {
    if (locationPackage.destinationAddress !== "") {
      calculateRoute();
    }
  }, [locationPackage]);

  const [formValues, setFormValues] = useState([{ stop1: "" }]);

  const onSubmit = async (values: z.infer<typeof pickupDropoffSchema>) => {
    if (values.destination) {
      calculateRoute();

      setTripPackageDetails({
        // origin: values.origin,
        destination: values.destination,
        originLat: locationPackage.originLat,
        originLng: locationPackage.originLng,

        destinationLat: locationPackage.destinationLat,
        originAddress: locationPackage.originAddress,
        originCity: locationPackage.originName,
        destinationLng: locationPackage.destinationLng,
        destinationAddress: locationPackage.destinationAddress,
        destinationCity: locationPackage.destinationName,
      });
    }
  };

  const onSubmitTripPackage = async (values: z.infer<typeof packageSchema>) => {
    createStop(values.packageWeight, values.packageSize, values.packagePrice);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setShowAddPackage(true);

    setTripPackageDetails({
      originLat: locationPackage.originLat,
      originLng: locationPackage.originLng,

      destinationLat: locationPackage.destinationLat,
      originAddress: locationPackage.originAddress,
      originCity: locationPackage.originName,
      destinationLng: locationPackage.destinationLng,
      destinationAddress: locationPackage.destinationAddress,
      destinationCity: locationPackage.destinationName,
    });
  };

  const onLoadAutocomplete = (autoComplete: any) => {
    autoCompleteRef.current = autoComplete;
  };

  const handlePlaceChange = () => {
    setLocationPackage((prev) => ({
      ...prev,

      destinationLat: autoCompleteRef.current
        //@ts-ignore
        ?.getPlace()
        .geometry?.location.lat(),
      destinationLng: autoCompleteRef.current
        //@ts-ignore
        ?.getPlace()
        .geometry?.location.lng(),
      //@ts-ignore
      destinationAddress: autoCompleteRef.current?.getPlace().formatted_address,
      //@ts-ignore
      destinationName: autoCompleteRef.current?.getPlace().name,
      //@ts-ignore
      destinationCity: autoCompleteRef.current?.getPlace().name,
    }));
  };
  async function calculateRoute() {
    if (locationPackage.destinationAddress === "") return;
    setLoadingCreationRoute(true);
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: {
        lat: Number(route?.data.routes[0].origin_lat),
        lng: Number(route?.data.routes[0].origin_lng),
      },
      destination: {
        //@ts-ignore
        lat: locationPackage?.destinationLat,
        //@ts-ignore
        lng: locationPackage.destinationLng,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });
    //@ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);
    //@ts-ignore
    setDuration(results.routes[0].legs[0].duration.text);
    setOriginZipcode(
      //@ts-ignore
      `${results.routes[0].legs[0].start_address}`.match(/\b\d{5}\b/g)[0]
    );

    setDestinationZipcode(
      //@ts-ignore
      `${results.routes[0].legs[0].end_address}`.match(/\b\d{5}\b/g)[0]
    );
    setTimeout(() => {
      setLoadingCreationRoute(false);
      setShowDistance(true);

      setShowAddPackage(true);
    }, 2000);
  }

  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] no-scrollbar">
        <div
          onClick={() => navigate(`/carrier/trips/${params.routeId}`)}
          className="flex items-center space-x-2 group cursor-pointer mb-4"
        >
          <ChevronLeft className="text-freightBlueDark h-5 w-5" />
          <h1 className="text-freightBlueDark text-sm group-hover:underline ">
            Back
          </h1>
        </div>

        <div className="flex ites-center justify-between mt-10">
          <h1 className="text-2xl font-medium text-gray-500">
            Trips stop deliveries
          </h1>
          <div>
            <h1>Route</h1>
            {isLoading ? (
              <div className="flex items-center space-x-2">
                <Skeleton className="h-5 w-20" />
                <Skeleton className="h-5 w-20" />
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <p className="text-sm font-medium">
                  {route?.data?.routes[0].origin_city},{" "}
                  {route?.data?.routes[0].origin_zipcode} -{" "}
                  {route?.data?.routes[0].destination_city},{" "}
                  {route?.data?.routes[0].destination_zipcode}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="bg-white shadow p-4 mt-10">
          <div className="sm:flex sm:flex-col ml-4">
            <Form {...formOriginDestinationStop}>
              <form
                className="flex flex-col w-full"
                onSubmit={formOriginDestinationStop.handleSubmit(onSubmit)}
              >
                <div className="sm:grid sm:grid-cols-2 gap-10 w-full">
                  <div>
                    <h1 className="text-lg font-medium border-b-2 mb-4 pb-2">
                      Stop's Direction{" "}
                    </h1>
                    <div className="w-full mb-4">
                      <h1 className="text-[15px] font-medium text-gray-500">
                        Origin
                      </h1>
                      {isLoading ? (
                        <>
                          <Skeleton className="h-5 w-full mt-2" />
                        </>
                      ) : (
                        <h3 className="text-sm cursor-not-allowed opacity-70 font-medium mt-2 border border-border p-2 pl-3 rounded">
                          {route?.data?.routes[0].origin_address}
                        </h3>
                      )}
                    </div>
                    <div className="w-full mb-4">
                      <h1 className="text-[15px] font-medium mb-2 text-gray-500">
                        Where will you stop?
                      </h1>
                      {isLoaded && (
                        <Controller
                          name="destination"
                          control={formOriginDestinationStop.control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              onLoad={(val) => {
                                onLoadAutocomplete(val);
                                formOriginDestinationStop.setValue(
                                  "destination",
                                  val
                                );
                              }}
                              onPlaceChanged={handlePlaceChange}
                              options={{
                                fields: [
                                  "address_components",
                                  "geometry",
                                  "formatted_address",
                                  "types",
                                ],
                                componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                              }}
                            >
                              <Input
                                type="text"
                                placeholder="Search destination"
                              />
                            </Autocomplete>
                          )}
                        />
                      )}
                    </div>

                    <div
                      className={cn(
                        "w-full mb-4",
                        showDistance
                          ? "opacity-100 translate-y-2"
                          : "opacity-0 translate-y-0"
                      )}
                    >
                      <h1 className="text-[15px] font-medium mb-2 text-gray-500">
                        Distance: {distance}
                      </h1>
                      <h1 className="text-[15px] font-medium mb-2 text-gray-500">
                        Duration: {duration}
                      </h1>
                    </div>
                  </div>
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: Number(route?.origin_lat),
                        lng: Number(route?.origin_lng),
                      }}
                      options={{
                        streetViewControl: false,
                      }}
                      zoom={15}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      {showAddPackage && (
                        <Marker
                          icon={{
                            url: require("./../assets/images/box.png"),
                            fillColor: "#EB00FF",
                            scale: 7,
                          }}
                          //@ts-ignore
                          title={autoCompleteRef.current?.getPlace().name}
                          position={{
                            lat: Number(
                              autoCompleteRef.current
                                //@ts-ignore
                                ?.getPlace()
                                .geometry?.location.lat()
                            ),
                            lng: Number(
                              //@ts-ignore
                              autoCompleteRef?.current
                                .getPlace()
                                .geometry?.location.lng()
                            ),
                          }}
                        />
                      )}

                      {directionResponse && (
                        <DirectionsRenderer directions={directionResponse} />
                      )}
                    </GoogleMap>
                  ) : null}
                </div>
                {!showAddPackage && (
                  <Button
                    //@ts-ignore
                    disabled={
                      locationPackage.destinationAddress === "" ||
                      loadingCreationRoute ||
                      showAddPackage
                    }
                    variant={"freightBlue"}
                    className="ml-auto mt-2"
                  >
                    {loadingCreationRoute ? (
                      <div>
                        <Loader2 className="h-5 w-5 animate-spin" />
                      </div>
                    ) : (
                      <> NEXT: PACKAGE</>
                    )}
                  </Button>
                )}
              </form>
            </Form>
            {showAddPackage && (
              <div className="border-t-2 border-border mt-4 pt-2">
                <h1 className="text-lg font-semibold">Add Package</h1>
                <Form {...formPackage}>
                  <form
                    className="flex flex-col w-full space-y-10  border-border mt-10 pt-2"
                    onSubmit={formPackage.handleSubmit(onSubmitTripPackage)}
                  >
                    <div className="flex flex-col w-full space-y-4">
                      <Controller
                        control={formPackage.control}
                        name="packageSize"
                        render={({ field }) => (
                          <Select
                            {...field}
                            onValueChange={(val) => {
                              formPackage.setValue("packageSize", val);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select Size" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="small">Small</SelectItem>
                              <SelectItem value="medium">Medium</SelectItem>
                              <SelectItem value="large">Large</SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <FormField
                        control={formPackage.control}
                        name="packageWeight"
                        render={({ field }) => (
                          <FormItem {...field}>
                            <FormLabel>Package Weight</FormLabel>
                            <FormControl>
                              <div className="flex items-center">
                                <Input
                                  type="number"
                                  {...field}
                                  name="packageWeight"
                                  className="w-full"
                                />
                                <p className="text-sm font-medium ml-2">kg</p>
                              </div>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={formPackage.control}
                        name="packagePrice"
                        render={({ field }) => (
                          <FormItem {...field}>
                            <FormLabel>Package Price</FormLabel>
                            <FormControl>
                              <div className="flex items-center">
                                <Input
                                  type="number"
                                  {...field}
                                  name="packagePrice"
                                  className="w-full"
                                />
                                <Euro className="h-4 w-4 ml-2" />
                              </div>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>

                    <Button
                      disabled={loading}
                      variant={"freightBlue"}
                      className={cn(
                        "w-60 ml-auto  mt-10",
                        successfullyCreated && "bg-green-500"
                      )}
                    >
                      {loading ? (
                        <>
                          <Loader2 className="h-5 w-5 animate-spin" />
                        </>
                      ) : (
                        <>
                          {successfullyCreated ? (
                            <>
                              <Check className="text-white" />
                            </>
                          ) : (
                            <>
                              {loadingCreatingStop ? (
                                <>
                                  <Loader2 className="animate-spin h-5 w-5" />
                                </>
                              ) : (
                                <p className="text-sm font-medium">
                                  Add package
                                </p>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Button>
                  </form>
                </Form>
              </div>
            )}
          </div>
        </div>
        {/* <div className="bg-white shadow p-4 mt-10">
          <div className="grid  gap-10 w-full">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col w-full space-y-4"
            >
              {formValues.map((element, index) => (
                <div className="flex items-end space-x-2" key={index}>
                  <div className="flex flex-col items-start">
                    <p className="text-sm font-medium mb-2">Stop {index}</p>
                    <Input
                      className="w-60"
                      type="text"
                      name="stop1"
                      value={element.stop1 || ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                  {index ? (
                    <Button
                      type="button"
                      className="button remove"
                      onClick={() => removeFormFields(index)}
                    >
                      Remove
                    </Button>
                  ) : null}
                </div>
              ))}
              <div className="mt-4 justify-end flex items-center space-x-2">
                <Button
                  variant={"freightGreen"}
                  onClick={() => addFormFields()}
                >
                  <Plus className="h-5 w-5" />
                  Add
                </Button>
                <Button variant={"freightBlue"} type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TripPackageStops;
