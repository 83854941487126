import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { Loader } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import "../styles/transitions.css";

import { GOOGLE_API_KEY } from "../api/config";

import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
//@ts-ignore
import { useAtom } from "jotai";
import Package from "src/assets/images/package.png";

import {
  dimensionsInfo,
  formDataAtom,
  petInfo,
  selectNormalAtom,
  selectPetDeliveryAtom,
  shippingInfo,
} from "../atoms/atoms";
import { Switch } from "../components/ui/switch";

//@ts-ignore

const SendNow = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [originAddressErrorMessage, setOriginAddressErrorMessage] =
    useState("");
  const [destinationAddressErrorMessage, setDestinationAddressErrorMessage] =
    useState("");

  const [cityState, setCityState] = useState<string | null | undefined>(null); // Allow city to be string, null, or undefined
  const [zipcodeState, setZipcodeState] = useState<string | null | undefined>(
    null
  ); // Allow zipcode to be string, null, or undefined

  const [showSetDimensions, setShowSetDimensions] = useState(false);
  const [showSelectedDimensions, setShowSelectedDimensions] = useState(false);
  const [loadingCreationRoute, setLoadingCreationRoute] = useState(false);
  const { control } = useForm(); // Added this line

  const [distance, setDistance] = useState<string | null>(null);
  const [duration, setDuration] = useState<string | null>(null);
  const [dimensionsDetails, setDimensionsDetails] = useState<any>();
  const [showDeliverPets, setShowDeliverPets] = useState(false);
  const [showSelectedPetDetails, setShowSelectedPetDetails] = useState(false);
  const [selectedPetDetails, setSelectedPetDetails] = useState<any>();
  const [petImage, setPetImage] = useState<any>();
  const [shippingData, setShippingData] = useAtom(shippingInfo);
  const [dimensionsData, setDimensionsData] = useAtom(dimensionsInfo);
  const [petData, setPetData] = useAtom(petInfo);
  const [formData, setFormData] = useAtom(formDataAtom);

  const [selectNormal, setSelectNormal] = useAtom(selectNormalAtom);
  const [selectPetDelivery, setSelectPetDelivery] = useAtom(
    selectPetDeliveryAtom
  );
  useEffect(() => {
    setFormData({
      origin_address: "",
      origin_city: "",
      origin_zipcode: null,
      origin_lat: null,
      origin_lng: null,
      destination_address: "",
      destination_zipcode: null,
      destination_city: "",
      destination_lat: null,
      destination_lng: null,
      delivery_duration: "",
      delivery_distance: "",
      wantToDeliverPets: false,
      height: "",
      width: "",
      length: "",
      weight: "",
      petType: "",
      petWeight: "",
      pet_has_box: false,
      petImage: null,
    });
  }, [setFormData]);

  const originAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(
    null
  );
  const destinationAutocompleteRef =
    useRef<google.maps.places.Autocomplete | null>(null);
  const originInputRef = useRef<HTMLInputElement>(null);
  const destinationInputRef = useRef<HTMLInputElement>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  async function calculateRoute() {
    setLoadingCreationRoute(true);
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: {
        lat: Number(formData.origin_lat),
        lng: Number(formData.origin_lng),
      },
      destination: {
        lat: Number(formData.destination_lat),
        lng: Number(formData.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    const leg = results.routes[0].legs[0];

    // Check if distance and duration exist
    if (leg.distance && leg.duration) {
      setDistance(leg.distance.text); // Set distance only if it exists
      setDuration(leg.duration.text); // Set duration only if it exists

      setFormData((prevData: any) => ({
        ...prevData,
        delivery_distance: leg.distance?.text || null, // Update delivery_distance
        delivery_duration: leg.duration?.text || null, // Update delivery_duration!
      }));
    }

    setTimeout(() => {
      setLoadingCreationRoute(false);
    }, 2000);
  }

  const handlePlaceSelect = (
    autocompleteRef: React.RefObject<google.maps.places.Autocomplete>,
    type: "origin" | "destination"
  ) => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const address = place.formatted_address || "";

      // Initialize city variable
      let city = "";
      let zipcode = "";

      console.log(place);
      console.log(lat);
      console.log(lng);
      console.log(address);

      // Find city in address_components
      const cityComponent = place.address_components?.find((component) =>
        component.types.includes("locality")
      );

      const zipcodeComponent = place.address_components?.find((component) =>
        component.types.includes("postal_code")
      );
      const routeComponent = place.address_components?.find((component) =>
        component.types.includes("route")
      );

      // Check if types array includes specific values
      const hasValidType = place.types?.some((type) =>
        [
          "airport",
          "point_of_interest",
          "street_address",
          "premise",
          "route",
        ].includes(type)
      );

      // Check if any required fields are null or undefined
      if (!hasValidType) {
        if (type === "origin") {
          setOriginAddressErrorMessage(
            "Please provide a complete origin address."
          );
          setFormData((prevData) => ({
            ...prevData,
            origin_address: address,
            // origin_lat: null,
            // origin_lng: null,
          }));
        } else {
          setDestinationAddressErrorMessage(
            "Please provide a complete destination address."
          );
          setFormData((prevData) => ({
            ...prevData,
            destination_address: address,
            // destination_lat: null,
            // destination_lng: null,
          }));
        }
        return;
      }
      if (cityComponent) {
        city = cityComponent.long_name;
        setCityState(city);
      }
      if (zipcodeComponent) {
        zipcode = zipcodeComponent.long_name;
        setZipcodeState(zipcode);
      }

      if (type === "origin") {
        setFormData((prevData) => ({
          ...prevData,
          origin_address: address,
          origin_city: city,
          origin_lat: lat,
          origin_lng: lng,
          origin_zipcode: zipcode,
        }));
        setOriginAddressErrorMessage("");
      } else {
        setFormData((prevData) => ({
          ...prevData,
          destination_address: address,
          destination_city: city,
          destination_lat: lat,
          destination_lng: lng,
          destination_zipcode: zipcode,
        }));
        setDestinationAddressErrorMessage("");
      }
    }
  };

  const handleSwitchChange = (checked: any) => {
    setSelectNormal(!checked); // When "checked", set selectNormal to false
    setSelectPetDelivery(checked); // When "checked", set selectPetDelivery to true
  };

  const handleHasBoxChange = (checked: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      pet_has_box: checked,
    }));
  };

  const saveInfo = () => {
    setPetData({ ...selectedPetDetails, petForLocalImage: petImage });
    !formData.wantToDeliverPets && setDimensionsData(dimensionsDetails);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check if any required fields are null or undefined
    if (formData?.origin_lat === null) {
      setOriginAddressErrorMessage("Please provide a complete origin address.");
      return; // Stop further execution
    }
    if (formData?.destination_lat === null) {
      setOriginAddressErrorMessage(
        "Please provide a complete destination address."
      );
      return; // Stop further execution
    }

    setLoading(true);

    // Simulate a loading state (replace with your real async call if needed)
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Show your UI components and calculate route
    setShowSetDimensions(true);
    setShowDeliverPets(true);
    calculateRoute();
  };

  const handlePackageSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowSelectedDimensions(true);
  };
  const handlePetSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSelectedPetDetails({
      petWeight: formData.petWeight,
      petType: formData.petType,
      petHasBox: formData.pet_has_box,
      petImage: formData.petImage,
    });
    setShowSelectedPetDetails(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (value: string) => {
    setFormData({
      ...formData,
      petType: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setFormData({
        ...formData,
        petImage: e.target.files[0],
      });
      setPetImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <div className="bg-gray-50 h-full pb-10 overflow-x-hidden">
      <Header />
      <div className="flex items-center justify-between w-full border-b border-border p-3 mb-4 max-w-4xl mx-auto mt-10">
        <h1 className="text-4xl font-medium ">
          Send <span className="text-2xl font-medium">small packages</span>
        </h1>
        <img src={Package} className="h-14 w-14 object-contain" alt="package" />
      </div>
      <div className="relative flex items-start justify-center">
        <div className=" p-4 bg-white shadow mt-10 w-full max-w-4xl  ">
          <h1 className="text-lg font-semibold">Package Destination</h1>
          <div className="flex  pr-2 pl-2 py-2">
            {!showSetDimensions ? (
              <form className="w-full p-6 bg-white " onSubmit={handleSubmit}>
                <div className="space-y-6">
                  {/* From Section */}

                  <div>
                    <h2 className="text-lg font-semibold mb-4">From</h2>
                    <div>
                      <div className="relative w-full">
                        {isLoaded && (
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (originAutocompleteRef.current = autocomplete)
                            }
                            onPlaceChanged={() =>
                              handlePlaceSelect(originAutocompleteRef, "origin")
                            }
                            options={{
                              fields: [
                                "address_components",
                                "geometry",
                                "formatted_address",
                                "types",
                              ],
                              componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                            }}
                          >
                            <input
                              ref={originInputRef}
                              type="text"
                              value={formData.origin_address}
                              onChange={(e) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  origin_address: e.target.value,
                                  origin_lat: null,
                                  origin_lng: null,
                                }));
                                setOriginAddressErrorMessage("");
                                setCityState(null);
                                setZipcodeState(null);
                              }}
                              placeholder="Origin Address"
                              className="p-2 border rounded-md w-full"
                            />
                          </Autocomplete>
                        )}
                        {originAddressErrorMessage && (
                          <p className="text-red-500 text-sm mt-2 animate-error-bounce">
                            {originAddressErrorMessage}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* To Section */}
                  <div>
                    <h2 className="text-lg font-semibold mb-4">To</h2>
                    <div>
                      <div className="relative">
                        {isLoaded && (
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (destinationAutocompleteRef.current =
                                autocomplete)
                            }
                            onPlaceChanged={() =>
                              handlePlaceSelect(
                                destinationAutocompleteRef,
                                "destination"
                              )
                            }
                            options={{
                              fields: [
                                "address_components",
                                "geometry",
                                "formatted_address",
                                "types",
                              ],
                              componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                            }}
                          >
                            <input
                              ref={destinationInputRef}
                              type="text"
                              value={formData.destination_address}
                              onChange={(e) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  destination_address: e.target.value,
                                  destination_lat: null,
                                  destination_lng: null,
                                }));
                                setCityState(null);
                                setDestinationAddressErrorMessage("");
                                setZipcodeState(null);
                              }}
                              placeholder="Destination Address"
                              className="p-2 border rounded-md w-full"
                            />
                          </Autocomplete>
                        )}
                      </div>
                      {destinationAddressErrorMessage && (
                        <p className="text-red-500 text-sm mt-2 animate-error-bounce">
                          {destinationAddressErrorMessage}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Pets Delivery Section */}
                  <div className="flex items-center space-x-2 mt-6">
                    <Controller
                      name="wantToDeliverPets"
                      control={control}
                      render={({ field }) => (
                        <div className="flex items-center space-x-2">
                          <Switch
                            checked={formData.wantToDeliverPets}
                            onCheckedChange={(value) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                wantToDeliverPets: value,
                              })); // Update formData.wantToDeliverPets directly
                              handleSwitchChange(value); // Toggle atom states if needed
                            }}
                            color="#0022B6"
                          />
                          <h1 className="text-sm font-medium">Deliver Pet</h1>
                        </div>
                      )}
                    />
                  </div>

                  {/* Submit Button */}
                  <div className="flex justify-end mt-8">
                    <Button
                      type="submit"
                      className="bg-freightBlueDark text-white disabled:bg-gray-300 w-full md:w-60 py-2 rounded-md "
                      title="Describe your shipment"
                    >
                      <p className="text-center">Describe your shipment</p>
                    </Button>
                  </div>
                </div>
              </form>
            ) : (
              <div className="flex-column w-full">
                <div className="pt-6 px-6 bg-white border rounded-lg w-full">
                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="flex-1">
                      <h3 className="text-gray-500 font-medium mb-2 text-sm uppercase tracking-wide">
                        Origin
                      </h3>
                      <p className="text-lg font-semibold">
                        {formData.origin_address}
                      </p>
                    </div>
                    <div className="flex-1">
                      <h3 className="text-gray-500 font-medium mb-2 text-sm uppercase tracking-wide">
                        Destination
                      </h3>
                      <p className="text-lg font-semibold">
                        {formData.destination_address}
                      </p>
                    </div>
                  </div>
                  <Map
                    origin={{
                      lat: formData.origin_lat!,
                      lng: formData.origin_lng!,
                    }}
                    destination={{
                      lat: formData.destination_lat!,
                      lng: formData.destination_lng!,
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          {showSetDimensions && (
            <div className="flex flex-col border-t pt-2">
              {!formData.wantToDeliverPets && (
                <div>
                  <h1 className="text-lg font-semibold pb-3">
                    Package Dimensions
                  </h1>

                  {showSelectedDimensions ? (
                    <div className="p-4 bg-white rounded-lg border">
                      <div className="flex items-start justify-between space-x-6">
                        {/* Dimensions Section */}
                        <div className="flex flex-wrap space-y-4 sm:space-y-0 sm:space-x-6">
                          <div className="flex flex-col w-32">
                            <h3 className="text-gray-500 text-sm font-medium mb-1">
                              Width:
                            </h3>
                            <h1 className="text-lg font-semibold">
                              {formData?.width} cm
                            </h1>
                          </div>
                          <div className="flex flex-col w-32">
                            <h3 className="text-gray-500 text-sm font-medium mb-1">
                              Length:
                            </h3>
                            <h1 className="text-lg font-semibold">
                              {formData?.length} cm
                            </h1>
                          </div>
                          <div className="flex flex-col w-32">
                            <h3 className="text-gray-500 text-sm font-medium mb-1">
                              Height:
                            </h3>
                            <h1 className="text-lg font-semibold">
                              {formData?.height} cm
                            </h1>
                          </div>
                          <div className="flex flex-col w-32">
                            <h3 className="text-gray-500 text-sm font-medium mb-1">
                              Weight:
                            </h3>
                            <h1 className="text-lg font-semibold">
                              {formData?.weight} kg
                            </h1>
                          </div>
                        </div>

                        {/* Image Section */}
                        <div className="relative flex items-center justify-center w-40">
                          <img
                            alt="package-dimensions"
                            src={require("src/assets/images/shoes-box.png")}
                            className="object-contain h-28 w-auto"
                          />
                          <div className="absolute top-0 right-0 p-2 bg-white bg-opacity-75 text-xs text-gray-700 rounded">
                            <p className="font-medium">
                              Width: {Math.round(Number(formData?.width))} cm
                            </p>
                            <p className="font-medium">
                              Height: {Math.round(Number(formData?.height))} cm
                            </p>
                            <p className="font-medium">
                              Length: {Math.round(Number(formData?.length))} cm
                            </p>
                            <p className="mt-2 text-xs text-gray-500">
                              Weight: {formData?.weight} kg
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-4 w-full">
                      <form
                        className="w-full space-y-6"
                        onSubmit={handlePackageSubmit}
                      >
                        <div className="flex flex-col space-y-6 border rounded p-6 w-full">
                          {/* Dimensions Inputs */}
                          <div className="flex flex-col space-y-4 w-full sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
                            {/* Height Input */}
                            <div className="w-full">
                              <label
                                htmlFor="height"
                                className="block font-medium mb-2"
                              >
                                Height (cm)
                              </label>
                              <Input
                                id="height"
                                name="height"
                                type="number"
                                value={formData.height}
                                onChange={(e) => {
                                  if (e.target.value.length <= 2) {
                                    handleChange(e);
                                  }
                                }}
                                min={0}
                                max={30}
                                placeholder="Parcel Height"
                              />
                            </div>

                            {/* Width Input */}
                            <div className="w-full">
                              <label
                                htmlFor="width"
                                className="block font-medium mb-2"
                              >
                                Width (cm)
                              </label>
                              <Input
                                id="width"
                                name="width"
                                type="number"
                                value={formData.width}
                                onChange={(e) => {
                                  if (e.target.value.length <= 2) {
                                    handleChange(e);
                                  }
                                }}
                                min={0}
                                max={30}
                                placeholder="Parcel Width"
                              />
                            </div>

                            {/* Length Input */}
                            <div className="w-full">
                              <label
                                htmlFor="length"
                                className="block font-medium mb-2"
                              >
                                Length (cm)
                              </label>
                              <Input
                                id="length"
                                name="length"
                                type="number"
                                value={formData.length}
                                onChange={(e) => {
                                  if (e.target.value.length <= 2) {
                                    handleChange(e);
                                  }
                                }}
                                min={0}
                                max={30}
                                placeholder="Parcel Length"
                              />
                            </div>
                          </div>

                          {/* Weight Input */}
                          <div className="w-1/3">
                            <label
                              htmlFor="weight"
                              className="block font-medium mb-2"
                            >
                              Weight (kg)
                            </label>
                            <Input
                              id="weight"
                              name="weight"
                              type="number"
                              value={formData.weight}
                              onChange={(e) => {
                                if (e.target.value.length <= 2) {
                                  handleChange(e);
                                }
                              }}
                              min={0}
                              max={30}
                              placeholder="Parcel Weight"
                            />
                          </div>
                        </div>
                        {/* Submit Button */}
                        <div className="flex items-center justify-end">
                          <Button
                            type="submit"
                            disabled={
                              !formData.height ||
                              !formData.width ||
                              !formData.length ||
                              !formData.weight
                            }
                            className="w-full sm:w-60"
                            variant="freightGreen"
                            title="Get Quote"
                          >
                            {loading ? (
                              <p className="text-sm font-medium text-white">
                                <Loader className="h-5 w-5 animate-spin" />
                              </p>
                            ) : (
                              "Get Estimated Quote"
                            )}
                          </Button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              )}

              {formData.wantToDeliverPets && showDeliverPets && (
                <div className="mt-5 border-t pt-2">
                  <h1 className="text-lg font-medium  pb-3">
                    Your Pet Details
                  </h1>
                  {showSelectedPetDetails ? (
                    <div className="flex items-center p-4 justify-between  bg-white rounded-lg border">
                      {/* Pet Details Section */}
                      <div className="flex flex-col space-y-6 sm:space-y-0 sm:space-x-8 sm:flex-row">
                        <div className="flex-1">
                          <h3 className="text-gray-500 font-semibold mb-2">
                            Pet Weight
                          </h3>
                          <h1 className="text-lg font-bold">
                            {formData.petWeight} kg
                          </h1>
                        </div>
                        <div className="flex-1">
                          <h3 className="text-gray-500 font-semibold mb-2">
                            Pet Type
                          </h3>
                          <h1 className="text-lg font-bold">
                            {formData.petType}
                          </h1>
                        </div>
                        <div className="flex-1">
                          <h3 className="text-gray-500 font-semibold mb-2">
                            Available Box
                          </h3>
                          <h1 className="text-lg font-bold">
                            {formData.pet_has_box ? "Yes" : "No"}
                          </h1>
                        </div>
                      </div>

                      {/* Pet Image Section */}
                      {petImage && (
                        <div className="flex-shrink-0">
                          <img
                            alt="pet"
                            src={petImage}
                            className="h-20 w-20 object-cover rounded-lg shadow-sm"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <form onSubmit={handlePetSubmit} className="py-4 bg-white">
                      <div className="flex flex-col space-y-6">
                        {/* Pet Details Section */}
                        <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6">
                          {/* Pet Type Select */}
                          <div className="flex-1">
                            <label className="block text-sm font-medium">
                              Pet Type
                            </label>
                            <Select
                              onValueChange={handleSelectChange}
                              value={formData.petType}
                            >
                              <SelectTrigger className="w-full sm:w-60">
                                <SelectValue placeholder="Select Pet Type" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Select Pet Type" disabled>
                                  Select Pet Type
                                </SelectItem>
                                <SelectItem value="Cat">Cat</SelectItem>
                                <SelectItem value="Dog">Dog</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          {/* Pet Weight Input */}
                          <div className="flex-1">
                            <label className="block text-sm font-medium">
                              Weight (kg)
                            </label>
                            <Input
                              className="w-full"
                              type="number"
                              name="petWeight"
                              value={formData.petWeight}
                              onChange={handleChange}
                              placeholder="Pet Weight"
                            />
                          </div>

                          {/* Box Available Switch */}
                          <div className="flex items-center space-x-2">
                            <Switch
                              checked={formData.pet_has_box}
                              onCheckedChange={handleHasBoxChange}
                              color="#0022B6"
                            />
                            <h1 className="text-sm font-medium">
                              Box Available
                            </h1>
                          </div>
                        </div>

                        {/* Pet Image Section */}
                        <div className="flex flex-col space-y-4 sm:flex-row items-start sm:space-x-6">
                          <div className="flex-1">
                            <p className="text-sm font-medium mb-2">
                              Pet Image
                            </p>
                            <div className="border border-dashed border-gray-300 rounded-lg p-6">
                              <div className="text-center">
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-300"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <div className="mt-4 flex flex-col items-center text-sm text-gray-600">
                                  <label className="cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600">
                                    <span>Upload a file</span>
                                    <Input
                                      accept="image/png, image/jpeg, image/gif"
                                      type="file"
                                      onChange={handleFileChange}
                                      className="sr-only"
                                    />
                                  </label>
                                  <p className="mt-1">or drag and drop</p>
                                  <p className="text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Display Image Preview */}
                          {petImage && (
                            <img
                              src={petImage}
                              alt="Pet"
                              className="h-full w-[40%] object-cover rounded-lg"
                            />
                          )}
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-end mt-8">
                          <Button
                            type="submit"
                            disabled={
                              !formData.petType ||
                              !formData.petWeight ||
                              !formData.petImage
                            }
                            className="w-60"
                            variant="freightGreen"
                            title="Get Quote"
                          >
                            {loading ? (
                              <p className="text-sm font-medium text-white">
                                <Loader className="h-5 w-5 animate-spin" />
                              </p>
                            ) : (
                              "Get Estimated Quote"
                            )}
                          </Button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              )}
              {(showSelectedDimensions || showSelectedPetDetails) && (
                <div className="mt-4 flex items-center space-x-2 w-60 ml-auto">
                  <Button
                    onClick={() => {
                      saveInfo();
                      navigate("/calculate-quote");
                    }}
                    className="w-60"
                    // disabled={!showSelectedDimensions}
                  >
                    Calculate Quote
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {showSetDimensions && (
          <div className="px-4  ">
            <p className="text-lg font-medium mb-2">Resumen</p>
            <div className="p-4 bg-white rounded-lg border mt-2">
              <div className="">
                <h3 className="text-gray-500 font-medium mb-2 text-sm uppercase tracking-wide">
                  From
                </h3>
                <p className="text-sm font-semibold">
                  {formData.origin_address}
                </p>
              </div>
              <div className="mt-4">
                <h3 className="text-gray-500 font-medium mb-2 text-sm uppercase tracking-wide">
                  To
                </h3>
                <p className="text-sm font-semibold">
                  {formData.destination_address}
                </p>
              </div>
            </div>
            {formData?.weight !== "" && (
              <div className="p-4 bg-white rounded-lg border mt-2">
                <div className="flex flex-col items-start ">
                  {/* Image Section */}
                  <p>Packages (1)</p>
                  <div className="relative flex items-center  mt-2">
                    <div className=" text-xs flex items-center space-x-2 text-gray-700 ">
                      <p className=" text-xs text-gray-500">
                        {formData?.weight} kg
                      </p>
                      <p className="font-medium">
                        {Math.round(Number(formData?.width))} cm
                      </p>
                      <p className="font-medium">
                        {Math.round(Number(formData?.height))} cm
                      </p>
                      <p className="font-medium">
                        {Math.round(Number(formData?.length))} cm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Map: React.FC<{
  origin: { lat: number; lng: number };
  destination: { lat: number; lng: number };
}> = ({ origin, destination }) => {
  const googleMapsApiKey = GOOGLE_API_KEY; // Reemplaza con tu API key

  return (
    <div className="w-full h-96 flex justify-center my-6 items-center">
      <iframe
        className="w-full h-full rounded-md"
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/directions?key=${googleMapsApiKey}&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}`}
        allowFullScreen
        title="map"
      ></iframe>
    </div>
  );
};

export default SendNow;
