import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { GOOGLE_API_KEY } from "../api/config";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import { Form } from "../components/ui/form";
import { Input } from "../components/ui/input";
import useLocalStorage from "../hooks/use-local-storage";
import { cn } from "../lib/utils";

const packageDeliverySchema = z.object({
  origin: z.any(),
  destination: z.any(),
});
const PackageDelivery = () => {
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState<any>();
  const [searchDropoffResult, setSearchDropoffResult] = useState<any>();
  const [locationPackage, setLocationPackage] = useState({
    originName: "",
    originLat: "",
    originLng: "",
    originAddress: "",
    destinationName: "",
    destinationLat: "",
    destinationLng: "",
    destinationAddress: "",
  });
  const originRef = useRef<any>(null);
  const destinationRef = useRef<any>(null);
  const formPackageDelivery = useForm<z.infer<typeof packageDeliverySchema>>({
    resolver: zodResolver(packageDeliverySchema),
    defaultValues: {
      destination: "",
      origin: "",
    },
  });
  const [packageDelivery, setPackageDelivery] =
    useLocalStorage("package_delivery");

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });
  function onPlaceChanged() {
    if (searchResult !== null && searchResult !== undefined) {
      formPackageDelivery.setValue("origin", searchResult?.getPlace().name);
      setLocationPackage((prev) => ({
        ...prev,
        originLat: searchResult?.getPlace().geometry?.location.lat(),
        originLng: searchResult?.getPlace().geometry?.location.lng(),
        originName: searchResult?.getPlace().name,
        originAddress: searchResult?.getPlace().formatted_address,
        originCity: searchResult?.getPlace().name,
      }));
    }
  }

  function onPlaceChangedDropoff() {
    if (searchDropoffResult !== null && searchDropoffResult !== undefined) {
      formPackageDelivery.setValue(
        "destination",
        searchDropoffResult?.getPlace().name
      );

      setLocationPackage((prev) => ({
        ...prev,
        destinationLat: searchDropoffResult
          ?.getPlace()
          .geometry?.location.lat(),
        destinationLng: searchDropoffResult
          ?.getPlace()
          .geometry?.location.lng(),
        destinationName: searchDropoffResult?.getPlace().name,
        destinationAddress: searchDropoffResult?.getPlace().formatted_address,
        destinationCity: searchDropoffResult?.getPlace().name,
      }));
    }
  }
  function onLoad(autoComplete: any) {
    setSearchResult(autoComplete);
  }

  function onLoadDropoff(autocomplete: any) {
    setSearchDropoffResult(autocomplete);
  }

  const onSubmit = async (values: z.infer<typeof packageDeliverySchema>) => {
    if (values.origin && values.destination) {
      setPackageDelivery({
        origin: values.origin,
        destination: values.destination,
        originLat: locationPackage.originLat,
        originLng: locationPackage.originLng,
        originAddress: locationPackage.originAddress,
        destinationLat: locationPackage.destinationLat,
        destinationLng: locationPackage.destinationLng,
        destinationAddress: locationPackage.destinationAddress,
      });
      navigate("/package-delivery/selection");
    }
  };
  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] no-scrollbar">
        <div className=" w-full relative flex items-center justify-between ">
          <div className="w-full border-b h-1 absolute top-4 z-10" />
          {window.location.pathname === `/package-delivery` && (
            <div className="w-1/3 border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          {window.location.pathname === `/package-delivery/selection` && (
            <div className="w-2/3 border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          <div className="flex flex-col z-20">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",

                window.location.pathname === `/package-delivery` &&
                  "bg-[#333] text-white"
              )}
            >
              <p className="text-sm font-medium">1</p>
            </div>
            <p
              className={cn(
                "text-xs w-20 mt-2 font-medium text-gray-500",
                window.location.pathname === `/package-delivery` &&
                  "text-[#333]"
              )}
            >
              Delivery Address
            </p>
          </div>
          <div className="flex flex-col z-20 items-center">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                window.location.pathname === `/package-delivery/selection` &&
                  "bg-[#333]"
              )}
            >
              <p
                className={cn(
                  "text-sm font-medium",

                  window.location.pathname === `/package-delivery/selection` &&
                    "text-white"
                )}
              >
                2
              </p>
            </div>
            <p
              className={cn(
                "text-xs text-center w-20 mt-2 font-medium text-gray-500",
                window.location.pathname === `/package-delivery/selection` &&
                  "text-[#333]"
              )}
            >
              Package Selection
            </p>
          </div>
          <div className="flex flex-col z-20 items-end  ">
            <div className="bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex">
              <p className="text-sm font-medium">3</p>
            </div>
            <p className="text-xs w-20 mt-2  justify-end items-end text-right font-medium text-gray-500">
              Submit
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-10">
          <h1 className="text-gray-500 text-2xl font-medium">Send delivery</h1>
        </div>
        <div className="bg-white shadow p-4 mt-10">
          <div className="sm:flex sm:flex-col sm:ml-4">
            <Form {...formPackageDelivery}>
              <form
                className="flex flex-col w-full"
                onSubmit={formPackageDelivery.handleSubmit(onSubmit)}
              >
                <div className="grid sm:grid-cols-2 w-full gap-10">
                  <div>
                    <h1 className="text-sm font-medium border-b-2 mb-4 pb-2">
                      Direction{" "}
                    </h1>
                    <div className="w-full mb-4">
                      {isLoaded && (
                        <Controller
                          control={formPackageDelivery.control}
                          name="origin"
                          render={({ field }) => (
                            <>
                              <Autocomplete
                                {...field}
                                onPlaceChanged={() => {
                                  onPlaceChanged();
                                }}
                                onLoad={onLoad}
                                options={{
                                  fields: [
                                    "address_components",
                                    "geometry",
                                    "formatted_address",
                                    "types",
                                  ],
                                  componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                                }}
                              >
                                <Input placeholder="Origin" ref={originRef} />
                              </Autocomplete>
                            </>
                          )}
                        />
                      )}
                    </div>
                    <div className="w-full mb-4">
                      {isLoaded && (
                        <Controller
                          control={formPackageDelivery.control}
                          name="destination"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              onPlaceChanged={onPlaceChangedDropoff}
                              onLoad={(val) => {
                                formPackageDelivery.setValue(
                                  "destination",
                                  val?.getPlace()?.name
                                );
                                onLoadDropoff(val);
                              }}
                              options={{
                                fields: [
                                  "address_components",
                                  "geometry",
                                  "formatted_address",
                                  "types",
                                ],
                                componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                              }}
                            >
                              <Input
                                placeholder="Destination"
                                ref={destinationRef}
                              />
                            </Autocomplete>
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <Button variant={"freightBlue"} className=" w-60 sm:ml-auto">
                  NEXT PRICES: PACKAGES
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDelivery;
