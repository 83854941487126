import { ArrowLeft, ArrowRight, Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { cn } from "../lib/utils";
import { SearchFreightInstantsProps } from "../types/types";
//@ts-ignore
//@ts-ignore
import { SquareArrowUpRight } from "lucide-react";
import { BASE_URL_IMG_2 } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { InstantFreight } from "../types/types";
//@ts-ignore
import SmallBox from "../assets/images/books-box.png";
//@ts-ignore
import MediumBox from "../assets/images/shoes-box.png";
//@ts-ignore
import LargeBox from "../assets/images/moving-box.png";

const sortOptions = [
  {
    value: "desc",
    label: "Date Listed (Newest to Oldest)",
  },
  {
    value: "asc",
    label: "Date Listed (Oldest to Newest)",
  },
  // {
  //   id: 3,
  //   label: "Distance (Longest to Shortest)",
  // },
  // {
  //   id: 4,
  //   label: "Distance (Shortest to Longest)",
  // },
  // {
  //   id: 5,
  //   label: "Origin (A to Z)",
  // },
  // {
  //   id: 6,
  //   label: "Origin (Z to A)",
  // },
  // {
  //   id: 7,
  //   label: "Destination (A to Z)",
  // },
  // {
  //   id: 8,
  //   label: "Destination (Z to A)",
  // },
];

const SearchFreightInstants: React.FC<SearchFreightInstantsProps> = ({
  instantPage,
  setInstantPage,
  sortInstantsBy,
  isLoadingInstants,
  allInstants,
  setSortInstantsBy,
  refetchInstants,
}) => {
  const navigate = useNavigate();
  const userInfo = userInfoUserStore((s) => s);
  const token = useTokenStoreStorage((state) => state.token);

  const handleOnClickImage = (freightId: any) => {
    if (token) {
      navigate(`/instant-freights-details/${freightId}`);
    }
  };
  return (
    <>
      <div className="my-20 max-w-7xl mx-auto">
        <h1 className="text-lg font-semibold border-b border-border pb-3 mb-4">
          Instant Freights
        </h1>
        <div className=" bg-white shadow rounded p-4">
          {allInstants?.message ===
          "No instant freights found for this customer_id" ? (
            <></>
          ) : (
            <div className="mt-4 ml-auto w-full justify-end flex space-x-2 mb-4">
              <Select
                onValueChange={(val: any) => {
                  setInstantPage(1);
                  setSortInstantsBy(val);
                }}
                value={sortInstantsBy}
              >
                <SelectTrigger className="w-40 h-8">
                  <SelectValue placeholder="Sort by Date" />
                </SelectTrigger>
                <SelectContent>
                  {sortOptions.map((sort) => (
                    <SelectItem value={sort.value}>{sort.label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                disabled={instantPage === 1}
                onClick={() => setInstantPage(1)}
                className={cn(
                  "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                  instantPage === 1 && "bg-[#333] text-white"
                )}
              >
                <p className="text-sm font-medium">1</p>
              </Button>
              <div
                onClick={() => {
                  if (instantPage > 1) {
                    setInstantPage((instantPage) => instantPage - 1);
                  }
                }}
                className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
              >
                <ArrowLeft className="h-4 w-4 text-[#333]" />
              </div>
              <div className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition">
                {" "}
                <p className="text-sm font-medium">{instantPage}</p>
              </div>
              <div
                onClick={() => setInstantPage((instantPage) => instantPage + 1)}
                className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
              >
                <ArrowRight className="h-4 w-4 text-[#333]" />
              </div>
              <Button
                disabled={instantPage === allInstants?.data?.total_pages}
                onClick={() =>
                  setInstantPage(allInstants?.data?.total_pages ?? 1)
                }
                className={cn(
                  "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                  instantPage === allInstants?.data?.total_pages &&
                    "bg-[#333] text-white"
                )}
              >
                {isLoadingInstants ? (
                  <p>
                    <Loader2 className="h-5 w-5 animate-spin" />
                  </p>
                ) : (
                  <p className="text-sm font-medium">
                    {allInstants?.data?.total_pages}
                  </p>
                )}
              </Button>
            </div>
          )}

          <Table className="border border-border">
            <TableHeader>
              <TableRow>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Package to deliver
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Origin/Destination
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last Updated
                </TableHead>
                {token && (
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </TableHead>
                )}
              </TableRow>
            </TableHeader>
            {isLoadingInstants ? (
              <TableBody>
                {[...Array(5)].map((_, index) => (
                  <TableRow key={index} className="border-b border-gray-200">
                    <TableCell>
                      <Skeleton className="h-5 w-10 bg-gray-200 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-20 bg-gray-200 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-60 bg-gray-200 rounded" />
                      <Skeleton className="h-5 w-60 mt-4 bg-gray-200 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-24 bg-gray-200 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-32 bg-gray-200 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-28 bg-gray-200 rounded" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <>
                {allInstants?.message ===
                "No instant freights found for this customer_id" ? (
                  <TableBody className="flex items-center p-4 justify-center w-full ">
                    <TableRow>
                      <p className="text-sm font-medium text-gray-500 mt-4">
                        No instant freight found
                      </p>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {allInstants?.data?.instant_freights &&
                      allInstants.data.instant_freights.map(
                        (freight: InstantFreight) => (
                          <TableRow
                            key={freight.id}
                            className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                          >
                            <TableCell className="p-4">
                              <div className="flex items-start space-x-4">
                                <div className="flex flex-col space-y-2">
                                  <p className="text-sm font-semibold">
                                    {freight.package_weight} kg
                                  </p>
                                  <p className="text-sm text-gray-600 capitalize">
                                    {freight.package_size}
                                  </p>
                                  <p className="text-sm font-medium text-gray-800">
                                    Est. {freight.estimated_price}€
                                  </p>
                                  {freight.package_size ? (
                                    <div
                                      onClick={() =>
                                        handleOnClickImage(freight.id)
                                      }
                                      className="bg-gray-100 border border-gray-300 rounded-lg p-2 hover:bg-gray-200 transition cursor-pointer"
                                    >
                                      <img
                                        src={
                                          freight.package_size === "small"
                                            ? SmallBox
                                            : freight.package_size === "medium"
                                            ? MediumBox
                                            : freight.package_size === "large"
                                            ? LargeBox
                                            : null // Optional: Handle case where package_size is not small, medium, or large
                                        }
                                        className="h-16 w-16 object-contain"
                                        alt={freight.package_size || "pet"} // Conditional alt text
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      onClick={() =>
                                        handleOnClickImage(freight.id)
                                      }
                                      alt="pet"
                                      className="h-20 w-20 object-cover rounded-lg border border-gray-300 cursor-pointer"
                                      src={`${BASE_URL_IMG_2}${freight?.pet_img}`}
                                    />
                                  )}
                                </div>
                                {freight.trip_type === "pet" && (
                                  <div className="flex flex-col space-y-2">
                                    <p className="text-sm font-semibold">
                                      {freight.pet_weight} kg
                                    </p>
                                    <p className="text-sm text-gray-600 capitalize">
                                      {freight.pet_type}
                                    </p>
                                    <p className="text-sm font-medium text-gray-800">
                                      {freight.has_cage === 1
                                        ? "Available Cage"
                                        : "Unavailable Cage"}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                            <TableCell className="p-4">
                              <div className="flex space-x-4">
                                <div className="flex flex-col space-y-2 bg-gray-100 rounded-lg p-2 border border-gray-300">
                                  <p className="text-xs font-medium text-gray-600">
                                    Origin
                                  </p>
                                  <p className="text-sm font-medium text-gray-800 truncate">
                                    <p>{freight.origin_city}</p>
                                  </p>
                                </div>
                                <div className="flex flex-col space-y-2 bg-gray-100 rounded-lg p-2 border border-gray-300">
                                  <p className="text-xs font-medium text-gray-600">
                                    Destination
                                  </p>
                                  <p className="text-sm font-medium text-gray-800 truncate">
                                    <p>{freight.destination_city}</p>
                                  </p>
                                </div>
                              </div>
                              <div className="flex space-x-4 mt-2">
                                <div className="flex flex-col space-y-2 bg-gray-100 rounded-lg p-2 border border-gray-300">
                                  <p className="text-xs font-medium text-gray-600">
                                    Distance
                                  </p>
                                  <p className="text-sm font-medium text-gray-800">
                                    {freight.delivery_distance}
                                  </p>
                                </div>
                                <div className="flex flex-col space-y-2 bg-gray-100 rounded-lg p-2 border border-gray-300">
                                  <p className="text-xs font-medium text-gray-600">
                                    Duration
                                  </p>
                                  <p className="text-sm font-medium text-gray-800">
                                    {freight.delivery_duration}
                                  </p>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="p-4">
                              <div className="flex justify-center">
                                <div
                                  className={cn(
                                    "rounded-lg px-4 py-2 text-xs font-semibold text-white text-center capitalize",
                                    freight.status === "created" &&
                                      "bg-blue-500",
                                    (freight.status === "assigned" ||
                                      freight.status === "paid" ||
                                      freight.status === "picking-up" ||
                                      freight.status === "in-transit" ||
                                      freight.status === "finished") &&
                                      "bg-yellow-500",
                                    freight.status === "confirmed" &&
                                      "bg-indigo-500",
                                    freight.status === "cancelled" &&
                                      "bg-gray-500"
                                  )}
                                >
                                  {[
                                    "created",
                                    "assigned",
                                    "cancelled",
                                    "confirmed",
                                  ].includes(freight.status)
                                    ? freight.status
                                    : "assigned"}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="p-4 text-center text-gray-500">
                              {freight.updated_at}
                            </TableCell>
                            {token && (
                              <TableCell className="p-4 relative">
                                {userInfo.user_type !== "customer" &&
                                  freight.status === "created" && (
                                    <Button
                                      onClick={() =>
                                        navigate(
                                          `/carrier/assign-freight/${freight.id}`
                                        )
                                      }
                                      className="bg-freightOrange text-white text-center px-4 py-1 rounded text-sm"
                                    >
                                      Assign Instant Freight
                                    </Button>
                                  )}

                                {userInfo.user_id === freight.carrier?.id ? (
                                  <SquareArrowUpRight
                                    onClick={() =>
                                      navigate(
                                        `/instant-freights-details/${freight.id}`
                                      )
                                    }
                                    className="absolute top-1/2 right-4 transform -translate-y-1/2 h-6 w-6 text-green-600 cursor-pointer"
                                  />
                                ) : (
                                  <SquareArrowUpRight
                                    onClick={() =>
                                      navigate(
                                        `/instant-freights-details/${freight.id}`
                                      )
                                    }
                                    className="absolute top-1/2 right-4 transform -translate-y-1/2 h-5 w-5 text-gray-600 cursor-pointer"
                                  />
                                )}
                                {freight.is_reviewed.toString() === "1" && (
                                  <div className="bg-gray-100 p-2 mt-2 rounded-lg">
                                    <p className="text-sm font-medium text-gray-600 text-center">
                                      Reviewed
                                    </p>
                                  </div>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      )}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </div>
        {/* {modal.isOpen && (
          <ModalCancelInstantFreight refetch={refetchInstants} />
        )}
        {modalFreightPaid.isOpen && (
          <ModalInstantFreightPaid refetch={refetchInstants} />
        )}
        {modalPayInstantFreight.isOpen && (
          <ModalPayInstantFreight refetch={refetchInstants} />
        )} */}
      </div>
    </>
  );
};

export default SearchFreightInstants;
