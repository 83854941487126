import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { ChevronLeft, Info, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  BASE_IMG_URL,
  BASE_URL,
  GETCATEGORY_SUBCATEGORY,
  REGISTER_CARRIER,
  REGISTER_DEVICE,
} from "../api/config";
import { userId } from "../atoms/atoms";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import { Checkbox } from "../components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import { useAvailablePackages } from "../lib/getAvailablePackages";
import { cn } from "../lib/utils";
import { listProvinces } from "../listProvinces";
import {
  useGuidTokenStorage,
  useTokenStoreStorage,
} from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { Category, SubCategory } from "../types/types";

const formSchema = z
  .object({
    contactFirstName: z.string().min(1, "First name is required"),
    contactLastname: z.string().min(1, "Last name is required"),
    username: z.string().min(1, "Username is required"),
    password: z.string().min(1, "Password is required"),
    confirmPassword: z.string().min(1, "Confirm Password is required"),
    email: z.string().min(1, "Email address is required"),
    phoneNumber: z.string().min(1, "Phone number is required"),
    phoneNumber2: z.string().min(1, "Phone number 2 is required"),
    instantService: z.boolean(),
    deliverPackages: z.boolean(),
    deliverPets: z.boolean(),
    termsConditions: z.boolean(),
    companyName: z.string().min(1, "Company Name is required"),
    driversLiscense: z.any().optional(),
    abnCertificate: z.any().optional(),
    abnNumber: z.string().min(11, "ABN number is required"),
    addressLine1: z.string().min(1, "Address Line 1 is required"),
    addressLine2: z.string().min(1, "Address Line 2 is required"),
    suburb: z.string().min(1, "Suburb is required"),
    postcode: z.string().min(1, "Postcode is required"),
    vehicleType: z.string().min(1, "Vehicle Type is required"),
    vehicleRegNo: z.any().optional(),
    // servicedFreightCat:z.any(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords did not match",
      });
    }
  });

// const guidToken = "65c34455d4730";

const packageTypes = [
  {
    id: 1,
    name: "small",
    checked: false,
  },
  {
    id: 2,
    name: "medium",
    checked: false,
  },
  {
    id: 3,
    name: "large",
    checked: false,
  },
];

const RegisterCarrier = () => {
  const [driversLiscense, setDriversLiscense] = useState<any>();
  const [abnCertificate, setAbnCertificate] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const setTokens = useTokenStoreStorage((s) => s.addToken);
  const setUserInfo = userInfoUserStore((s) => s.addUserInfo);
  const navigate = useNavigate();
  const [cityList, setCityList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const setGuidToken = useGuidTokenStorage((state) => state.addGuid);
  const guidToken = useGuidTokenStorage((state) => state.guidToken);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const [user_id, setUserId] = useAtom(userId);
  const [selectionProvince, setSelectionProvince] = useState<{
    label: string;
    value: string;
    id: number;
  }>();
  const [selectedCities, setSelectedCities] = useState<any[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [providesInstantServices, setProvidesInstantServices] =
    useState<boolean>(false);
  const [selectionPackageType, setSelectionPackageType] = useState<any>({
    packages: [],
  });
  const [vehicle, setVehicle] = useState("");

  const availablePackages = useAvailablePackages();

  const [loading, setLoading] = useState(false);
  const [showSubcategories, setShowSubcategories] = useState(false);

  const [categoryId, setCategoryId] = useState<any>([]);
  const [subCategoryId, setSubcategoryId] = useState<any>([]);

  useEffect(() => {
    setCityList(listProvinces);
  }, []);

  const registerDevice = async (
    contactFirstName: string,
    contactLastname: string,
    username: string,
    email: string,
    phoneNumber: string,
    phoneNumber2: string,
    password: string,
    companyName: string,
    addressLine1: string,
    addressLine2: string,
    suburb: string,
    postcode: string,
    abnNumber: string,
    instantService: boolean,
    vehicleType: string,
    vehicleRegNo: string,
    driversLiscense: string,
    abnCertificate: string,
    deliverPackages: boolean,
    deliverPets: boolean
  ) => {
    const response = await fetch(`${BASE_URL}${REGISTER_DEVICE}?GUID=NEW`, {
      method: "POST",
      headers: {},
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "GUID created successfully.") {
          setGuidToken(data.data.GUID);
          register(
            contactFirstName,
            contactLastname,
            username,
            email,
            phoneNumber,
            phoneNumber2,
            password,
            companyName,
            addressLine1,
            addressLine2,
            suburb,
            postcode,
            abnNumber,
            instantService,
            vehicleType,
            vehicleRegNo!,
            driversLiscense,
            abnCertificate,
            data.data.GUID,
            deliverPackages,
            deliverPets
          );
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  useEffect(() => {
    const append = cityList.map(function (row: {
      province_name: string;
      province_short_name: string;
      id: number;
    }) {
      return {
        label: row.province_name,
        value: row.province_short_name,
        id: row.id,
        checked: false,
      };
    });

    setSelectedCities(append);
  }, [cityList]);

  const handleCheckboxChange = (event: any) => {
    let newArray = [...selectionPackageType.packages, event.target.id];
    if (selectionPackageType.packages.includes(event.target.id)) {
      newArray = newArray.filter((type) => type !== event.target.id);
    }
    setSelectionPackageType({
      packages: newArray,
    });
  };

  const registerDetails = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      abnNumber: "",
      abnCertificate: "",
      addressLine1: "",
      addressLine2: "",
      companyName: "",
      confirmPassword: "",
      contactFirstName: "",
      contactLastname: "",
      driversLiscense: "",
      phoneNumber2: "",
      postcode: "",
      suburb: "",
      password: "",
      phoneNumber: "",
      username: "",
      vehicleType: "",
      vehicleRegNo: "",
      instantService: false,
      termsConditions: false,
      deliverPets: false,
    },
  });

  const formData = new FormData();

  const register = async (
    firstname: string,
    lastname: string,
    username: string,
    email: string,
    phoneNumber: string,
    phoneNumber2: string,
    password: string,
    companyName: string,
    address: string,
    addressLine2: string,
    suburb: string,
    postcode: string,
    abnNumber: string,
    instantServer: boolean,
    vehicleType: string,
    vehicleRegNo: string,
    doc1: any,
    doc2: any,
    guidToken: string,
    deliverPackages: boolean,
    deliverPets: boolean
  ) => {
    formData.append("username", username);
    formData.append("first_name", firstname);
    formData.append("last_name", lastname);
    formData.append("email", email);
    formData.append("email1", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("phone2", phoneNumber2);
    formData.append("password", password);
    formData.append("GUID", guidToken);
    formData.append("user_type", "carrier");
    formData.append("company_name", companyName);
    formData.append("address", address);
    formData.append("address_line2", addressLine2);
    formData.append("suburb", suburb);
    formData.append("postcode", postcode);
    formData.append("abn", abnNumber);
    formData.append("state", "Spain");
    formData.append("social_login", "0");
    formData.append("terms_conditions", "1");
    formData.append(
      "categories",
      JSON.stringify(
        categoryId.map((category: { id: string; name: string }) => category.id)
      )
    );

    formData.append(
      "subcategories",
      JSON.stringify(
        subCategoryId.map(
          (subcategory: { id: string; name: string }) => subcategory.id
        )
      )
    );

    formData.append(
      "operate_in",
      selectedCities
        ?.filter((city) => city.checked === true)
        .map((city) => city.id)
        .toString()
    );
    formData.append(
      "package_size_available",
      selectionPackageType.packages.map((item: string) => item)
    );
    formData.append("doc1", doc1);
    formData.append("doc2", doc2);
    formData.append("vehicleType", vehicleType);
    formData.append("vehicleRegNo", vehicleRegNo?.toString()!);
    formData.append("is_instant_server", instantServer === true ? "1" : "0");
    formData.append(
      "availableToDeliverPackages",
      deliverPackages === true ? "1" : "0"
    );
    formData.append("availableToDeliverPets", deliverPets === true ? "1" : "0");

    const response = await fetch(`${BASE_URL}${REGISTER_CARRIER}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Transporter registered successfully.") {
          setUserInfo(
            data.data.email,
            data.data.phone_number,
            data.data.user_type,
            data.data.user_id,
            data.data.first_name
          );
          setUserId(data.data.user_id);
          toast.success(`Customer registered`);
          navigate("/register/verify");
        } else {
          if (data.data.email1) {
            toast.error(`Email already taken`);
          }
          if (data.data.username) {
            toast.error(`Username already taken`);
          }
          toast.error(data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#777",
      },
      display: "flex",
      height: "40px",
      width: "400px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  const handleCityChange = (e: any) => {
    if (!e) {
      setCityList(listProvinces);
    } else {
      setCityList(listProvinces.filter((name) => name.province_name !== e));
    }
  };
  const onCategoryClick = (category: Category) => {
    // setCategoryId({
    //   catid: category.id,
    //   catname: category.name,
    // });
    // setCategoryId((prev: any) => [
    //   ...prev,
    //   { catid: category.id, catname: category.name },
    // ]);
    // if (category.subcategories.length > 0) {
    //   setSubCategories(category.subcategories);
    //   setShowSubcategories(true);
    // }

    setCategoryId((prev: any) => {
      const exists = prev?.some(
        (cat: { id: number; name: string }) => cat.id === category.id
      );

      if (exists) {
        return prev?.filter((cat: { id: number }) => cat.id !== category.id);
      } else {
        return [...prev, { id: category.id, name: category.name }];
      }
    });
    setSubCategories(category.subcategories);
  };

  const onSubCategoryClick = (subcategory: SubCategory) => {
    setSubcategoryId((prev: any) => {
      const exists = prev?.some(
        (subCategory: { id: number; name: string }) =>
          subCategory.id === Number(subcategory.id)
      );

      if (exists) {
        return prev?.filter(
          (subCategory: { id: number }) =>
            subCategory.id !== Number(subcategory.id)
        );
      } else {
        return [...prev, { id: subcategory.id, name: subcategory.name }];
      }
    });
  };
  // const handleCategoriesChange = (e: any) => {
  //   if (!e) {
  //     setCategoryList(categories?.data);
  //   } else if(categories) {
  //     setCategoryList(
  //       categories?.filter((name: { catname: string }) => name.catname !== e)
  //     );
  //   }
  // };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (
      categoryId?.length > 0 &&
      selectedCities?.filter((city) => city.checked)?.length > 0
    ) {
      setLoading(true);
      registerDevice(
        values.contactFirstName,
        values.contactLastname,
        values.username,
        values.email,
        values.phoneNumber,
        values.phoneNumber2,
        values.password,
        values.companyName,
        values.addressLine1,
        values.addressLine2,
        values.suburb,
        values.postcode,
        values.abnNumber,
        values.instantService,
        values.vehicleType,
        values.vehicleRegNo!,
        values.driversLiscense,
        values.abnCertificate,
        values.deliverPackages,
        values.deliverPets
      );
    } else {
      toast.error("No selected provinces or categories");
    }
  };

  const getCategories = async () => {
    const response = await fetch(`${BASE_URL}${GETCATEGORY_SUBCATEGORY}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: categoriesData } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });

  useEffect(() => {
    setCategories(categoriesData);
  }, [categoriesData]);
  useEffect(() => {
    const append = categories?.map(function (row: {
      id: number;
      name: string;
    }) {
      return {
        label: row.name,
        value: row.id,
        id: row.id,
        checked: false,
      };
    });
    setSelectedCategories(append);
  }, [categories]);

  const handleCitiesFilter = (
    city: { label: string; value: string; id: number; checked: boolean },
    val: boolean
  ) => {
    setSelectedCities((current: any) =>
      current.map((obj: { id: number }) => {
        if (obj.id === city.id) {
          return { ...obj, checked: val };
        }
        return obj;
      })
    );
  };
  const handleCategoriesFilter = (
    category: { label: string; value: number; id: number; checked: boolean },
    val: boolean
  ) => {
    setSelectedCategories((current: any) =>
      current.map((obj: { id: number }) => {
        if (obj.id === category.id) {
          return { ...obj, checked: val };
        }
        return obj;
      })
    );
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <Header />
      <div className="max-w-4xl mx-auto mt-10">
        <h1 className="text-xl font-medium mb-4 p-4">Register Transporter</h1>

        <div className="bg-white p-10  my-4 shadow border border-border rounded">
          <Form {...registerDetails}>
            <form
              onSubmit={registerDetails.handleSubmit(onSubmit)}
              className="w-full flex flex-col dark:bg-transparent"
            >
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="username"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Username</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="username"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="password"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem className="relative">
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input
                            type={!showPassword ? "password" : "text"}
                            {...field}
                            name="password"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                        <div className="absolute top-9 right-2">
                          {!showPassword ? (
                            <div
                              onClick={() => setShowPassword(true)}
                              className="cursor-pointer"
                            >
                              <FaEyeSlash className="h-4 w-4" />
                            </div>
                          ) : (
                            <div
                              onClick={() => setShowPassword(false)}
                              className="cursor-pointer"
                            >
                              <FaEye className="h-4 w-4" />
                            </div>
                          )}
                        </div>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="confirmPassword"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem className="relative">
                        <FormLabel>Confirm Password</FormLabel>
                        <FormControl>
                          <Input
                            type={!showConfirmPassword ? "password" : "text"}
                            {...field}
                            name="confirmPassword"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                        <div className="absolute top-9 right-2">
                          {!showConfirmPassword ? (
                            <div
                              onClick={() => setShowConfirmPassword(true)}
                              className="cursor-pointer"
                            >
                              <FaEyeSlash className="h-4 w-4" />
                            </div>
                          ) : (
                            <div
                              onClick={() => setShowConfirmPassword(false)}
                              className="cursor-pointer"
                            >
                              <FaEye className="h-4 w-4" />
                            </div>
                          )}
                        </div>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4 py-10 border-y">
                <FormField
                  control={registerDetails.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="email"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="phoneNumber"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Phone Number</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            name="phoneNumber"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="phoneNumber2"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Phone Number 2</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            name="phoneNumber2"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="contactFirstName"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Contact First name</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="contactFirstName"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="contactLastname"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Contact Last name</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="contactLastname"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="companyName"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Company Name</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="companyName"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <Controller
                  control={registerDetails.control}
                  name="driversLiscense"
                  render={({ field, fieldState }) => (
                    <>
                      <h1 className="text-sm font-medium mb-2">Document 1</h1>

                      <Input
                        accept="image/png, image/jpeg, image/gif*"
                        {...field}
                        value={field.value.fileName}
                        type="file"
                        name="driversLiscense"
                        className="w-full dark:bg-transparent"
                        onChange={(event: any) => {
                          field.onChange(event.target.files?.[0]);
                          setDriversLiscense(
                            URL.createObjectURL(event.target.files?.[0])
                          );
                        }}
                      />
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <Controller
                  control={registerDetails.control}
                  name="abnCertificate"
                  render={({ field, fieldState }) => (
                    <>
                      <h1 className="text-sm font-medium mb-2">Document 2</h1>
                      <Input
                        accept="image/png, image/jpeg, image/gif*"
                        {...field}
                        value={field.value.fileName}
                        type="file"
                        name="abnCertificate"
                        className="w-full dark:bg-transparent"
                        onChange={(event: any) => {
                          field.onChange(event.target.files?.[0]);
                          setAbnCertificate(
                            URL.createObjectURL(event.target.files?.[0])
                          );
                        }}
                      />
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="abnNumber"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>ABN Number</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="abnNumber"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>

              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="addressLine1"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Address</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="addressLine1"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="addressLine2"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Address Line 2</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="addressLine2"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="suburb"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Suburb</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            name="suburb"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500"></p>
                      )}
                    </>
                  )}
                />
              </div>

              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="postcode"
                  render={({ field, fieldState }) => (
                    <>
                      <FormItem>
                        <FormLabel>Postcode</FormLabel>
                        <FormControl>
                          <Input {...field} name="postcode" />
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div>
                <h1 className="text-sm font-medium mb-2">
                  Serviced Freight Categories
                </h1>
                {showSubcategories && (
                  <div
                    onClick={() => setShowSubcategories(false)}
                    className="flex items-center space-x-2 my-2 text-blue-500 cursor-pointer group"
                  >
                    <ChevronLeft className="h-4 w-4 " />
                    <p className="text-sm group-hover:underline">Back</p>
                  </div>
                )}
                <div className="grid sm:grid-cols-2">
                  {!showSubcategories ? (
                    categories?.map((category) => (
                      <div
                        key={category.id}
                        onClick={() => onCategoryClick(category)}
                        className={cn(
                          "border w-[300px] mb-2 rounded-md hover:bg-slate-100 cursor-pointer transition duration-200 ease-in-out",
                          categoryId.some(
                            (cat: { id: number }) =>
                              cat.id === Number(category.id)
                          ) && "border-2 border-black"
                        )}
                      >
                        <div className="flex items-center gap-2">
                          <div className=" rounded-l-md flex justify-center items-center">
                            <img
                              className="h-16 w-28 rounded-l-md object-contain"
                              src={BASE_IMG_URL + category.img}
                              alt={category.name}
                            />
                          </div>
                          <div className="flex justify-between items-center w-full pr-4">
                            <p>{category.name}</p>
                            {category.subcategories.length > 0 && (
                              <div className="bg-gray-100 h-6 w-6 hover:scale-105 transition flex items-center rounded-full">
                                <MdKeyboardArrowRight
                                  onClick={() => {
                                    setShowSubcategories(true);
                                    onCategoryClick(category);
                                  }}
                                  size={25}
                                  className="text-gray-500"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      {subCategories?.map((subcategory) => (
                        <div
                          key={subcategory.id}
                          className={cn(
                            "border w-[300px] mb-2 rounded-md cursor-pointer hover:bg-slate-100 transition duration-200 ease-in-out",
                            subCategoryId.some(
                              (subcat: { id: number }) =>
                                subcat.id === Number(subcategory.id)
                            ) && "border-2 border-black"
                          )}
                          onClick={() => onSubCategoryClick(subcategory)}
                        >
                          <div className="flex items-center gap-2">
                            <div>
                              <img
                                className="h-16 w-28 rounded-l-md object-contain cursor-pointer"
                                src={BASE_IMG_URL + subcategory.img}
                                alt={subcategory.name}
                              />
                            </div>
                            <div>
                              <p>{subcategory.name}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {/* // <div
                      //   className="flex flex-col gap-1 mb-2"
                      //   key={category.id}
                      // >
                      //   <label className="flex items-center text-sm tracking-wide">
                      //     <Checkbox
                      //       checked={category.checked}
                      //       onCheckedChange={(val: boolean) =>
                      //         handleCategoriesFilter(category, val)
                      //       }
                      //       className="mr-3"
                      //     />
                      //     {category.label}
                      //   </label>
                      // </div> */}
                  {/* // )} */}
                </div>

                {selectedCategories?.filter((category) => category.checked)
                  ?.length > 0 && (
                  <div className="mt-2">
                    <p className="text-sm font-medium">Selected categories</p>
                    <div className="flex flex-wrap space-x-2 mt-2">
                      {selectedCategories
                        ?.filter((category) => category.checked)
                        .map((category) => (
                          <p className="text-sm font-medium bg-gray-50 px-2 py-1">
                            {category.label}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full my-4">
                <h1 className="text-sm font-medium mb-2">
                  Provinces you operate in
                </h1>
                <div className="grid sm:grid-cols-2">
                  {selectedCities.map(
                    (city: {
                      label: string;
                      value: string;
                      id: number;
                      checked: boolean;
                    }) => {
                      return (
                        <div key={city.id} className="flex flex-col gap-1 mb-2">
                          <label className="flex items-center text-sm tracking-wide">
                            <Checkbox
                              checked={city.checked}
                              onCheckedChange={(val: boolean) =>
                                handleCitiesFilter(city, val)
                              }
                              className="mr-3"
                            />
                            {city.label}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
                {/* <SelectReact
                  onInputChange={(e) => handleCityChange(e)}
                  options={selectedCities}
                  className="text-sm dark:text-black"
                  styles={colourStyles2}
                  isMulti
                  isOptionSelected={(option: { checked: boolean }) =>
                    option.checked === true
                  }
                  placeholder="Select Province"
                  // value={
                  //   selectionProvince && {
                  //     label: selectionProvince.label,
                  //     value: selectionProvince.value,
                  //   }
                  // }
                  onChange={(e: any, action) => {
                    handleCitiesFilter(e, action);
                    // setSelectionProvince(e);
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  // noOptionsMessage={() => "No options"}
                /> */}
                {selectedCities?.filter((city) => city.checked)?.length > 0 && (
                  <div className="mt-2">
                    <p className="text-sm font-medium">Selected cities</p>
                    <div className="flex flex-wrap space-x-2 mt-2">
                      {selectedCities
                        ?.filter((city) => city.checked)
                        .map((city) => (
                          <p className="text-sm font-medium bg-gray-50 px-2 py-1">
                            {city.label}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full mb-4 border-t pt-2 ">
                <h1 className="font-medium"> Instant Services</h1>
                <Controller
                  control={registerDetails.control}
                  name="instantService"
                  render={({ field, fieldState }) => (
                    <>
                      <div className="flex items-center space-x-2 mt-4">
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                        <p className="text-sm">Provide instant services</p>
                      </div>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="border-b py-4 border-t">
                {registerDetails.getValues("instantService") === true && (
                  <div>
                    <>
                      <Controller
                        control={registerDetails.control}
                        name="deliverPackages"
                        render={({ field, fieldState }) => (
                          <>
                            <div className="flex items-center space-x-2 ">
                              <Checkbox
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                              <p className="text-sm"> Deliver packages</p>
                            </div>
                            {fieldState.error && (
                              <p className="text-sm font-medium text-red-500">
                                {fieldState.error.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                      <h1 className="text-sm font-medium mb-2 mt-4">
                        Package Type
                      </h1>

                      <div className="flex flex-col space-y-2 ">
                        {packageTypes.map((type) => (
                          <div className="flex items-center space-x-2">
                            <input
                              onChange={handleCheckboxChange}
                              id={type.name}
                              type="checkbox"
                              value={type.name}
                              color="#0022B6"
                              className="h-3 w-3 rounded"
                            />
                            <p className="text-sm font-medium text-[#333] capitalize">
                              {type.name}
                            </p>
                            <TooltipProvider delayDuration={0}>
                              <Tooltip>
                                <TooltipTrigger>
                                  <Info className="h-4 w-4 text-[#333]" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex flex-col items-start">
                                    <p className="text-sm font-medium mb-2">
                                      Info
                                    </p>
                                    <div className="flex flex-col items-start bg-gray-50 border-border border w-full px-2 py-1">
                                      <h1 className="text-sm font-medium text-gray-500">
                                        Weight
                                      </h1>
                                      <p className="text-sm font-medium text-[#333]">
                                        {type.name === "small" && <p>0-5kg</p>}
                                        {type.name === "medium" && (
                                          <p>5-20kg</p>
                                        )}
                                        {type.name === "large" && (
                                          <p>20-30kg</p>
                                        )}
                                      </p>
                                    </div>
                                    <div className="flex flex-col items-start bg-gray-50 border-border border w-full px-2 py-1">
                                      <h1 className="text-sm font-medium text-gray-500">
                                        Dimensions
                                      </h1>
                                      <p className="text-sm font-medium text-[#333]">
                                        {type.name === "small" && (
                                          <p>aprox. 23 x 14 x 4cm</p>
                                        )}
                                        {type.name === "medium" && (
                                          <p>aprox. 35 x 20 x 15cm</p>
                                        )}
                                        {type.name === "large" && (
                                          <p>aprox. 75 x 35 x 35cm</p>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        ))}
                      </div>
                    </>
                    <Controller
                      control={registerDetails.control}
                      name="deliverPets"
                      render={({ field, fieldState }) => (
                        <>
                          <div className="flex items-center space-x-2 mt-4 ">
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                            <p className="text-sm">Transport pets</p>
                          </div>
                          {fieldState.error && (
                            <p className="text-sm font-medium text-red-500">
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="w-full mb-4">
                <h1 className="text-sm font-medium mb-2 pt-2">Vehicle Type</h1>
                <Controller
                  control={registerDetails.control}
                  name="vehicleType"
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        onValueChange={(val) => {
                          registerDetails.setValue("vehicleType", val);
                          setVehicle(val);
                        }}
                        {...field}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select vehicle type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="car">Car</SelectItem>
                          <SelectItem value="motorbike">Motorbike</SelectItem>
                          <SelectItem value="bike">Bike</SelectItem>
                        </SelectContent>
                      </Select>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              {(vehicle === "car" || vehicle === "motorbike") && (
                <div className="w-full mb-4">
                  <FormField
                    name="vehicleRegNo"
                    control={registerDetails.control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormItem>
                          <FormLabel>Vehicle Reg No</FormLabel>
                          <FormControl>
                            <Input {...field} name="vehicleRegNo" />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-sm font-medium text-red-500">
                            {fieldState.error.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              )}

              <div className="w-full mb-4">
                <Controller
                  control={registerDetails.control}
                  name="termsConditions"
                  render={({ field, fieldState }) => (
                    <>
                      <div className="flex items-center space-x-2 mt-4">
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                        <p className="text-sm">
                          I verify that I have read and accept the terms &
                          conditions set out by Runnme
                        </p>
                      </div>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>

              <Button
                disabled={
                  loading ||
                  registerDetails.getValues("termsConditions") === false ||
                  categoryId?.length === 0 ||
                  selectedCities?.filter((city) => city.checked).length === 0
                }
                variant={"freightBlue"}
                className="w-60 ml-auto mt-10 "
              >
                {loading ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin" />
                  </>
                ) : (
                  <p className="text-sm font-medium">Register Transporter</p>
                )}
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default RegisterCarrier;
