import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import { FaClock, FaMapMarkerAlt } from "react-icons/fa";

import { FaArrowRight, FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import { RiPinDistanceFill } from "react-icons/ri";
import { BASE_URL, GET_ALL_SIZES, GOOGLE_API_KEY } from "../../api/config";
import loadingRoute from "../../assets/Animations/routeLottie.json";
import small from "../../assets/images/books-box.png";
import large from "../../assets/images/moving-box.png";
import medium from "../../assets/images/shoes-box.png";
import { Size } from "../../types/types";
import { TripStepSummary } from "./TripStepSummary";

interface TripStepsProps {
  fromInputValue: string;
  finalDestination: string;
  stops: string[];
  setFinalDestination: React.Dispatch<React.SetStateAction<string>>;
  setStops: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTime: string | null; // New prop for the selected time
}

export const TripSteps: React.FC<TripStepsProps> = ({
  fromInputValue,
  finalDestination,
  stops,
  setFinalDestination,
  setStops,
  selectedTime,
}) => {
  const [distanceMatrixResults, setDistanceMatrixResults] = useState<any[]>([]);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [selectedSizesCount, setSelectedSizesCount] = useState<{
    [key: string]: number;
  }>({});
  const [selectedSizeId, setSelectedSizeId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Estado para controlar el envío
  const [showSummary, setShowSummary] = useState(false);
  const [originCoordinates, setOriginCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [destinationCoordinates, setDestinationCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const handleNextClick = () => {
    setShowSummary(true); // Show summary on button click
  };

  // Calculate distances
  const calculateDistances = (origin: string, destinations: string[]) => {
    if (!origin) {
      console.error("Origin is empty");
      return;
    }

    const service = new google.maps.DistanceMatrixService();

    const handleAddressChange = (place: {
      geometry: { location: { lat: any; lng: any } };
    }) => {
      if (place.geometry) {
        const { lat, lng } = place.geometry.location;
        setOriginCoordinates({ lat: lat(), lng: lng() });
      }
    };

    const handleFinalDestinationChange = (place: {
      geometry: { location: { lat: any; lng: any } };
    }) => {
      if (place.geometry) {
        const { lat, lng } = place.geometry.location;
        setDestinationCoordinates({ lat: lat(), lng: lng() });
      }
    };

    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: destinations,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (
        response: google.maps.DistanceMatrixResponse | null,
        status: google.maps.DistanceMatrixStatus
      ) => {
        if (status === google.maps.DistanceMatrixStatus.OK && response) {
          const results = response.rows[0].elements;
          setDistanceMatrixResults(results);
        } else {
          console.error("Error with Distance Matrix:", status); // Log de error
        }
      }
    );
  };

  const handleSubmit = () => {
    setIsLoading(true);
    calculateDistances(fromInputValue, [finalDestination]);
    setIsSubmitted(true); // Se establece el envío como correcto
    handleNextClick(); // Proceed to the next step after submission
  };

  useEffect(() => {
    if (distanceMatrixResults.length > 0) {
    }
  }, [distanceMatrixResults]);

  useEffect(() => {
    getAllSizes();
  }, []);
  // Autocomplete refs
  const autocompleteRefs = useRef<(google.maps.places.Autocomplete | null)[]>(
    []
  );
  const destinationAutocompleteRef =
    useRef<google.maps.places.Autocomplete | null>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });
  const handlePlaceSelect = (index: number) => {
    if (autocompleteRefs.current[index]) {
      const place = autocompleteRefs.current[index]?.getPlace();
      if (place && place.formatted_address) {
        const newStops = [...stops];
        newStops[index] = place.formatted_address;

        // Get latitude and longitude
        const latLng = place.geometry?.location;
        if (latLng) {
          console.log("Latitude:", latLng.lat(), "Longitude:", latLng.lng());
        }

        setStops(newStops);
      }
    }
  };

  const handleFinalDestinationSelect = () => {
    if (destinationAutocompleteRef.current) {
      const place = destinationAutocompleteRef.current?.getPlace();
      if (place && place.formatted_address) {
        setFinalDestination(place.formatted_address);

        // Get latitude and longitude for final destination
        const latLng = place.geometry?.location;
        if (latLng) {
          localStorage.setItem("dlat", latLng.lat().toString());
          localStorage.setItem("dlng", latLng.lng().toString());
        }
      }
    }
  };

  const handleAddStop = () => {
    setStops([...stops, ""]);
  };

  const handleRemoveStop = (stop: string) => {
    setStops(stops.filter((item: string) => item !== stop));
  };

  const handleStopChange = (index: number, value: string) => {
    const newStops = [...stops];
    newStops[index] = value;
    setStops(newStops);
  };

  const getImageForSize = (name: string) => {
    switch (name) {
      case "small":
        return small;
      case "medium":
        return medium;
      case "large":
        return large;
      default:
        return null;
    }
  };

  const getAllSizes = async () => {
    setLoader(true); // Inicia la carga
    try {
      const response = await fetch(`${BASE_URL}${GET_ALL_SIZES}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Failed to fetch sizes");
      const data = await response.json();
      setSizes(data.data);
    } catch (error) {
      console.error("Error fetching sizes:", error);
    } finally {
      setLoader(false);
    }
  };
  const handleCardClick = (size: Size) => {
    const sizeName = size.name;
    const newCount = selectedSizesCount[sizeName]
      ? selectedSizesCount[sizeName] + 1
      : 1;
    setSelectedSizesCount({ ...selectedSizesCount, [sizeName]: newCount });
  };

  const truncateText = (text: string, maxLength: number = 10) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const getLastWord = (text: string) => {
    const words = text.trim().split(" "); // Divide el texto en palabras
    return words.length > 0 ? words[words.length - 1] : ""; // Retorna la última palabra
  };

  const calculateArrivalTime = (
    departureTime: string,
    durationText: string
  ) => {
    if (!departureTime || !durationText) return null;

    // Extract hours and minutes from duration text
    const durationParts = durationText.split(" ");
    let hoursToAdd = 0;
    let minutesToAdd = 0;

    for (let i = 0; i < durationParts.length; i += 2) {
      const durationValue = parseInt(durationParts[i]);
      if (durationParts[i + 1].includes("h")) {
        hoursToAdd += durationValue;
      } else if (durationParts[i + 1].includes("min")) {
        minutesToAdd += durationValue;
      }
    }

    // Create a date object for the selected departure time
    const [selectedHours, selectedMinutes] = departureTime
      .split(":")
      .map(Number);
    const departureDate = new Date();
    departureDate.setHours(selectedHours, selectedMinutes);

    // Calculate new time
    departureDate.setHours(departureDate.getHours() + hoursToAdd);
    departureDate.setMinutes(departureDate.getMinutes() + minutesToAdd);

    return departureDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const isNextButtonDisabled =
    !isSubmitted || Object.keys(selectedSizesCount).length === 0;

  useEffect(() => {
    // Asegúrate de que haya al menos una parada al iniciar el componente
    if (stops.length === 0) {
      setStops([""]); // Añade una parada vacía si no hay paradas
    }
  }, []);

  return (
    <div>
      {showSummary ? (
        <TripStepSummary
          showSummary={showSummary}
          setShowSummary={setShowSummary}
          fromInputValue={fromInputValue}
          finalDestination={finalDestination}
          stops={stops}
          distanceMatrixResults={distanceMatrixResults}
          selectedSizesCount={selectedSizesCount}
          selectedTime={selectedTime}
          originCoordinates={originCoordinates}
          destinationCoordinates={destinationCoordinates}
        />
      ) : (
        <div>
          {loader ? (
            <Lottie animationData={loadingRoute} className="h-[350px]" />
          ) : (
            <div className="mt-4 p-4 bg-white rounded-md shadow-md w-4xl">
              {loadError && <p>Error loading Google Maps</p>}
              {!isLoaded ? (
                <p>Loading Google Maps...</p>
              ) : (
                <div>
                  <h2 className="font-bold mb-2 mt-4">
                    How many packages can you transport?
                  </h2>
                  <div className="flex gap-4 relative z-10 pb-10">
                    {sizes.map((size) => (
                      <div
                        key={size.id}
                        onClick={() => handleCardClick(size)}
                        className={`flex items-center gap-4 border px-4 rounded-md bg-white cursor-pointer ${
                          selectedSizeId === size.id
                            ? "shadow-blue-500 shadow-lg"
                            : "shadow-md"
                        } hover:shadow-blue-300 hover:shadow-lg transition-shadow duration-300 transform active:scale-95`}
                      >
                        <img
                          src={getImageForSize(size.name)}
                          alt={size.name}
                          className="h-28 w-28 object-contain"
                        />
                        <div>
                          <p className="font-bold flex gap-2 capitalize">
                            {size.name}
                          </p>
                          <p>
                            {size.length} cm x {size.width} cm x {size.height}{" "}
                            cm
                          </p>
                          <p>{size.weight} kg</p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex items-center justify-between">
                    <h2 className="font-bold mb-2">Stops (Optional)</h2>
                    <h3 className="text-sm text-gray-400">
                      Limit: Max 4 Stops
                    </h3>
                  </div>
                  {stops.map((stop, index) => (
                    <div key={index} className="flex items-center mb-2 gap-2">
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (autocompleteRefs.current[index] = autocomplete)
                        }
                        onPlaceChanged={() => handlePlaceSelect(index)}
                        options={{
                          fields: [
                            "address_components",
                            "geometry",
                            "formatted_address",
                            "types",
                          ],
                          componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                        }}
                      >
                        <input
                          type="text"
                          placeholder={`Stop ${index + 1}`}
                          value={stop}
                          onChange={(e) =>
                            handleStopChange(index, e.target.value)
                          }
                          className="border border-gray-300 rounded-md p-2 mr-2 w-[400px]"
                        />
                      </Autocomplete>
                      {index === stops.length - 1 && index < 3 && (
                        <button
                          onClick={handleAddStop}
                          className="bg-[#1889ca] text-white rounded-md p-2"
                        >
                          <FaCirclePlus />
                        </button>
                      )}
                      <button onClick={() => handleRemoveStop(stop)}>
                        <FaCircleMinus />
                      </button>
                    </div>
                  ))}

                  <h2 className="font-bold mb-2 mt-4">Final Destination</h2>
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (destinationAutocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={handleFinalDestinationSelect}
                    options={{
                      fields: [
                        "address_components",
                        "geometry",
                        "formatted_address",
                        "types",
                      ],
                      componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Enter final destination"
                      value={finalDestination}
                      onChange={(e) => setFinalDestination(e.target.value)}
                      className="border border-gray-300 rounded-md p-2 mb-4 w-full"
                    />
                  </Autocomplete>
                </div>
              )}
              {distanceMatrixResults.length > 0 ? (
                <div className="mt-4  bg-white rounded-md w-4xl">
                  <h2 className="font-bold mb-2">
                    Distance & Duration Summary
                  </h2>
                  {distanceMatrixResults.map((result, index) => {
                    const arrivalTime = selectedTime
                      ? calculateArrivalTime(
                          selectedTime,
                          result.duration?.text
                        ) // Only call if selectedTime is not null
                      : null; // Set to null if selectedTime is null
                    return (
                      <div
                        key={index}
                        className="bg-gray-100 p-2 rounded-md mb-2 flex gap-4 items-center justify-between"
                      >
                        <div className="flex items-center space-x-4">
                          <p className="flex gap-2 items-center">
                            <FaMapMarkerAlt />
                            {index === 0
                              ? getLastWord(fromInputValue)
                              : getLastWord(stops[index - 1])}
                          </p>
                          <FaArrowRight />
                          <p className="flex gap-2 items-center">
                            <FaMapMarkerAlt />
                            {getLastWord(stops[index] || finalDestination)}
                          </p>
                        </div>
                        <div className="flex items-center space-x-2">
                          <p className="flex gap-2 items-center">
                            <RiPinDistanceFill /> {result.distance?.text},
                          </p>
                          <p className="flex gap-2 items-center">
                            <FaClock />
                            {arrivalTime && <span> {arrivalTime}</span>}{" "}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : isLoading ? (
                <p>Submitting locations...</p>
              ) : (
                <p className="pt-2 text-sm text-gray-400"></p>
              )}
              <div className="mt-4 p-4 bg-gray-100 rounded-md flex flex-col space-y-3">
                {Object.entries(selectedSizesCount).map(([sizeName, count]) => (
                  <div className="flex items-center space-x-2 bg-white w-fit rounded border px-4 py-1">
                    <p key={sizeName} className="mb-2">
                      <span className="text-gray-500">{count} x</span>{" "}
                      <span className="capitalize">{sizeName}</span>{" "}
                    </p>
                    {sizeName === "small" && (
                      <img src={small} className="h-10 w-10" alt="small" />
                    )}
                    {sizeName === "medium" && (
                      <img src={medium} className="h-10 w-10" alt="medium" />
                    )}
                    {sizeName === "large" && (
                      <img src={large} className="h-10 w-10" alt="large" />
                    )}
                  </div>
                ))}
              </div>
              <button
                className={`rounded-md mt-4 w-[100px] p-2 bg-[#1889ca] text-white cursor-pointer`}
                onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
