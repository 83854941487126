import { zodResolver } from "@hookform/resolvers/zod";
import { useJsApiLoader } from "@react-google-maps/api";
import { useAtom } from "jotai";
import { ArrowRight, Loader, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiCat, PiDog } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  BASE_URL,
  CALCULATE_QUOTE,
  CALCULATE_QUOTE_WITH_PET,
  CREATE_INSTANT_FREIGHT,
  GOOGLE_API_KEY,
} from "../api/config";
import {
  dimensionsInfo,
  formDataAtom,
  petInfo,
  selectNormalAtom,
  selectPetDeliveryAtom,
  shippingInfo,
} from "../atoms/atoms";
import Header from "../components/header/Header";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Form } from "../components/ui/form";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

const quoteSchema = z.object({
  normalQuote: z.boolean(),
  petQuote: z.boolean(),
});

const CalculateQuote = () => {
  const [shippingData, setShippingData] = useAtom(shippingInfo);
  const [dimensionsData, setDimensionsData] = useAtom(dimensionsInfo);
  const [petData, setPetData] = useAtom(petInfo);
  const [instantData, setInstantData] = useAtom(formDataAtom);
  const [creatingFreight, setCreatingFreight] = useState(false);

  const [loadingQuote, setLoadingQuote] = useState(true);
  const [loadingQuotePet, setLoadingQuotePet] = useState(true);

  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);

  const [quoteWithoutPet, setQuote] = useState("");
  const [quoteWithPet, setQuotePet] = useState("");

  const [searchResult, setSearchResult] = useState<any>();
  const [searchResultDropoff, setSearchResultDropoff] = useState<any>();

  const [startAddress, setStartAddress] = useState<any>();
  const [endAddress, setEndAddress] = useState<any>();

  const [originPosition, setOriginPosition] = useState<any>();
  const [destinationPosition, setDestinationPosition] = useState<any>();

  const [directionsResponse, setDirectionsResponse] = useState();
  const [distance, setDistance] = useState();
  const [duration, setDuration] = useState();
  const navigate = useNavigate();

  const [calculateOriginLoading, setCalculateOriginLoading] = useState(false);
  const [calculateDestinationLoading, setcalculateDestinationLoading] =
    useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  useEffect(() => {
    console.log("petData", petData);
  }, [petData]);

  const quoteDetails = useForm<z.infer<typeof quoteSchema>>({
    resolver: zodResolver(quoteSchema),
    defaultValues: {
      normalQuote: false,
      petQuote: false,
    },
  });

  const onSubmitQuote = async (values: z.infer<typeof quoteSchema>) => {
    console.log("values:", values);
    createInstantFreight(values.normalQuote, values.petQuote);
  };
  async function calculateLatLngOrigin() {
    setCalculateOriginLoading(true);
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${instantData.origin_address}&key=${GOOGLE_API_KEY}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.results && data.results[0]) {
          // Get the lat and lng
          setSearchResult({
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng,
          });

          // Extract the city name from the address components
          const addressComponents = data.results[0].address_components;
          const cityComponent =
            addressComponents.find((component: any) =>
              component.types.includes("locality")
            ) ||
            addressComponents.find((component: any) =>
              component.types.includes("administrative_area_level_2")
            );

          const originCity = cityComponent ? cityComponent.long_name : "";
          console.log("Origin City:", originCity);

          // Store the city name in the form data or state
          formData.append("origin_city", originCity);
        } else {
          console.log("No results found for the origin address.");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setCalculateOriginLoading(false);
      });

    return response;
  }
  async function calculateLatLngDestination() {
    setCalculateOriginLoading(true);

    const response =
      await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${instantData.destination_address}&key=${GOOGLE_API_KEY}
`)
        .then((res) => res.json())
        .then((data) => {
          setSearchResultDropoff({
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setcalculateDestinationLoading(false);
        });
    return response;
  }

  useEffect(() => {
    calculateLatLngOrigin();
    calculateLatLngDestination();
  }, []);

  useEffect(() => {
    if (searchResult && searchResultDropoff && isLoaded) {
      calculateRuote();
    }
  }, [searchResult, searchResultDropoff, isLoaded]);

  async function calculateRuote() {
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: {
        lat: searchResult?.lat,
        lng: searchResult?.lng,
      },
      destination: {
        lat: searchResultDropoff?.lat,
        lng: searchResultDropoff?.lng,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    //@ts-ignore
    setDirectionsResponse(results);
    //@ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);

    //@ts-ignore
    setDuration(results.routes[0].legs[0].duration?.text);

    //@ts-ignore
    setStartAddress(results.routes[0].legs[0].start_address);
    setEndAddress(results.routes[0].legs[0].end_address);

    setOriginPosition({
      lat: searchResult?.lat,
      lng: searchResult?.lng,
    });

    setDestinationPosition({
      lat: searchResultDropoff?.lat,
      lng: searchResultDropoff?.lng,
    });
    if (results.routes[0].legs[0].start_address !== "") {
      calculateQuote(results.routes[0].legs[0].distance!.text);
      if (petData?.petType) {
        calculateQuoteWithPet(results.routes[0].legs[0].distance!.text);
      }
    }
  }
  const calculateQuote = async (distance: string) => {
    setLoadingQuote(true);
    const response = await fetch(`${BASE_URL}${CALCULATE_QUOTE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        weight: instantData.weight,
        width: instantData.width,
        height: instantData.height,
        length: instantData.length,
        kms: distance.split(" ")?.[0].replaceAll(",", ""),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Quote generated successfully") {
          setQuote(data.data.estimated_quote);
        }
      })
      .finally(() => {
        setLoadingQuote(false);
      });

    return response;
  };

  const calculateQuoteWithPet = async (distance: string) => {
    setLoadingQuotePet(true);
    const response = await fetch(
      `${BASE_URL}${CALCULATE_QUOTE_WITH_PET}?user_id=${user_id}&kms=${distance
        .split(" ")?.[0]
        .replaceAll(
          ",",
          ""
        )}&pet_type=${petData?.petType.toLowerCase()}&has_cage=${
        petData?.petHasBox
      }&pet_weight=${petData?.petWeight}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Pet delivery cost generated successfully") {
          setQuotePet(data.data.estimated_quote);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingQuotePet(false);
      });

    return response;
  };

  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("customer_id", user_id);
  formData.append("delivery_distance", distance!);
  formData.append("delivery_duration", duration!);
  formData.append("origin_city", instantData.origin_city);
  formData.append("destination_city", instantData.destination_city);
  formData.append("origin_address", instantData.origin_address);
  formData.append("destination_address", instantData.destination_address);
  formData.append("estimated_price", quoteWithoutPet);
  formData.append("origin_zipcode", instantData.origin_zipcode);
  formData.append("destination_zipcode", instantData.destination_zipcode);
  formData.append("origin_lat", searchResult?.lat);
  formData.append("origin_lng", searchResult?.lng);
  formData.append("destination_lat", searchResultDropoff?.lat);
  formData.append("destination_lng", searchResultDropoff?.lng);

  const createInstantFreight = async (
    selectNormal: boolean,
    selectPetDelivery: boolean
  ) => {
    setCreatingFreight(true);

    // Start by clearing formData to prevent any unintended retained data
    formData.delete("trip_type");
    formData.delete("pet_type");
    formData.delete("pet_weight");
    formData.delete("has_cage");
    formData.delete("pet_img");

    console.log("selectPetDeliveryBoolean", selectPetDeliveryAtom);
    console.log("selectNormalBoolean", selectNormalAtom);
    console.log("selectNormal", selectNormal);
    console.log("selectPetDelivery", selectPetDelivery);

    // If pet delivery is selected, append pet data
    if (selectPetDelivery) {
      console.log("Log inside selectPetDeliveryBoolean");
      formData.append("trip_type", "pet");
      formData.append("pet_type", petData?.petType || "");
      formData.append("pet_weight", petData?.petWeight || "");
      formData.append("has_cage", petData?.petHasBox === true ? "1" : "0");
      formData.append("pet_img", petData?.petImage || "");
    }

    // If normal freight is selected, append normal data
    if (selectNormal) {
      console.log("Log inside selectNormalBoolean");

      formData.append("trip_type", "normal");
      formData.append("package_size", "medium");
      formData.append("package_weight", instantData.weight || "");
      formData.append("package_height", instantData.height || "");
      formData.append("package_length", instantData.length || "");
      formData.append("package_width", instantData.width || "");
    }

    // Adjust the estimated price based on both conditions
    formData.append(
      "estimated_price",
      selectNormal && selectPetDelivery
        ? quoteWithPet + quoteWithoutPet // If both are true, sum the quotes
        : selectNormal
        ? quoteWithoutPet // Only normal is selected
        : selectPetDelivery
        ? quoteWithPet // Only pet delivery is selected
        : ""
    );

    // Append other fixed or common data
    formData.append("is_valuable", "0");

    try {
      const response = await fetch(`${BASE_URL}${CREATE_INSTANT_FREIGHT}`, {
        method: "POST",
        headers: {
          AccessToken: token,
        },
        body: formData,
      });

      const data = await response.json();

      if (data.message === "Instant freight generated successfully") {
        toast.success("Instant service created!");
        setTimeout(() => {
          navigate("/my-instant-freights");
        }, 2000);
      }

      return data;
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        setCreatingFreight(false);
      }, 2000);
    }
  };

  const handleContinue = () => {
    setCreatingFreight(true);

    // If there's a pet quote, create freight with pet
    if (petData.petType) {
      createInstantFreight(false, true);
    } else {
      // Otherwise create normal freight
      createInstantFreight(true, false);
    }
  };

  return (
    <div className="bg-gray-50">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[95vh] overflow-y-auto no-scrollbar">
        {/* Form Section */}
        <div className="bg-white rounded-lg border border-gray-200 shadow-md p-6">
          <Form {...quoteDetails}>
            <form onSubmit={quoteDetails.handleSubmit(onSubmitQuote)}>
              {/* Shipping Details */}
              <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6">
                <div className="flex-1">
                  <h3 className="text-lg text-gray-500 font-medium">Origin</h3>
                  {calculateOriginLoading ? (
                    <Skeleton className="h-4 w-32 bg-gray-200 rounded" />
                  ) : (
                    <h1 className="text-xl font-semibold text-gray-800">
                      {instantData.origin_address}
                    </h1>
                  )}
                </div>
                <div className="flex-1">
                  <h3 className="text-lg text-gray-500 font-medium">
                    Destination
                  </h3>
                  {calculateDestinationLoading ? (
                    <Skeleton className="h-4 w-32 bg-gray-200 rounded" />
                  ) : (
                    <h1 className="text-xl font-semibold text-gray-800">
                      {instantData.destination_address}
                    </h1>
                  )}
                </div>
              </div>

              {/* Package Delivery Quote */}
              {!petData.petType && (
                <div className="mt-6 border-t border-gray-200 pt-4">
                  <h3 className="text-lg text-gray-500 font-medium">
                    Package Delivery Quote
                  </h3>
                  {loadingQuote ? (
                    <div className="flex items-center justify-between space-x-4 p-4 bg-gray-50 rounded">
                      <div className="flex items-center space-x-4">
                        <Skeleton className="h-4 w-12 bg-gray-200 rounded" />
                        <Skeleton className="h-4 w-16 bg-gray-200 rounded" />
                        <Skeleton className="h-4 w-20 bg-gray-200 rounded" />
                        <Skeleton className="h-4 w-24 bg-gray-200 rounded" />
                      </div>
                      <Skeleton className="h-10 w-24 bg-gray-200 rounded" />
                    </div>
                  ) : (
                    <div className="flex items-center justify-between p-4 bg-gray-50 rounded">
                      <div className="flex-1 flex items-start space-x-6">
                        <div className="flex flex-col">
                          <h3 className="text-gray-500 text-sm font-medium">
                            Width
                          </h3>
                          <h1 className="text-lg font-semibold text-gray-800">
                            {instantData.width} cm
                          </h1>
                        </div>
                        <div className="flex flex-col">
                          <h3 className="text-gray-500 text-sm font-medium">
                            Height
                          </h3>
                          <h1 className="text-lg font-semibold text-gray-800">
                            {instantData.height} cm
                          </h1>
                        </div>
                        <div className="flex flex-col">
                          <h3 className="text-gray-500 text-sm font-medium">
                            Length
                          </h3>
                          <h1 className="text-lg font-semibold text-gray-800">
                            {instantData.length} cm
                          </h1>
                        </div>
                        <div className="flex flex-col">
                          <h3 className="text-gray-500 text-sm font-medium">
                            Weight
                          </h3>
                          <h1 className="text-lg font-semibold text-gray-800">
                            {instantData.weight} kg
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-start justify-between  px-4 py-2  w-ful">
                        <div className="relative h-14 w-24 ">
                          <img
                            alt="package-dimensions"
                            src={require("src/assets/images/shoes-box.png")}
                            className="h-full w-full"
                          />
                          <p className="text-[10px] absolute right-14 top-10 font-medium">
                            {Math.round(Number(instantData.width))}cm(w)
                          </p>
                          <p className="text-[10px] absolute right-20 top-4 font-medium">
                            {Math.round(Number(instantData.height))}cm(h)
                          </p>
                          <p className="text-[10px] absolute right-0 top-10 font-medium">
                            {Math.round(Number(instantData.length))}cm(l)
                          </p>
                        </div>
                        <div className="">
                          <p className="text-xs">
                            {" "}
                            Weight: {instantData.weight}kg
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-end mt-4">
                    <div
                      className={cn(
                        "bg-green-500 text-white flex items-center justify-center py-2 px-4 rounded-md w-36",
                        quoteDetails.getValues("normalQuote") === true
                          ? "bg-green-500"
                          : "bg-gray-200 text-gray-500"
                      )}
                    >
                      {loadingQuote ? (
                        <Loader className="h-5 w-5 animate-spin" />
                      ) : (
                        <p className="text-center">
                          {Number(quoteWithoutPet).toFixed(2)} €
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {petData.petType && (
                <>
                  {/* Pet Delivery Quote */}
                  <div className="mt-6 border-t border-gray-200 pt-4">
                    <h3 className="text-lg text-gray-500 font-medium">
                      Pet Delivery Quote
                    </h3>
                    {loadingQuotePet ? (
                      <div className="flex items-center justify-between space-x-4 p-4 bg-gray-50 rounded">
                        <div className="flex items-center space-x-4">
                          <Skeleton className="h-4 w-12 bg-gray-200 rounded" />
                          <Skeleton className="h-4 w-16 bg-gray-200 rounded" />
                          <Skeleton className="h-4 w-20 bg-gray-200 rounded" />
                          <Skeleton className="h-4 w-24 bg-gray-200 rounded" />
                        </div>
                        <Skeleton className="h-10 w-24 bg-gray-200 rounded" />
                      </div>
                    ) : (
                      <div className="flex items-center justify-between p-4 bg-gray-50 rounded">
                        <div className="flex-1 flex items-start space-x-6">
                          <div className="flex flex-col">
                            <h3 className="text-gray-500 text-sm font-medium">
                              Weight
                            </h3>
                            <h1 className="text-lg font-semibold text-gray-800">
                              {petData.petWeight} kg
                            </h1>
                          </div>
                          <div className="flex flex-col">
                            <h3 className="text-gray-500 text-sm font-medium">
                              Type
                            </h3>
                            {petData.petType.toLowerCase() === "dog" ? (
                              <PiDog className="h-6 w-6 text-gray-800" />
                            ) : (
                              <PiCat className="h-6 w-6 text-gray-800" />
                            )}
                          </div>
                          <div className="flex flex-col">
                            <h3 className="text-gray-500 text-sm font-medium">
                              Available Cage
                            </h3>
                            <h1 className="text-lg font-semibold text-gray-800">
                              {petData.petHasBox ? "Yes" : "No"}
                            </h1>
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <img
                            alt="pet"
                            src={petData.petForLocalImage}
                            className="h-20 w-20 object-cover rounded-lg shadow-sm"
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex justify-end mt-4">
                      <div
                        className={cn(
                          "bg-green-500 text-white flex items-end justify-center py-2 px-4 rounded-md w-36",
                          quoteDetails.getValues("petQuote") === true
                            ? "bg-green-500"
                            : "bg-gray-200 text-gray-500"
                        )}
                      >
                        {loadingQuotePet ? (
                          <Loader className="h-5 w-5 animate-spin" />
                        ) : (
                          <p className="text-center">
                            {Number(quoteWithPet).toFixed(2)} €
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Submit Button */}
              <button
                disabled={creatingFreight || loadingQuote}
                onClick={handleContinue}
                type="submit"
                className="bg-blue-500 text-white px-5 py-2 rounded-lg flex items-center space-x-3 mt-6 hover:bg-blue-600 transition-colors duration-200 disabled:bg-gray-300 disabled:text-gray-600"
              >
                {creatingFreight ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <>
                    <span>Create Instant Freight</span>
                    <ArrowRight className="h-5 w-5 transition-transform duration-200 ease-in-out" />
                  </>
                )}
              </button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CalculateQuote;
