import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { BASE_URL, REGISTER_FREIGHT_PAYMENT } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { BookingCustomerType } from "src/types/types";
import * as animationData from "../../assets/SuccessPurchase.json";
import { Button } from "../ui/button";

interface CompletePaymentBookingCheckoutProps {
  profile: {
    Email: string;
    firstname: string;
    lastname: string;
  };
  booking: BookingCustomerType;
  customerId: string;
  clientSecret: string;
  paymentIntentId: string;
  attachPaymentMethod: (paymentMethodToken: string) => void;
  selectedMethod: any;
  selectedWithPaymentMethod: boolean;
}

const CompletePaymentBookingCheckout: React.FC<
  CompletePaymentBookingCheckoutProps
> = ({
  attachPaymentMethod,
  booking,
  clientSecret,
  customerId,
  paymentIntentId,
  profile,
  selectedMethod,
  selectedWithPaymentMethod,
}) => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const stripe = useStripe();
  const elements = useElements();
  const [loadingRetrieve, setLoadingRetrieve] = useState(false);
  const [hideInput, setHideInput] = useState(false);
  const [successfulPayment, setSuccessfulPayment] = useState(false);
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [successfulWithSelectedMethod, setSuccesfulWithSelectedMethod] =
    useState(false);

  useEffect(() => {
    if (selectedWithPaymentMethod && stripe !== null) {
      setTimeout(() => {
        setSuccesfulWithSelectedMethod(true);
      }, 1000);
    } else {
      return;
    }
  }, [selectedWithPaymentMethod, stripe]);

  // RETRIEVE PAYMENT
  const retrievePayment = async (e?: any) => {
    setLoadingRetrieve(true);
    e?.preventDefault();
    if (!stripe || !elements) return;

    //@ts-ignore
    const result = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: profile?.Email,
          name: `${profile?.firstname} ${profile?.lastname}`,
        },
      },
    });

    const response: any = await attachPaymentMethod(result?.paymentMethod?.id!);
    if (response?.message === "Payment method associated successfully") {
      confirmPayment(response?.data.payment_method_id);
      setSuccessfulPayment(true);
      setLoadingRetrieve(false);
    }

    return response;
  };

  //CONFIRM PAYMENT
  const confirmPayment = async (paymentMethod: string) => {
    const { error, paymentIntent } = await stripe!.confirmPayment({
      clientSecret: clientSecret,
      confirmParams: {
        payment_method: paymentMethod,

        return_url: `https://runnme.com/dashboard-customer`,
      },
      redirect: "if_required",
    });
    if (paymentIntent?.status === "succeeded") {
      setHideInput(true);

      toast.success(`Payment successful`);
      setShowSuccessfulMessage(true);

      setTimeout(() => {
        registerFreightPayment();
        setHideInput(false);
      }, 2000);
    }

    if (error?.type === "card_error" || error?.type === "validation_error") {
      toast.error(`${error.message}`);
    } else {
      setMessage("An unexpected error occured");
    }
  };
  useEffect(() => {
    retrievePaymentWithRegisteredCard();
  }, [successfulWithSelectedMethod]);

  const retrievePaymentWithRegisteredCard = async () => {
    if (!stripe || !elements) return;

    const response: any = await attachPaymentMethod(selectedMethod?.id!);

    if (
      //   response?.message === "Payment method associated successfully" ||
      response.message ===
      "The payment method is already associated with a user."
    ) {
      setSuccessfulPayment(true);
      confirmPayment(selectedMethod?.id!);
    }
  };

  const registerFreightPayment = async () => {
    const response = await fetch(
      `${BASE_URL}${REGISTER_FREIGHT_PAYMENT}?user_id=${user_id}&freight_id=${booking?.freight_id}&freight_amount=${booking?.quote_amount}&booking_id=${booking?.booking_id}&stripe_freight_payment_id=${paymentIntentId}&stripe_customer_id=${customerId}&is_freight_amount_paid=1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Freight payment registered successfully") {
          toast.success(data.message);
          //   completeJob();
          navigate(`/customer/booking/${booking.booking_id}`);
        }
      });
    return response;
  };

  //   const completeJob = async () => {
  //     const response = await fetch(`${BASE_URL}${JOB_COMPLETE}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         AccessToken: token,
  //       },
  //       body: JSON.stringify({
  //         user_id: user_id,
  //         booking_status: "complete",
  //         booking_id: booking?.booking_id,
  //         completed_by: "customer",
  //         job_completed: true,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data.message === "Job completed successfully.") {
  //           //   refetch();
  //           navigate(`/customer/booking/${booking.booking_id}`);
  //         }
  //       });

  //     return response;
  //   };
  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (successfulPayment && !showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2 w-full justify-center items-center">
        <p className="text-lg font-medium">Fulfilling payment</p>

        <Loader2 className="h-10 w-10 animate-spin" />
      </div>
    );
  }
  if (successfulPayment && showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2  w-full justify-center items-center">
        <p className="text-lg font-medium">Payment successful!</p>
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={isStopped}
          isPaused={isPaused}
        />
      </div>
    );
  }
  return (
    <form id="payment-form" onSubmit={retrievePayment}>
      {!selectedWithPaymentMethod && (
        <>
          <h1 className="text-lg border-b pb-2 font-medium mb-4">
            Enter your card details
          </h1>
          <CardElement />
          <div className="mt-4 ml-auto justify-end w-full flex">
            <Button variant={"freightBlue"}>
              {loadingRetrieve ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                `Pay ${booking?.quote_amount}€`
              )}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default CompletePaymentBookingCheckout;
