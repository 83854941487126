import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { ALL_ROUTES, BASE_URL, GOOGLE_API_KEY } from "../api/config";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import { Form } from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Switch } from "../components/ui/switch";
import { TimePicker } from "../components/ui/time-picker";
import useLocalStorage from "../hooks/use-local-storage";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

const pickupDropoffSchema = z.object({
  origin: z.any(),
  destination: z.any(),
  hourDeparture: z.any(),
  hourArrival: z.any(),
  oneWay: z.boolean(),
});

const DeliveryRouteEdit = () => {
  const navigate = useNavigate();
  const [tripPackageDetails, setTripPackageDetails] = useLocalStorage(
    "trip_package_details"
  );
  const [pickupDropoffTrip, setPickupDropoffTrip] = useLocalStorage(
    "trip_pickup_dropoff"
  );
  const originRef = useRef<any>(null);
  const destinationRef = useRef<any>(null);
  const [searchResult, setSearchResult] = useState<any>();

  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const params = useParams();

  const [searchDropoffResult, setSearchDropoffResult] = useState<any>();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const formOriginDestination = useForm<z.infer<typeof pickupDropoffSchema>>({
    resolver: zodResolver(pickupDropoffSchema),
    defaultValues: {
      destination: "",
      origin: "",
      hourArrival: new Date(),
      hourDeparture: new Date(),
      oneWay: true,
    },
  });

  const [locationPackage, setLocationPackage] = useState({
    originName: "",
    originLat: "",
    originLng: "",
    originAddress: "",
    destinationName: "",
    destinationLat: "",
    destinationLng: "",
    destinationAddress: "",
  });

  const getRoute = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?user_id=${user_id}&route_id=${params.routeId}&sort=desc`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const {
    data: routes,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["routes_user", params.routeId],
    queryFn: getRoute,
  });

  function onPlaceChanged() {
    if (searchResult !== null && searchResult !== undefined) {
      formOriginDestination.setValue("origin", searchResult?.getPlace().name);
      setLocationPackage((prev) => ({
        ...prev,
        originLat: searchResult?.getPlace().geometry?.location.lat(),
        originLng: searchResult?.getPlace().geometry?.location.lng(),
        originName: searchResult?.getPlace().name,
        originAddress: searchResult?.getPlace().formatted_address,
        originCity: searchResult?.getPlace().name,
      }));
    }
  }

  function onLoad(autoComplete: any) {
    setSearchResult(autoComplete);
  }

  function onLoadDropoff(autocomplete: any) {
    setSearchDropoffResult(autocomplete);
  }

  function onPlaceChangedDropoff() {
    if (searchDropoffResult !== null && searchDropoffResult !== undefined) {
      formOriginDestination.setValue(
        "destination",
        searchDropoffResult?.getPlace().name
      );

      setLocationPackage((prev) => ({
        ...prev,
        destinationLat: searchDropoffResult
          ?.getPlace()
          .geometry?.location.lat(),
        destinationLng: searchDropoffResult
          ?.getPlace()
          .geometry?.location.lng(),
        destinationName: searchDropoffResult?.getPlace().name,
        destinationAddress: searchDropoffResult?.getPlace().formatted_address,
        destinationCity: searchDropoffResult?.getPlace().name,
      }));
    }
  }
  const onSubmitOriginDestination = async (
    values: z.infer<typeof pickupDropoffSchema>
  ) => {
    if (values.origin && values.destination) {
      setTripPackageDetails({
        origin: values.origin,
        destination: values.destination,
        originLat: locationPackage.originLat,
        originLng: locationPackage.originLng,
        timeDeparture: `${
          pickupDropoffTrip?.pickup
        } ${values.hourDeparture.getHours()}:${values.hourDeparture.getMinutes()}:00`,
        timeArrival: `${
          pickupDropoffTrip?.dropoff
        } ${values.hourDeparture.getHours()}:${values.hourDeparture.getMinutes()}:00`,
        destinationLat: locationPackage.destinationLat,
        originAddress: locationPackage.originAddress,
        originCity: locationPackage.originName,
        destinationLng: locationPackage.destinationLng,
        destinationAddress: locationPackage.destinationAddress,
        destinationCity: locationPackage.destinationName,
        oneWay: values.oneWay,
      });
      navigate(`/carrier/trips/prices/edit/${params.routeId}`);
    }
  };

  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex items-start space-x-2  py-10 max-w-7xl mx-auto w-full h-[94vh] no-scrollbar">
        <div className="sm:flex pl-2 pr-2 flex-col w-full">
          <div className="flex pr-2 items-center justify-between mt-10">
            <h1 className="text-2xl font-medium text-gray-500">
              Edit Trip Package
            </h1>
          </div>
          <div className="bg-white shadow pr-6 mt-10 pt-4">
            <div className="sm:flex sm:flex-col ml-4">
              {/* Inputs de texto para las ubicaciones */}
              <Form {...formOriginDestination}>
                <form
                  className="flex flex-col w-full space-y-10"
                  onSubmit={formOriginDestination.handleSubmit(
                    onSubmitOriginDestination
                  )}
                >
                  <div
                    className="sm:grid grid-cols-2 gap-10  w-full
                  "
                  >
                    <div>
                      <h1 className="text-sm font-medium border-b-2 mb-4 pb-2">
                        Direction{" "}
                      </h1>
                      <div className="w-full mb-4">
                        {isLoaded && (
                          <Controller
                            control={formOriginDestination.control}
                            name="origin"
                            render={({ field }) => (
                              <>
                                <Autocomplete
                                  {...field}
                                  onPlaceChanged={() => {
                                    onPlaceChanged();
                                  }}
                                  onLoad={onLoad}
                                  options={{
                                    fields: [
                                      "address_components",
                                      "geometry",
                                      "formatted_address",
                                      "types",
                                    ],
                                    componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                                  }}
                                >
                                  <Input placeholder="Origin" ref={originRef} />
                                </Autocomplete>
                              </>
                            )}
                          />
                        )}
                      </div>
                      <div className="w-full mb-4">
                        {isLoaded && (
                          <Controller
                            control={formOriginDestination.control}
                            name="destination"
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                onPlaceChanged={onPlaceChangedDropoff}
                                onLoad={(val) => {
                                  formOriginDestination.setValue(
                                    "destination",
                                    val?.getPlace()?.name
                                  );
                                  onLoadDropoff(val);
                                }}
                                options={{
                                  fields: [
                                    "address_components",
                                    "geometry",
                                    "formatted_address",
                                    "types",
                                  ],
                                  componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                                }}
                              >
                                <Input
                                  placeholder="Destination"
                                  ref={destinationRef}
                                />
                              </Autocomplete>
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <h1 className="text-sm font-medium border-b-2 mb-4 pb-2">
                        Time{" "}
                      </h1>
                      <div className="w-full mb-4">
                        <h1 className="text-sm font-medium mb-2">
                          Time of Departure
                        </h1>
                        <Controller
                          control={formOriginDestination.control}
                          name="hourDeparture"
                          render={({ field }) => (
                            <div>
                              <TimePicker
                                date={field.value}
                                setDate={field.onChange}
                              />
                            </div>
                          )}
                        />
                      </div>
                      <div className="w-full mb-4">
                        <h1 className="text-sm font-medium mb-2">
                          Time of Arrival
                        </h1>
                        <Controller
                          control={formOriginDestination.control}
                          name="hourArrival"
                          render={({ field }) => (
                            <div>
                              <TimePicker
                                date={field.value}
                                setDate={field.onChange}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid grid-cols-2 gap-10  w-full border-t-2 pt-4">
                    <div>
                      <h1 className="text-sm font-medium">One way trip</h1>
                      <Controller
                        control={formOriginDestination.control}
                        name="oneWay"
                        render={({ field }) => (
                          <div className="flex items-center space-x-2 mt-4">
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                              color="#0022B6"
                            />
                            <p className="text-sm font-medium">One way trip?</p>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="pb-4 flex justify-center">
                    <Button
                      variant={"freightBlue"}
                      className=" w-60 sm:ml-auto"
                    >
                      NEXT PRICES: PACKAGES
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-2  mt-10 rounded ">
          <h1 className="text-lg font-medium">Details</h1>
          <div className="p-4 rounded flex flex-col space-y-3 bg-white shadow w-72">
            <div className="flex flex-col items-start">
              <p className="text-sm font-medium text-gray-500">Start date</p>
              <p className="text-sm font-medium">
                {new Date(routes?.data.routes[0].start_date).toDateString()}{" "}
                {new Date(routes?.data.routes[0].start_date).getHours()}:
                {new Date(routes?.data.routes[0].start_date).getMinutes()}
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-sm font-medium text-gray-500">End date</p>
              <p className="text-sm font-medium">
                {new Date(routes?.data.routes[0].end_date).toDateString()}{" "}
                {new Date(routes?.data.routes[0].end_date).getHours()}:
                {new Date(routes?.data.routes[0].end_date).getMinutes()}
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-sm font-medium text-gray-500">Origin</p>
              <p className="text-sm font-medium">
                {routes?.data.routes[0].origin_address}
              </p>
            </div>
            <div className="flex flex-col items-start mt-4">
              <p className="text-sm font-medium text-gray-500">Destination</p>
              <p className="text-sm font-medium">
                {routes?.data.routes[0].destination_address}
              </p>
            </div>
            <div className="flex flex-col items-start mt-4">
              <p className="text-sm font-medium text-gray-500">Destination</p>
              <p className="text-sm font-medium">
                {routes?.data.routes[0].destination_address}
              </p>
            </div>
            <div className="flex flex-col items-start mt-4">
              <p className="text-sm font-medium text-gray-500">
                Delivery Distance
              </p>
              <p className="text-sm font-medium">
                {routes?.data.routes[0].delivery_distance}
              </p>
            </div>
            {/* </div> */}
            <div className="flex flex-col items-start mt-4">
              <p className="text-sm font-medium text-gray-500">
                Delivery Duration
              </p>
              <p className="text-sm font-medium">
                {routes?.data.routes[0].delivery_duration}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryRouteEdit;
