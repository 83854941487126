import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useRef, useState } from "react";
import { GOOGLE_API_KEY } from "../api/config";
import Header from "../components/header/Header";
import { TripSteps } from "../components/trips/TripSteps";
import { Calendar } from "../components/ui/calendar";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";

const TripPackage = () => {
  const [fromInputValue, setFromInputValue] = useState<string>(""); // State for the origin
  const [toTimeValue, setToTimeValue] = useState<string | null>("10:00");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [tripStepsVisible, setTripStepsVisible] = useState(false); // Control TripSteps visibility
  const [submitFreightActive, setSubmitFreightActive] = useState(false);
  const [freightDetailsClicked, setFreightDetailsClicked] = useState(false);
  const [shipmentDetailsClicked, setShipmentDetailsClicked] = useState(false); // New state for Shipment Details
  const [finalDestination, setFinalDestination] = useState("");
  const [stops, setStops] = useState<string[]>([]); // Initialize with empty array
  const token = useTokenStoreStorage((state) => state.token);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setCalendarVisible(false);
  };

  const handleDayClick = (day: Date) => {
    handleDateChange(day);
  };

  // Validate form input
  const isFormValid = () => {
    return fromInputValue.trim() !== "" && toTimeValue !== null;
  };

  const handleNextClick = () => {
    if (isFormValid()) {
      setTripStepsVisible(true);
      setShipmentDetailsClicked(true); // Set shipment details clicked to true
    }
  };

  // Autocomplete refs
  const originAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(
    null
  );

  // Google Maps API loader
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const handleFromPlaceSelect = () => {
    if (originAutocompleteRef.current) {
      const place = originAutocompleteRef.current.getPlace();
      if (place && place.formatted_address) {
        setFromInputValue(place.formatted_address); // Set the origin value correctly

        // Get latitude and longitude for origin location
        const latLng = place.geometry?.location;
        if (latLng) {
          const olat = latLng.lat();
          const olng = latLng.lng();

          // Store latitude and longitude in local storage
          localStorage.setItem("olat", olat.toString());
          localStorage.setItem("olng", olng.toString());
        }
      }
    }
  };

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  return (
    <div>
      <Header />
      <div>
        <div className="flex flex-col py-10 bg-gray-50 no-scrollbar">
          <div className="max-w-4xl mx-auto w-full relative flex items-center justify-between">
            <div className="w-full border-b h-1 absolute top-4 z-10" />
            <div className="flex flex-col z-20">
              <div className="bg-[#1889ca] text-white z-20 h-10 w-10 rounded-full text-center items-center justify-center flex">
                <p className="text-sm font-medium">1</p>
              </div>
              <p className="text-xs w-20 mt-2 font-medium text-gray-500">
                Freight Details
              </p>
            </div>
            <div className="flex flex-col z-20 items-center">
              <div
                className={cn(
                  "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                  shipmentDetailsClicked ? "bg-[#1889ca] text-white" : ""
                )}
              >
                <p className="text-sm font-medium">2</p>
              </div>
              <p className="text-xs w-20 mt-2 text-center font-medium text-gray-500">
                Shipment Details
              </p>
            </div>
            <div className="flex flex-col z-20 items-end">
              <div
                className={cn(
                  "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                  submitFreightActive ? "bg-[#1889ca] text-white" : ""
                )}
              >
                <p className="text-sm font-medium">3</p>
              </div>
              <p className="text-xs w-20 mt-2 justify-end items-end text-right font-medium text-gray-500">
                Submit Freight
              </p>
            </div>
          </div>
        </div>

        {!tripStepsVisible && (
          <div className="max-w-4xl mx-auto w-full mt-10 min-h-44 bg-white p-4 rounded-lg shadow-md">
            <div className="relative flex items-center justify-between">
              {isLoaded && (
                <div>
                  <h1 className="text-sm font-medium mb-4 text-gray-500">
                    From: Origin
                  </h1>
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (originAutocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={handleFromPlaceSelect}
                    options={{
                      fields: [
                        "address_components",
                        "geometry",
                        "formatted_address",
                        "types",
                      ],
                      componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                    }}
                  >
                    <input
                      type="text"
                      placeholder="From"
                      value={fromInputValue}
                      onChange={(e) => setFromInputValue(e.target.value)}
                      className="border border-gray-300 rounded-md p-2 mr-2 w-[400px]"
                    />
                  </Autocomplete>
                </div>
              )}
              <div className="flex flex-col w-full">
                <h1 className="text-sm font-medium mb-4 text-gray-500">
                  When are you going?
                </h1>
                <div className="flex items-center space-x-2 w-full">
                  <div className="relative w-1/2">
                    <input
                      type="text"
                      placeholder="Select Date"
                      value={
                        selectedDate instanceof Date
                          ? selectedDate.toLocaleDateString()
                          : "Today"
                      }
                      onClick={() => setCalendarVisible(!calendarVisible)}
                      readOnly
                      className="p-2 border rounded-md cursor-pointer"
                    />
                    {calendarVisible && (
                      <Calendar
                        onDayClick={handleDayClick}
                        className="absolute z-[9999] bg-white shadow-sm"
                      />
                    )}
                  </div>
                  <div className="relative w-1/2">
                    <input
                      type="time"
                      value={toTimeValue || ""}
                      onChange={(e) => setToTimeValue(e.target.value)}
                      className="border border-gray-300 rounded-md p-2 mr-2 w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={handleNextClick}
              className={`rounded-md mt-4 w-[100px] p-2 ${
                isFormValid()
                  ? "bg-[#1889ca] text-white"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
              disabled={!isFormValid()}
            >
              Next
            </button>
          </div>
        )}

        {tripStepsVisible && (
          <div className="max-w-4xl mx-auto w-full mt-10 h-44">
            <TripSteps
              fromInputValue={fromInputValue}
              finalDestination={finalDestination}
              stops={stops}
              setFinalDestination={setFinalDestination}
              setStops={setStops}
              selectedTime={toTimeValue} // Pass the selected time here
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TripPackage;
