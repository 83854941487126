import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { BASE_URL, GET_SINGLE_STOP, STOP_UPDATE } from "../api/config";
import Header from "../components/header/Header";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { StopType } from "../types/types";
//@ts-ignore
// import MovingBox from "../assets/images/moving-box.png";
import { ChevronLeft, Pencil } from "lucide-react";
import { GOOGLE_API_KEY } from "../api/config";
import { Button } from "../components/ui/button";
//@ts-ignore
import Smallbox from "../assets/images/Smallbox.png";
//@ts-ignore
import Mediumbox from "../assets/images/Mediumbox.png";
//@ts-ignore
import Largebox from "../assets/images/Largebox.png";

const formEditStopSchema = z.object({
  origin: z.any(),
  destination: z.any(),
  packageSize: z.string().min(1),
  packageWeight: z.string().min(1),
  packagePrice: z.string().min(1),
});

const CarrierStopEdit = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const params = useParams();
  const [searchResult, setSearchResult] = useState<any>();
  const [searchDropoffResult, setSearchDropoffResult] = useState<any>();
  const [locationPackage, setLocationPackage] = useState({
    originName: "",
    originLat: "",
    originLng: "",
    originAddress: "",
    destinationName: "",
    destinationLat: "",
    destinationLng: "",
    destinationAddress: "",
  });
  const [originZipcode, setOriginZipcode] = useState("");
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [destinationZipcode, setDestinationZipcode] = useState("");
  const [loadingCreationRoute, setLoadingCreationRoute] = useState(false);
  const navigate = useNavigate();

  const originRef = useRef(null);
  const destinationRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const formEditPackage = useForm<z.infer<typeof formEditStopSchema>>({
    resolver: zodResolver(formEditStopSchema),
    defaultValues: {
      destination: "",
      origin: "",
      packagePrice: "",
      packageSize: "",
      packageWeight: "",
    },
  });

  function onLoad(autoComplete: any) {
    setSearchResult(autoComplete);
  }

  function onLoadDropoff(autocomplete: any) {
    setSearchDropoffResult(autocomplete);
  }

  const getStop = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_SINGLE_STOP}?user_id=${user_id}&stop_id=${params.stopId}`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: stop,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["stop", params.stopId],
    queryFn: getStop,
  });

  function onPlaceChanged() {
    if (searchResult !== null && searchResult !== undefined) {
      formEditPackage.setValue("origin", searchResult?.getPlace().name);
      setLocationPackage((prev) => ({
        ...prev,
        originLat: searchResult?.getPlace().geometry?.location.lat(),
        originLng: searchResult?.getPlace().geometry?.location.lng(),
        originName: searchResult?.getPlace().name,
        originAddress: searchResult?.getPlace().formatted_address,
        originCity: searchResult?.getPlace().name,
      }));
    }
  }

  function onPlaceChangedDropoff() {
    if (searchDropoffResult !== null && searchDropoffResult !== undefined) {
      formEditPackage.setValue(
        "destination",
        searchDropoffResult?.getPlace().name
      );

      setLocationPackage((prev) => ({
        ...prev,
        destinationLat: searchDropoffResult
          ?.getPlace()
          .geometry?.location.lat(),
        destinationLng: searchDropoffResult
          ?.getPlace()
          .geometry?.location.lng(),
        destinationName: searchDropoffResult?.getPlace().name,
        destinationAddress: searchDropoffResult?.getPlace().formatted_address,
        destinationCity: searchDropoffResult?.getPlace().name,
      }));
    }
  }
  useEffect(() => {
    if (!isLoading) {
      formEditPackage.reset({
        destination: stop?.data[0].destination_address,
        origin: stop?.data[0].origin_address,
        packagePrice: stop?.data[0].price.toFixed(2),
        packageWeight: stop?.data[0].package_weight.toString(),
        packageSize: stop?.data[0].package_size,
      });
    }
  }, [stop]);

  useEffect(() => {
    if (!isLoading) {
      setLocationPackage({
        destinationAddress: stop?.data[0].destination_address,
        destinationLat: stop?.data[0].destination_lat,
        destinationLng: stop?.data[0].destination_lng,
        destinationName: stop?.data[0].destination_address,
        originAddress: stop?.data[0].origin_address,
        originLat: stop?.data[0].origin_lat,
        originLng: stop?.data[0].origin_lng,
        originName: stop?.data[0].origin_address,
      });
    }
  }, [isLoading, stop]);

  const editStop = async (
    packageSize: string,
    packageWeight: string,
    price: string
  ) => {
    const response = await fetch(`${BASE_URL}${STOP_UPDATE}`, {
      method: "PUT",
      headers: {
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        origin_lat: locationPackage.originLat,
        origin_lng: locationPackage.originLng,
        origin_address: locationPackage.originAddress,
        origin_city: locationPackage.originName,
        destination_lat: locationPackage.destinationLat,
        destination_lng: locationPackage.destinationLng,
        destination_zipcode: destinationZipcode,
        destination_address: locationPackage.destinationAddress,
        destination_city: locationPackage.destinationName,
        price: price,
        package_size: packageSize,
        package_weight: packageWeight,
        stop_id: stop?.data[0].id,
        delivery_distance: distance,
        delivery_duration: duration,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  async function calculateRoute() {
    setLoadingCreationRoute(true);
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: {
        lat: Number(stop?.data?.[0].origin_lat),
        lng: Number(stop?.data?.[0].origin_lng),
      },
      destination: {
        //@ts-ignore
        lat: Number(stop?.data?.[0].destination_lat),
        //@ts-ignore
        lng: Number(stop?.data?.[0].destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    //@ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);
    //@ts-ignore
    setDuration(results.routes[0].legs[0].duration.text);
    setOriginZipcode(
      //@ts-ignore
      `${results.routes[0].legs[0].start_address}`.match(/\b\d{5}\b/g)[0]
    );

    setDestinationZipcode(
      //@ts-ignore
      `${results.routes[0].legs[0].end_address}`.match(/\b\d{5}\b/g)[0]
    );
    setTimeout(() => {
      setLoadingCreationRoute(false);
    }, 2000);
  }

  useEffect(() => {
    calculateRoute();
  }, []);

  const onSubmit = (values: z.infer<typeof formEditStopSchema>) => {};
  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex items-start max-w-7xl mx-auto p-16 w-full h-[94vh] no-scrollbar ">
        <div className="flex flex-col    w-full">
          <div
            onClick={() => navigate("/carrier/trips")}
            className="flex items-center space-x-2 group cursor-pointer mb-4"
          >
            <ChevronLeft className="text-freightBlueDark h-5 w-5" />
            <h1 className="text-freightBlueDark text-sm group-hover:underline ">
              Back
            </h1>
          </div>
          <div className="flex items-center justify-between mt-10">
            <h1 className="text-2xl font-medium text-gray-500">Edit Stop</h1>
          </div>
          <div className=" bg-white shadow mt-4 p-4 w-full">
            {isLoading ? (
              <div className="w-full h-20"></div>
            ) : (
              <Form {...formEditPackage}>
                <form
                  className="w-full flex-col flex"
                  onSubmit={formEditPackage.handleSubmit(onSubmit)}
                >
                  <div className=" w-full gap-10 mt-4">
                    <div className="w-">
                      <h1 className="text-lg font-medium border-b-2 mb-4 pb-2">
                        Direction{" "}
                      </h1>
                      <div className="w-full mb-4">
                        <h1 className="text-sm font-medium mb-2">Origin</h1>
                        {isLoaded && (
                          <Controller
                            control={formEditPackage.control}
                            name="origin"
                            render={({ field, fieldState }) => (
                              <>
                                <Autocomplete
                                  {...field}
                                  onPlaceChanged={() => {
                                    onPlaceChanged();
                                  }}
                                  onLoad={onLoad}
                                  options={{
                                    fields: [
                                      "address_components",
                                      "geometry",
                                      "formatted_address",
                                      "types",
                                    ],
                                    componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                                  }}
                                >
                                  <Input
                                    placeholder={stop?.data?.[0].origin_address}
                                    ref={originRef}
                                  />
                                </Autocomplete>
                                {fieldState.error && (
                                  <p className="text-sm font-medium text-red-500">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <h1 className="text-sm font-medium mb-2">
                          Destination
                        </h1>

                        {isLoaded && (
                          <Controller
                            control={formEditPackage.control}
                            name="destination"
                            render={({ field, fieldState }) => (
                              <>
                                <Autocomplete
                                  {...field}
                                  onPlaceChanged={onPlaceChangedDropoff}
                                  onLoad={(val) => {
                                    formEditPackage.setValue(
                                      "destination",
                                      val?.getPlace()?.name
                                    );
                                    onLoadDropoff(val);
                                  }}
                                  options={{
                                    fields: [
                                      "address_components",
                                      "geometry",
                                      "formatted_address",
                                      "types",
                                    ],
                                    componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                                  }}
                                >
                                  <Input
                                    placeholder={
                                      stop?.data?.[0].destination_address
                                    }
                                    ref={destinationRef}
                                  />
                                </Autocomplete>
                                {fieldState.error && (
                                  <p className="text-sm font-medium text-red-500">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        )}
                      </div>
                      <div className="flex flex-col space-y-4">
                        <div className="flex flex-col items-start">
                          <h1 className="text-sm font-medium mb-2">Size</h1>
                          <Controller
                            control={formEditPackage.control}
                            name="packageSize"
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  {...field}
                                  onValueChange={(val) => {
                                    formEditPackage.setValue(
                                      "packageSize",
                                      val
                                    );
                                  }}
                                >
                                  <SelectTrigger className="">
                                    <SelectValue placeholder="Size" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="small">Small</SelectItem>
                                    <SelectItem value="medium">
                                      Medium
                                    </SelectItem>
                                    <SelectItem value="large">Large</SelectItem>
                                  </SelectContent>
                                </Select>
                                {fieldState.error && (
                                  <p className="text-sm font-medium text-red-500">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>

                        <FormField
                          control={formEditPackage.control}
                          name="packageWeight"
                          render={({ field, fieldState }) => (
                            <div>
                              <FormItem>
                                <FormLabel>Weight</FormLabel>
                                <div className="flex items-center space-x-2">
                                  <FormControl>
                                    <Input {...field} name="packageWeight" />
                                  </FormControl>
                                  <p className="text-sm font-medium">kg</p>
                                </div>
                              </FormItem>
                              {fieldState.error && (
                                <p className="text-sm font-medium text-red-500">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </div>
                          )}
                        />
                        <FormField
                          control={formEditPackage.control}
                          name="packagePrice"
                          render={({ field, fieldState }) => (
                            <div>
                              <FormItem>
                                <FormLabel>Price</FormLabel>
                                <div className="flex items-center space-x-2">
                                  <FormControl>
                                    <Input {...field} name="price" />
                                  </FormControl>
                                  <p className="text-sm font-medium">€</p>
                                </div>
                              </FormItem>
                              {fieldState.error && (
                                <p className="text-red-500 font-medium">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      {stop?.data?.[0].package_size === "small" && (
                        <img
                          src={Smallbox}
                          className=" w-24 object-contain"
                          alt="small-box"
                        />
                      )}
                      {stop?.data?.[0].package_size === "medium" && (
                        <img
                          src={Mediumbox}
                          className=" w-24 object-contain"
                          alt="medium-box"
                        />
                      )}
                      {stop?.data?.[0].package_size === "large" && (
                        <img
                          src={Largebox}
                          className=" w-24 object-contain"
                          alt="large-box"
                        />
                      )}
                    </div>
                  </div>
                  <Button variant={"freightBlue"} className="mt-4 w-40 ml-auto">
                    <Pencil className="h-4 w-4 mr-2" />
                    <p className="text-sm font-medium">Edit Stop</p>
                  </Button>
                </form>
              </Form>
            )}
          </div>
        </div>
        <div className="   mt-[85px] ml-4">
          <h1 className="text-2xl font-medium text-gray-500 ">Details</h1>
          <div className="bg-white shadow mt-4 p-2 w-full">
            {isLoading ? (
              <div className="flex flex-col space-y-4"></div>
            ) : (
              <>
                {stop.data.map((stop: StopType) => (
                  <div className="flex flex-col space-y-4 p-4 bg-gray-50 m-4 rounded border border-border w-80 ">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium">Origin</p>
                      <p className="text-sm font-medium text-gray-500">
                        {stop.origin_address}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium">Destination</p>
                      <p className="text-sm font-medium text-gray-500">
                        {stop.destination_address}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium">Distance</p>
                      <p className="text-sm font-medium text-gray-500">
                        {stop.delivery_distance}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium">Duration</p>
                      <p className="text-sm font-medium text-gray-500">
                        {stop.delivery_duration}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium">Package</p>
                      <div className="flex items-center space-x-2">
                        <p className="text-sm font-medium text-gray-500">
                          {stop.package_weight}kg
                        </p>
                        <p className="text-sm font-medium text-gray-500 capitalize">
                          {stop.package_size}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium">Price</p>
                      <div className="flex flex-col">
                        <p className="text-sm font-medium text-gray-500">
                          {stop.price.toFixed(2)}€
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierStopEdit;
