import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import {
  ArrowLeft,
  Check,
  Grip,
  Loader2,
  Mail,
  Pencil,
  Phone,
  Settings,
  Star,
  Wallet,
} from "lucide-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  BASE_URL_IMG_2,
  GET_IMAGE,
  GOOGLE_API_KEY,
  INSTANT_FREIGHTS,
  INSTANT_FREIGHT_REVIEWS,
} from "../api/config";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Rating } from "../components/Rating";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { usePayInstantFreight } from "../hooks/use-pay-instant-freight";
import { useSubmitInstantReview } from "../hooks/use-submit-instant-review";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { InstantFreight, InstantReviewType } from "../types/types";

//@ts-ignore
import { BiTrash } from "react-icons/bi";
import ModalCompleteInstantFreight from "../components/modal/ModalCompleteInstantFreight";
import ModalInstantFreightInTransit from "../components/modal/ModalInstantFreightInTransit";
import ModalInstantFreightPickingUp from "../components/modal/ModalInstantFreightPickingUp";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheets";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import { useComplteInstantFreight } from "../hooks/use-complete-instant-freight";
import { useDeleteInstantService } from "../hooks/use-delete-instant-service";
import { useInstantFreightInTransit } from "../hooks/use-instant-freight-in-transit";
import { useInstantFreightPickingUp } from "../hooks/use-instant-freight-picking-up";
const containerStyle = {
  width: "440px",
  height: "520px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};
const ViewInstantFreight = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const [map, setMap] = useState(null);
  const [freight, setFreight] = useState<InstantFreight | null>(null);
  const modalSubmitInstantReview = useSubmitInstantReview();
  const params = useParams();
  const modalFreightInTransit = useInstantFreightInTransit();
  const modalFreightPickingUp = useInstantFreightPickingUp();
  const modalFreightConfirmed = useComplteInstantFreight();
  const [originPosition, setOriginPosition] = useState<{
    lat: number;
    lng: number;
  }>();
  const [destinationPosition, setDestinationPosition] = useState<{
    lat: number;
    lng: number;
  }>();
  const modalPayInstantFreight = usePayInstantFreight();
  const [directionResponse, setDirectionsResponse] = useState<any>(null);
  const [distance, setDistance] = useState<any>();
  const [duration, setDuration] = useState<any>();
  const [timeLeftFromPickupToTransit, setTimeLeftFromPickupToTransit] =
    useState({
      pickup: "",
      delivery: "",
    });
  const modalDeleteInstantService = useDeleteInstantService();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const getInstantFreights = async () => {
    const response = await fetch(
      `${BASE_URL}${INSTANT_FREIGHTS}?user_id=${user_id}&freight_id=${params.id}&sort=ASC`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());
    return response;
  };

  const {
    data: instantFreights,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["instant_freights", params.id],
    queryFn: getInstantFreights,
  });
  const navigate = useNavigate();

  const getInstantFreightReviews = async () => {
    const response = await fetch(
      `${BASE_URL}${INSTANT_FREIGHT_REVIEWS}?instant_freight_id=${params.id}&user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: instantFreightReviews,
    isLoading: isLoadingBookingReviews,
    refetch: refetchReviews,
  } = useQuery({
    queryKey: ["instant_freight_reviews", params.id],
    queryFn: getInstantFreightReviews,
  });

  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(instantFreights?.data[0][0]?.origin_lat),
        lng: Number(instantFreights?.data[0][0]?.origin_lng),
      },
      destination: {
        lat: Number(instantFreights?.data[0][0]?.destination_lat),
        lng: Number(instantFreights?.data[0][0]?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance?.text);
    setDuration(results.routes[0].legs[0].duration?.text);
  }

  useEffect(() => {
    setTimeout(() => {
      if (
        instantFreights?.data[0][0]?.origin_lat &&
        instantFreights?.data[0][0]?.destination_lat
      ) {
        calculateRoute();
      }
    }, 2000);
  }, [instantFreights]);

  useEffect(() => {
    const currentDate = moment();
    const futureDelivery = moment(
      `${instantFreights?.data[0][0].updated_at.split(" ")[0]} ${
        instantFreights?.data[0][0].estimated_deliver_schedule
      }:00 `
    );

    const timeLeftTillDelivery = moment(
      futureDelivery.diff(currentDate)
    ).format("HH:mm:ss");
    const futurePickup = moment(
      `${instantFreights?.data[0][0].updated_at.split(" ")[0]} ${
        instantFreights?.data[0][0].estimated_pickup_schedule
      }:00`
    );
    const timeLeftTillPickup = moment(futurePickup.diff(currentDate)).format(
      "HH:mm:ss"
    );

    setTimeLeftFromPickupToTransit({
      pickup: timeLeftTillPickup,
      delivery: timeLeftTillDelivery,
    });
  }, [instantFreights]);

  return (
    <SidebarLayout>
      <div
        onClick={() => navigate("/my-instant-freights")}
        className="flex items-center space-x-2 mb-4 cursor-pointer transition-transform transform"
      >
        <ArrowLeft className="h-5 w-5 text-freightBlueDark" />
        <h3 className="text-freightBlueDark text-sm font-medium hover:text-freightBlue hover:underline">
          Back
        </h3>
      </div>
      <div className="my-4 flex items-center justify-between">
        <h1 className="text-lg font-semibold text-gray-800">
          Instant Service - <span className="text-gray-500">Details</span>
        </h1>

        {isLoading ? (
          <div className="flex items-center">
            <Skeleton className="h-5 w-24" />
          </div>
        ) : (
          <div
            className={cn(
              "px-4 py-2 rounded-lg shadow-md flex items-center",
              instantFreights?.data[0][0]?.status === "created" &&
                "bg-blue-500",
              instantFreights?.data[0][0]?.status === "assigned" &&
                "bg-yellow-500",
              instantFreights?.data[0][0]?.status === "paid" && "bg-green-500",
              instantFreights?.data[0][0]?.status === "picking-up" &&
                "bg-orange-500",
              instantFreights?.data[0][0]?.status === "in-transit" &&
                "bg-teal-500",
              instantFreights?.data[0][0]?.status === "finished" &&
                "bg-purple-500",
              instantFreights?.data[0][0]?.status === "confirmed" &&
                "bg-indigo-500",
              instantFreights?.data[0][0]?.status === "cancelled" &&
                "bg-gray-500"
            )}
          >
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger>
                  <h1 className="capitalize text-white font-medium text-md">
                    {instantFreights?.data[0][0]?.status}
                  </h1>
                </TooltipTrigger>
                <TooltipContent className="p-2 text-sm bg-gray-700 text-white rounded">
                  {instantFreights?.data[0][0]?.status === "assigned" && (
                    <p>Waiting for customer to pay...</p>
                  )}
                  {instantFreights?.data[0][0]?.status === "paid" && (
                    <p>Waiting for package pick up...</p>
                  )}
                  {instantFreights?.data[0][0]?.status === "picking-up" && (
                    <p>Waiting for package delivery...</p>
                  )}
                  {/* Add more status descriptions as needed */}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
      <div className=" bg-white shadow rounded p-4">
        {isLoading ? (
          <div className="flex items-start space-x-6 justify-between animate-pulse">
            {/* Left Column Skeletons */}
            <div className="flex flex-col items-start space-y-4 w-2/3">
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-start bg-gray-100 rounded-lg p-4 w-full shadow-sm"
                  >
                    <Skeleton className="h-5 w-2/3 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-lg" />
                    <Skeleton className="h-5 w-1/3 mt-4 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-lg" />
                  </div>
                ))}
            </div>

            {/* Right Column Skeletons */}
            <div className="flex flex-col items-end space-y-4 w-1/3">
              <Skeleton className="h-5 w-24 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-lg" />
              <div className="bg-gray-100 px-2 py-2 rounded-lg shadow-sm w-full flex items-center justify-center">
                <Skeleton className="h-5 w-3/4 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-lg" />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col space-y-6 p-6 ">
              <div className="flex items-start justify-between">
                <div className="flex flex-col">
                  <div className="flex items-start space-x-4">
                    {freight?.status !== "cancelled" && (
                      <div className="relative flex flex-col items-center space-y-6">
                        {/* Order Status Flow */}
                        <div className="w-1 h-40 bg-gray-200 absolute z-0 top-4" />

                        {/* Step 1: Order Accepted */}
                        <div className="relative z-10">
                          {["assigned", "paid"].includes(
                            instantFreights?.data[0][0].status
                          ) ? (
                            <Check className="text-white bg-green-500 h-6 w-6 rounded-full p-1" />
                          ) : (
                            <div
                              className={`h-4 w-4 rounded-full z-10 ${
                                instantFreights?.data[0][0].status === "created"
                                  ? "bg-gray-300"
                                  : "bg-green-500"
                              }`}
                            />
                          )}
                        </div>

                        {/* Step 2: Picking Up */}
                        <div className="relative z-10">
                          {instantFreights?.data[0][0].status ===
                          "picking-up" ? (
                            <Check className="text-white bg-green-500 h-6 w-6 rounded-full p-1" />
                          ) : (
                            <div
                              className={`h-4 w-4 rounded-full z-10 ${
                                instantFreights?.data[0][0].status ===
                                  "created" ||
                                instantFreights?.data[0][0].status ===
                                  "assigned"
                                  ? "bg-gray-300"
                                  : "bg-green-500"
                              }`}
                            />
                          )}
                        </div>

                        {/* Step 3: In Transit */}
                        <div className="relative z-10">
                          {instantFreights?.data[0][0].status ===
                          "in-transit" ? (
                            <Check className="text-white bg-green-500 h-6 w-6 rounded-full p-1" />
                          ) : (
                            <div
                              className={`h-4 w-4 rounded-full z-10 ${
                                instantFreights?.data[0][0].status !==
                                "in-transit"
                                  ? "bg-gray-300"
                                  : "bg-green-500"
                              }`}
                            />
                          )}
                        </div>

                        {/* Step 4: Arrived */}
                        <div className="relative z-10">
                          {instantFreights?.data[0][0].status ===
                          "confirmed" ? (
                            <Check className="text-white bg-green-500 h-6 w-6 rounded-full p-1" />
                          ) : (
                            <div className="h-4 w-4 bg-gray-300 rounded-full z-10" />
                          )}
                        </div>
                      </div>
                    )}

                    {/* Status Text */}
                    <div className="flex flex-col space-y-4 mt-4">
                      <p
                        className={`text-sm font-medium ${
                          instantFreights?.data[0][0].status !== "created"
                            ? "text-gray-500"
                            : ""
                        }`}
                      >
                        Order Accepted
                      </p>
                      <p
                        className={`text-sm font-medium ${
                          instantFreights?.data[0][0].status !== "picking-up"
                            ? "text-gray-500"
                            : "text-green-500"
                        }`}
                      >
                        Transporter is coming to pick up the package
                      </p>
                      <p
                        className={`text-sm font-medium ${
                          instantFreights?.data[0][0].status !== "in-transit"
                            ? "text-gray-500"
                            : ""
                        }`}
                      >
                        Carrier is transporting the package
                      </p>
                      <p
                        className={`text-sm font-medium ${
                          instantFreights?.data[0][0].status !== "confirmed"
                            ? "text-gray-500"
                            : ""
                        }`}
                      >
                        Package has arrived at the destination
                      </p>
                    </div>
                  </div>

                  {/* Package Details */}
                  <div className="mt-8">
                    <h2 className="text-lg font-bold mb-4">Shipping Details</h2>
                    <div className="grid grid-cols-2 gap-6 bg-gray-100 p-4 rounded-lg">
                      <div>
                        <h3 className="text-sm font-medium">Origin Address</h3>
                        <p className="text-gray-500">{`${instantFreights?.data[0][0].origin_address}, ${instantFreights?.data[0][0].origin_city}`}</p>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium">Distance</h3>
                        <p className="text-gray-500">{`${instantFreights?.data[0][0].delivery_distance} km`}</p>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium">
                          Destination Address
                        </h3>
                        <p className="text-gray-500">{`${instantFreights?.data[0][0].destination_address}, ${instantFreights?.data[0][0].destination_city}`}</p>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium">Duration</h3>
                        <p className="text-gray-500">{`${instantFreights?.data[0][0].delivery_duration}`}</p>
                      </div>
                    </div>
                  </div>

                  {/* Package Section */}
                  <div className="mt-6">
                    <h2 className="text-lg font-bold mb-4">Package</h2>
                    <div className="flex space-x-6 bg-gray-100 p-4 rounded-lg">
                      <div>
                        <h3 className="text-sm font-medium">Weight</h3>
                        <p className="text-gray-500">{`${instantFreights?.data[0][0].package_weight} kg`}</p>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium">Dimensions</h3>
                        <p className="text-gray-500">{`${instantFreights?.data[0][0].package_size}`}</p>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium">Price Estimated</h3>
                        <p className="text-gray-500">{`${Number(
                          instantFreights?.data[0][0].estimated_price
                        ).toFixed(2)} €`}</p>
                      </div>
                    </div>
                  </div>

                  {/* Pet Section */}
                  {instantFreights?.data[0][0]?.pet_type && (
                    <div className="mt-6">
                      <h2 className="text-lg font-bold mb-4">Pet</h2>
                      <div className="flex space-x-6 bg-gray-100 p-4 rounded-lg">
                        <div>
                          <h3 className="text-sm font-medium">Pet Type</h3>
                          <p className="text-gray-500 capitalize">
                            {instantFreights?.data[0][0]?.pet_type}
                          </p>
                        </div>
                        <div>
                          <h3 className="text-sm font-medium">Pet Size</h3>
                          <p className="text-gray-500 capitalize">
                            {instantFreights?.data[0][0]?.pet_size}
                          </p>
                        </div>
                        <div>
                          <img
                            src={`${BASE_URL_IMG_2}${instantFreights?.data[0][0]?.pet_img}`}
                            alt="pet"
                            className="h-20 rounded object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Map */}
                  <div className="mt-6">
                    <h2 className="text-lg font-bold mb-4">Map</h2>
                    {directionResponse && isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{
                          lat: Number(instantFreights?.data[0][0]?.origin_lat),
                          lng: Number(instantFreights?.data[0][0]?.origin_lng),
                        }}
                        zoom={6}
                      >
                        <Marker
                          position={{
                            lat: Number(
                              instantFreights?.data[0][0]?.origin_lat
                            ),
                            lng: Number(
                              instantFreights?.data[0][0]?.origin_lng
                            ),
                          }}
                        />
                        <Marker
                          position={{
                            lat: Number(
                              instantFreights?.data[0][0]?.destination_lat
                            ),
                            lng: Number(
                              instantFreights?.data[0][0]?.destination_lng
                            ),
                          }}
                        />
                        <DirectionsRenderer directions={directionResponse} />
                      </GoogleMap>
                    ) : (
                      <div className="w-full h-96 bg-gray-200 flex items-center justify-center">
                        <Loader2 className="animate-spin h-5 w-5 text-gray-500" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* </div> */}

              <Sheet>
                <SheetTrigger asChild>
                  <Button
                    variant="outline"
                    className="ml-auto absolute top-0 right-0"
                  >
                    <Settings className="h-4 w-4 mr-2" />
                    Settings{" "}
                  </Button>
                </SheetTrigger>
                <SheetContent className="flex flex-col justify-between">
                  <SheetHeader>
                    <SheetTitle>Instant Service Settings</SheetTitle>
                    <SheetDescription>
                      Make changes to your instant service here.
                    </SheetDescription>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-2 items-center gap-4">
                        <Button
                          disabled={
                            instantFreights?.data[0][0].status !== "created"
                          }
                          onClick={() =>
                            navigate(
                              `/my-instant-freights/edit/${instantFreights?.data[0][0].id}`
                            )
                          }
                          variant={"freightGreen"}
                          className="col-span-4"
                        >
                          <Pencil className="h-4 w-4 mr-2" />
                          <p className="text-sm font-medium">Update Instant </p>
                        </Button>
                        <Button
                          onClick={() =>
                            modalDeleteInstantService.onOpen(
                              "deleteInstantService",
                              instantFreights?.data[0][0].id
                            )
                          }
                          disabled={
                            instantFreights?.data[0][0].status !== "created"
                          }
                          variant={"outline"}
                          className="col-span-4"
                        >
                          <BiTrash className="h-4 w-4 mr-2 text-[#333]" />
                          <p className="text-sm font-medium text-[#333]">
                            Delete Instant{" "}
                          </p>
                        </Button>
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4 ">
                        {instantFreights?.data[0][0].status === "confirmed" && (
                          <Button
                            onClick={() =>
                              modalSubmitInstantReview.onOpen(
                                "submitInstantReview",
                                instantFreights?.data[0][0].id,
                                instantFreights?.data[0][0].carrier_id
                              )
                            }
                            variant={"freightBlue"}
                            className="col-span-4"
                          >
                            <Star className="h-4 w-4 mr-2" />
                            <p className="text-sm font-medium ">Send review</p>
                          </Button>
                        )}
                        {/* </div> */}
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4 border-t pt-4">
                        <Button variant={"outline"} className="col-span-4">
                          <Grip className="h-4 w-4 mr-2" />
                          <p className="text-sm font-medium">
                            {instantFreights?.data[0][0].otp_confirmation}
                          </p>
                        </Button>
                      </div>
                      {instantFreights?.data[0][0].status === "assigned" && (
                        <div className="grid grid-cols-4 items-center gap-4">
                          <Button
                            disabled={
                              instantFreights?.data[0][0].status !== "assigned"
                            }
                            className="col-span-4"
                            variant={"freightGreen"}
                            onClick={
                              () => {
                                navigate(
                                  `/pay-instant-freight/${instantFreights?.data[0][0].id}`
                                );
                              }
                              // modalPayInstantFreight.onOpen(
                              //   "payInstantFreight",
                              //   instantFreights?.data[0][0].id.toString(),
                              //   instantFreights?.data[0][0].estimated_price.toString()
                              // )
                            }
                          >
                            <Wallet className="h-4 w-4 mr-2" />
                            <p className="text-sm font-medium"> Pay now</p>
                          </Button>
                        </div>
                      )}
                    </div>
                  </SheetHeader>

                  <SheetFooter>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Button className="col-span-1" variant={"outline"}>
                        <Phone className="h-4 w-4 " />
                      </Button>
                      <Button
                        className="col-span-3"
                        // onClick={() =>
                        //   modalSendMessage.onOpen("sendMessage", {
                        //     quoteId: quote.quote_id,
                        //     customerId: quote.customerId,
                        //     carrierId: quote.accepted_carr_id,
                        //     receiverId: Number(user_id),
                        //     senderId: quote.accepted_carr_id,
                        //     freightId: quote.freight_id,
                        //     transporterImage: quote.transporter_name,
                        //     transporterName: quote.transporter_name,
                        //     customerName: quote.customer_name,
                        //   })
                        // }
                        variant={"outline"}
                      >
                        <Mail className="h-4 w-4 mr-2" />
                        <p>Send Message</p>
                      </Button>
                    </div>
                  </SheetFooter>
                </SheetContent>
              </Sheet>
            </div>
          </>
        )}
      </div>
      <div className="bg-white shadow rounded-lg mt-4 p-4">
        {instantFreightReviews?.message === "Instant review data not found." ? (
          <div className="py-4 text-center">
            <p className="text-sm font-medium text-gray-500">No reviews</p>
          </div>
        ) : (
          instantFreightReviews?.data?.length > 0 && (
            <div className="py-4">
              <h1 className="text-lg font-semibold text-gray-800 mb-2">
                Reviews
              </h1>
              {instantFreightReviews?.data.map((review: InstantReviewType) => (
                <div
                  key={review.id} // Add a unique key for each review item
                  className="flex items-start bg-gray-50 mb-4 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className="flex-shrink-0">
                    <div className="bg-gray-200 rounded-full h-12 w-12 flex items-center justify-center overflow-hidden">
                      {review.reviewer_img ? (
                        <img
                          alt="profile"
                          className="h-full w-full object-cover"
                          src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${review.reviewer_img}&image_type=profile`}
                        />
                      ) : (
                        <img
                          src={require("../assets/images/user-icon-placeholder.jpg")}
                          className="h-full w-full object-cover"
                          alt="user-icon-placeholder"
                        />
                      )}
                    </div>
                  </div>
                  <div className="ml-4 flex-1">
                    <Rating
                      size={20}
                      count={Number(review.rating)}
                      value={Number(review.rating)}
                      edit={false}
                    />
                    <p className="text-sm text-gray-700 mt-1">
                      {review.review}
                    </p>
                    <p className="text-xs text-gray-500 mt-2">
                      {review.updated_at}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>

      {modalFreightInTransit.isOpen && (
        <ModalInstantFreightInTransit refetch={refetch} />
      )}

      {modalFreightPickingUp.isOpen && (
        <ModalInstantFreightPickingUp refetch={refetch} />
      )}
      {modalFreightConfirmed.isOpen && (
        <ModalCompleteInstantFreight refetch={refetch} />
      )}
    </SidebarLayout>
  );
};

export default ViewInstantFreight;
