import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BASE_URL, CONFIRM_OTP } from "src/api/config";
import { useConfirmBooking } from "src/hooks/use-confirm-booking";
import { cn } from "src/lib/utils";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { z } from "zod";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../ui/input-otp";

const confirmBookingSchema = z.object({
  optNumber: z.string().min(1),
});

interface ModalConfirmBookingProps {
  refetch: () => void;
}

const ModalConfirmBooking: React.FC<ModalConfirmBookingProps> = ({
  refetch,
}) => {
  const modalConfirmBooking = useConfirmBooking((state) => state);
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [showError, setShowError] = useState({ show: false, value: "" });
  const [loadingConfirmingOtp, setLoadingConfirmingOtp] = useState(false);
  const [confirmOtpSuccessfully, setConfirmOtpSuccessfully] = useState(false);

  const confirmBookingReason = useForm<z.infer<typeof confirmBookingSchema>>({
    resolver: zodResolver(confirmBookingSchema),
    defaultValues: {
      optNumber: "",
    },
  });

  const confirmOtp = async (otpNumber: string) => {
    setLoadingConfirmingOtp(true);
    const response = await fetch(
      `${BASE_URL}${CONFIRM_OTP}?user_id=${user_id}&freight_id=${modalConfirmBooking.freightId}&otp=${otpNumber}`,
      {
        method: "POST",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (
          data.message === "OTP validated and user_carbon updated successfully"
        ) {
          setConfirmOtpSuccessfully(true);
          toast.success(data.message);
          setTimeout(() => {
            modalConfirmBooking.onClose();
          }, 2000);
          refetch();
        } else {
          toast.error(`Error confirming OTP. Try again!`);
          // setShowError({
          //   show: true,
          //   value: "Error confirming OTP. Try again!",
          // });
        }
      })
      .finally(() => {
        setLoadingConfirmingOtp(false);
      });

    return response;
  };

  const onSubmit = async (values: z.infer<typeof confirmBookingSchema>) => {
    confirmOtp(values.optNumber);
  };
  return (
    <Dialog
      open={modalConfirmBooking.isOpen}
      onOpenChange={modalConfirmBooking.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-start">
            <div className="flex items-center border-b w-full mb-4 pb-4">
              <h1>Confirm Booking</h1>
            </div>
            <Form {...confirmBookingReason}>
              <form
                onSubmit={confirmBookingReason.handleSubmit(onSubmit)}
                className="w-full flex flex-col justify-center items-center"
              >
                <FormField
                  name="optNumber"
                  control={confirmBookingReason.control}
                  render={({ field }) => (
                    <FormControl className="">
                      <FormItem className="">
                        <FormLabel>Verify OTP Number</FormLabel>
                        <InputOTP {...field} maxLength={6}>
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                          </InputOTPGroup>
                          <InputOTPSeparator />
                          <InputOTPGroup>
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                        {/* <Input
                          {...field}
                          name="otpNumber"
                          className="w-full dark:bg-transparent"
                        /> */}
                      </FormItem>
                    </FormControl>
                  )}
                />
                {/* {showError.show && (
                  <h1 className="text-sm font-medium text-left text-red-500 mt-2">
                    {showError.value}
                  </h1>
                )} */}
                <Button
                  className={cn(
                    "mt-4 w-full",
                    confirmOtpSuccessfully && "bg-green-500"
                  )}
                  variant={"freightBlue"}
                >
                  {loadingConfirmingOtp ? (
                    <>
                      <Loader2 className="animate-spin h-5 w-5" />
                    </>
                  ) : (
                    <>
                      {confirmOtpSuccessfully ? (
                        <Check className="h-5 w-5 text-white" />
                      ) : (
                        <p className="text-sm font-medium">Confirm</p>
                      )}
                    </>
                  )}
                </Button>
              </form>
            </Form>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalConfirmBooking;
