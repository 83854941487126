import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FaPencil, FaSpinner } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  EXPIRE_DAYS_URL,
  FREIGHT_DETAIL,
  GET_ALL_SIZES,
  GET_IMAGE,
  GOOGLE_API_KEY,
} from "../api/config";
import small from "../assets/images/books-box.png";
import large from "../assets/images/moving-box.png";
import medium from "../assets/images/shoes-box.png";
import { selectedCategory, selectedSubcategory } from "../atoms/atoms";
import Header from "../components/header/Header";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { ExpireOption, FormDataFreights, Size } from "../types/types";

const INITIAL_STATE_FORM_DATA: FormDataFreights = {
  description: "",
  origin_address: "",
  origin_lat: null,
  origin_lng: null,
  destination_address: "",
  destination_lat: null,
  destination_lng: null,
  start_date: "",
  end_date: "",
  expire_date: "",
  freight_type: 1,
  origin_location_type: "",
  size_id: null,
  delivery_duration: "",
  delivery_distance: "",
};
export const formatDateForInput = (dateString: string) => {
  if (dateString === "") return "";
  const [day, month, year] = dateString?.split("-");

  return `${year}-${month}-${day}`;
};

const EditNewFreight = () => {
  const params = useParams();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const [categoryId, setCategoryId] = useAtom(selectedCategory);
  const [subCategoryId, setSubcategoryId] = useAtom(selectedSubcategory);
  const [selectedSizeId, setSelectedSizeId] = useState<number | null>(null);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [loadingSizes, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormDataFreights>(
    INITIAL_STATE_FORM_DATA
  );
  const expireDateRef = useRef<HTMLSelectElement>(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [loadingCreationRoute, setLoadingCreationRoute] = useState(false);
  const [expireOptions, setExpireOptions] = useState<ExpireOption[]>([]);
  const [expireDate, setExpireDate] = useState("");
  const originInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });
  const originAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(
    null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [updatingFreight, setUpdatingFreight] = useState(false);
  const navigate = useNavigate();
  const destinationAutocompleteRef =
    useRef<google.maps.places.Autocomplete | null>(null);
  const [imagePreview, setImagePreview] = useState<any>();

  const getFreightDetails = async () => {
    const response = await fetch(
      `${BASE_URL}${FREIGHT_DETAIL}/${params?.id}/${user_id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());

    return response?.data[0];
  };
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const getAllSizes = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}${GET_ALL_SIZES}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch sizes");
      }
      const data = await response.json();
      setSizes(data.data);
    } catch (error) {
      console.error("Error fetching sizes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getExpiredDays();
    getAllSizes();
  }, []);

  const { data: freightDetails, isLoading: isLoadingFreight } = useQuery({
    queryKey: ["freight_details", params?.id],
    queryFn: getFreightDetails,
  });

  useEffect(() => {
    if (!isLoadingFreight) {
      setFormData({
        delivery_distance: freightDetails?.delivery_distance,
        delivery_duration: freightDetails?.delivery_duration,
        destination_address: freightDetails?.destination_address,
        description: freightDetails?.description,
        end_date: formatDateForInput(freightDetails?.end_date),
        start_date: formatDateForInput(freightDetails?.start_date),
        destination_lat: freightDetails?.destination_lat,
        destination_lng: freightDetails?.destination_lng,
        expire_date: freightDetails?.expire_date,
        origin_address: freightDetails?.origin_address,
        origin_lat: freightDetails?.origin_lat,
        origin_lng: freightDetails?.origin_lng,
        origin_location_type: freightDetails?.origin_location_type,
        size_id: freightDetails?.freight_type_info.freight_size?.id,
        freight_type: freightDetails?.freight_type_info.freight_category,
        animal_img: freightDetails?.animal_img,
        comments: "",
        freight_weight: freightDetails?.freight_weight,
        has_cage: freightDetails?.has_cage,
      });
      setSelectedSizeId(freightDetails?.freight_type_info.freight_size?.id);
    }
  }, [freightDetails, isLoadingFreight]);

  const handleCardClick = (id: number) => {
    setSelectedSizeId(id);
  };
  const getImageForSize = (name: string) => {
    switch (name) {
      case "small":
        return small;
      case "medium":
        return medium;
      case "large":
        return large;
      default:
        return null;
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addDaysToDate = (dateString: string, daysToAdd: number) => {
    const [day, month, year] = dateString.split("-");

    const date = new Date(`${year}-${month}-${day}`);

    date.setDate(date.getDate() + Number(daysToAdd));

    const updatedDay = String(date.getDate()).padStart(2, "0");
    const updatedMonth = String(date.getMonth() + 1).padStart(2, "0");
    const updatedYear = date.getFullYear();

    setExpireDate(`${updatedDay}-${updatedMonth}-${updatedYear}`);
  };

  async function calculateRoute() {
    setLoadingCreationRoute(true);
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: {
        lat: Number(formData.origin_lat),
        lng: Number(formData.origin_lng),
      },
      destination: {
        lat: Number(formData.destination_lat),
        lng: Number(formData.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    const leg = results.routes[0].legs[0];

    if (leg.distance && leg.duration) {
      setDistance(leg.distance.text);
      setDuration(leg.duration.text);

      setFormData((prevData: any) => ({
        ...prevData,
        delivery_distance: leg.distance?.text || null,
        delivery_duration: leg.duration?.text || null,
      }));
    }
    setUpdatingFreight(true);

    setTimeout(() => {
      setLoadingCreationRoute(false);
      if (freightDetails?.freight_type_info?.freight_category === "Animals") {
        handleUpdateFreightPet();
      } else {
        handleUpdateFreight();
      }
    }, 2000);
  }
  const formDataPet = new FormData();

  formDataPet.append("animal_img", formData.animal_img!);

  const handleUpdateFreightPet = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        AccessToken: token,
      },
      body: formDataPet,
    };

    try {
      const response = await fetch(
        `${BASE_URL}freight/updateFreight?user_id=${user_id}&freightid=${
          params.id
        }&has_cage=${formData.has_cage ? "1" : "0"}&freight_weight=${
          formData.freight_weight
        }&description=${formData.description}`,

        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to create freight");
      }
      const data = await response.json();
      setUpdatingFreight(false);
      toast.success("Freight updated successfully!");
      navigate("/dashboard-customer");
    } catch (error) {
      console.error("Error creating freight:", error);
      toast.error("Failed to create freight.");
    }
  };
  const handleNext = (e: React.FormEvent) => {
    e.preventDefault();
    calculateRoute();
  };

  const handlePlaceSelect = (
    autocompleteRef: React.RefObject<google.maps.places.Autocomplete>,
    type: "origin" | "destination"
  ) => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const address = place.formatted_address || "";

      if (type === "origin") {
        setFormData((prevData) => ({
          ...prevData,
          origin_address: address,
          origin_lat: lat,
          origin_lng: lng,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          destination_address: address,
          destination_lat: lat,
          destination_lng: lng,
        }));
      }
    }
  };

  const getExpiredDays = async () => {
    try {
      const response = await fetch(`${BASE_URL}${EXPIRE_DAYS_URL}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch expiration days");
      }
      const data = await response.json();
      setExpireOptions(data.data);
    } catch (error) {
      console.error("Error fetching expiration days:", error);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    calculateRoute();
  };

  const handleUpdateFreight = async () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        AccessToken: token,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${BASE_URL}freight/updateFreight?user_id=${user_id}&freightid=${
          params?.id
        }&description=${formData.description}&start_date=${
          formData.start_date
        }&end_date=${formData.end_date}&expire_date=${formatDateForInput(
          expireDate
        )}&origin_address=${formData.origin_address}&origin_lat=${
          formData.origin_lat
        }&origin_lng=${formData.origin_lng}&destination_address=${
          formData.destination_address
        }&destination_lat=${formData.destination_lat}&origin_location_type=${
          formData.origin_location_type
        }&freight_type=${
          formData.freight_type ?? "package"
        }&delivery_distance=${formData.delivery_distance}&delivery_duration=${
          formData.delivery_duration
        }&size_id=${selectedSizeId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to create freight");
      }
      const data = await response.json();
      setUpdatingFreight(false);
      toast.success("Freight updated successfully!");
      navigate("/dashboard-customer");
    } catch (error) {
      console.error("Error creating freight:", error);
      toast.error("Failed to create freight.");
    }
  };

  const getTodayDateFormatted = () => {
    const today = new Date();

    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      setFormData((prevData: any) => ({
        ...prevData,
        animal_img: file,
      }));

      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    }
  };

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  if (freightDetails?.freight_type_info?.freight_category === "Animals") {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="sm:flex flex-col py-10 h-[94vh] overflow-y-auto no-scrollbar">
          <div className=" max-w-4xl mx-auto justify-between w-full border-border border-b pb-3 mt-10 space-x-2 mb-4">
            <h1 className="text-lg font-semibold mb-4">Edit Freight</h1>
            <form onSubmit={handleNext} className="flex flex-col gap-4">
              <div className="flex flex-col ">
                <label> Pet Image</label>
                <div className="relative w-full flex">
                  <input
                    ref={fileInputRef} // Ref to the file input
                    type="file"
                    name="animal_img"
                    onChange={handleFileChange}
                    className="p-2 border rounded-md w-full opacity-0 absolute top-0 left-0 cursor-pointer h-full"
                    accept="image/*"
                  />

                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      onClick={handleImageClick} // Clicking the image opens file input
                      className="h-48 w-48 object-cover border rounded-md cursor-pointer"
                    />
                  ) : (
                    <div
                      className="h-48 w-48 border border-dashed rounded-md flex items-center justify-center cursor-pointer"
                      onClick={handleImageClick}
                    >
                      <p className="text-gray-500">Click to add image</p>
                    </div>
                  )}
                  {formData.animal_img !== null && (
                    <div className="">
                      <img
                        src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${formData.animal_img}&image_type=freight`}
                        alt="animal"
                        className="h-48 w-48 object-cover border rounded-md opacity-80"
                      />
                      <div
                        onClick={handleImageClick}
                        className="absolute top-2 right-2 bg-gray-50 p-1 rounded-full hover:scale-105 transition cursor-pointer"
                      >
                        <FaPencil className="h-4 w-4 " />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <textarea
                name="description"
                placeholder="Description"
                value={formData.description}
                onChange={handleInputChange}
                className="p-2 border rounded-md w-full"
                required
              />
              <>
                <div className="flex gap-4">
                  <div className="flex flex-col  w-full  ">
                    <label>Start Date</label>
                    <input
                      type="date"
                      name="start_date"
                      placeholder="Start Date"
                      value={formData.start_date}
                      onChange={handleInputChange}
                      className="p-2 border rounded-md w-full"
                      required
                    />
                  </div>
                  <div className="flex flex-col  w-full  ">
                    <label>End Date</label>

                    <input
                      type="date"
                      name="end_date"
                      placeholder="End Date"
                      value={formData.end_date}
                      onChange={handleInputChange}
                      className="p-2 border rounded-md w-full"
                      required
                    />
                  </div>
                </div>

                <div className="w-full flex gap-4   ">
                  <div className="flex flex-col w-1/2  ">
                    <label>Origin</label>
                    <Autocomplete
                      className="w-full  "
                      onLoad={(autocomplete) =>
                        (originAutocompleteRef.current = autocomplete)
                      }
                      onPlaceChanged={() =>
                        handlePlaceSelect(originAutocompleteRef, "origin")
                      }
                      options={{
                        fields: [
                          "address_components",
                          "geometry",
                          "formatted_address",
                          "types",
                        ],
                        componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                      }}
                    >
                      <input
                        ref={originInputRef}
                        type="text"
                        value={formData.origin_address}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            origin_address: e.target.value,
                          }))
                        }
                        placeholder="Origin Address"
                        className="h-10 bg-white border w-full px-2 rounded-md"
                      />
                    </Autocomplete>
                  </div>

                  <div className="flex flex-col w-1/2  ">
                    <label>Destination</label>
                    <Autocomplete
                      className="w-full "
                      onLoad={(autocomplete) =>
                        (destinationAutocompleteRef.current = autocomplete)
                      }
                      onPlaceChanged={() =>
                        handlePlaceSelect(
                          destinationAutocompleteRef,
                          "destination"
                        )
                      }
                      options={{
                        fields: [
                          "address_components",
                          "geometry",
                          "formatted_address",
                          "types",
                        ],
                        componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                      }}
                    >
                      <input
                        ref={destinationInputRef}
                        type="text"
                        value={formData.destination_address}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            destination_address: e.target.value,
                          }))
                        }
                        placeholder="Destination Address"
                        className="h-10 bg-white border w-full px-2 rounded-md"
                      />
                    </Autocomplete>
                  </div>
                </div>

                <div className="flex flex-col w-1/2  pr-2">
                  <label>Weight</label>
                  <input
                    type="number"
                    name="freight_weight"
                    placeholder="Freight Weight"
                    value={formData.freight_weight}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md w-full"
                    required
                  />
                </div>

                <div className="">
                  <input
                    type="checkbox"
                    checked={formData.has_cage}
                    onChange={() => {
                      if (formData.has_cage) {
                        setFormData((prev) => ({
                          ...prev,
                          has_cage: false,
                        }));
                      } else {
                        setFormData((prev) => ({
                          ...prev,
                          has_cage: true,
                        }));
                      }
                    }}
                    name="has_cage"
                    className="mr-2"
                  />
                  <label htmlFor="">Can you provide a cage?</label>
                </div>
              </>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="p-2 bg-blue-500 w-[100px] text-white rounded-md"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  if (isLoadingFreight)
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="sm:flex flex-col py-10 h-[94vh] overflow-y-auto no-scrollbar">
          <div className="  max-w-4xl mx-auto justify-between w-full border-border border-b pb-3 mt-10 space-x-2 mb-4">
            <h1 className="text-lg font-semibold mb-4">Edit Freight</h1>
            <FaSpinner className="h-6 w-6 animate-spin" />
          </div>
        </div>
      </div>
    );
  return (
    <div>
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="sm:flex flex-col py-10 h-[94vh] overflow-y-auto no-scrollbar">
          <div className="  max-w-4xl mx-auto justify-between w-full border-border border-b pb-3 mt-10 space-x-2 mb-4">
            <h1 className="text-lg font-semibold mb-4">Edit Freight</h1>
            {loadingSizes ? (
              <div>
                <FaSpinner className="h-5 w-5 animate-spin" />
              </div>
            ) : (
              <div className="flex gap-4">
                {sizes.map((size) => (
                  <div
                    key={size?.id}
                    onClick={() => handleCardClick(size?.id)}
                    className={`flex items-center gap-4 border px-4 rounded-md bg-white cursor-pointer ${
                      selectedSizeId === size?.id
                        ? "shadow-blue-500 shadow-lg"
                        : "shadow-md"
                    } hover:shadow-blue-300 hover:shadow-lg transition-shadow duration-300`}
                  >
                    <img
                      src={getImageForSize(size.name)}
                      alt={size.name}
                      className="h-28 w-28 object-contain"
                    />
                    <div>
                      <p className="font-bold flex gap-2 capitalize">
                        {size.name}
                      </p>
                      <p>
                        {size.length} x {size.width} x {size.height} cm
                      </p>
                      <p>{size.weight} kg</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 mt-8 rounded-md w-full"
            >
              <h3 className="text-xl font-semibold">
                Complete Freight Details
              </h3>
              <p className="font-semibold">Dates</p>
              <div className="flex gap-4">
                <input
                  type="date"
                  name="start_date"
                  placeholder="Start Date"
                  value={formData.start_date}
                  onChange={handleInputChange}
                  onClick={(e) => e.currentTarget.showPicker()} // Opens the date picker
                  className="p-2 border rounded-md w-1/2"
                  required
                />
                <input
                  type="date"
                  name="end_date"
                  placeholder="End Date"
                  value={formData.end_date}
                  onChange={handleInputChange}
                  onClick={(e) => e.currentTarget.showPicker()} // Opens the date picker
                  className="p-2 border rounded-md w-1/2"
                  required
                />
              </div>
              <p className="font-semibold">Address Information</p>
              {isLoaded && (
                <div className="flex flex-col gap-4">
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (originAutocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={() =>
                      handlePlaceSelect(originAutocompleteRef, "origin")
                    }
                    options={{
                      fields: [
                        "address_components",
                        "geometry",
                        "formatted_address",
                        "types",
                      ],
                      componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                    }}
                  >
                    <input
                      ref={originInputRef}
                      type="text"
                      value={formData.origin_address}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          origin_address: e.target.value,
                        }))
                      }
                      placeholder="Origin Address"
                      className="p-2 border rounded-md w-full"
                    />
                  </Autocomplete>
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (destinationAutocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={() =>
                      handlePlaceSelect(
                        destinationAutocompleteRef,
                        "destination"
                      )
                    }
                    options={{
                      fields: [
                        "address_components",
                        "geometry",
                        "formatted_address",
                        "types",
                      ],
                      componentRestrictions: { country: "ES" }, // Restrict to the US, adjust as needed
                    }}
                  >
                    <input
                      ref={destinationInputRef}
                      type="text"
                      value={formData.destination_address}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          destination_address: e.target.value,
                        }))
                      }
                      placeholder="Destination Address"
                      className="p-2 border rounded-md w-full"
                    />
                  </Autocomplete>

                  <input
                    type="text"
                    name="origin_location_type"
                    placeholder="Location Type (Building, Office, House...)"
                    value={formData.origin_location_type}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md w-full"
                    required
                  />
                </div>
              )}

              <p className="font-semibold">Expiration Date</p>

              <select
                ref={expireDateRef}
                name="expire_date"
                value={expireDateRef.current?.value ?? ""}
                onChange={(e) => {
                  addDaysToDate(
                    getTodayDateFormatted(),
                    Number(e.target.value)
                  );
                }}
                className="p-2 border rounded-md w-full"
                required
              >
                {expireOptions.map((option) => (
                  <option key={option?.id} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>

              <p className="font-semibold">Additional Information</p>

              <textarea
                name="description"
                placeholder="Description"
                value={formData.description}
                onChange={handleInputChange}
                className="p-2 border rounded-md h-44 w-full resize-none"
                maxLength={150}
                required
              />
              <div className="w-full flex justify-end">
                <button
                  disabled={updatingFreight}
                  type="submit"
                  className="p-2 bg-[#1889ca] text-white rounded-md w-[150px] flex items-center justify-center"
                >
                  {updatingFreight ? (
                    <>
                      <FaSpinner className="h-5 w-5 animate-spin " />
                    </>
                  ) : (
                    <>Save</>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNewFreight;
