import { useQuery } from "@tanstack/react-query";
import {
  ArrowLeft,
  ArrowRight,
  Ban,
  Loader2,
  SquareArrowUpRight,
} from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, BASE_URL_IMG_2, INSTANT_FREIGHTS } from "../api/config";
import SidebarLayout from "../components/layout/SidebarLayout";
import ModalCancelInstantFreight from "../components/modal/ModalCancelInstantFreight";
import ModalInstantFreightPaid from "../components/modal/ModalInstantFreightPaid";
import ModalPayInstantFreight from "../components/modal/ModalPayInstantFreight";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useCancelInstantFreightModal } from "../hooks/use-cancel-instant-freight";
import { useInstantFreightPaid } from "../hooks/use-instant-freight-paid";
import { usePayInstantFreight } from "../hooks/use-pay-instant-freight";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { InstantFreight } from "../types/types";
//@ts-ignore
import SmallBox from "../assets/images/books-box.png";
//@ts-ignore
import MediumBox from "../assets/images/shoes-box.png";
//@ts-ignore
import LargeBox from "../assets/images/moving-box.png";

const CustomerInstantFreights = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const modal = useCancelInstantFreightModal();
  const modalFreightPaid = useInstantFreightPaid();
  const modalPayInstantFreight = usePayInstantFreight();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<"ASC" | "DESC">("DESC");

  const navigate = useNavigate();
  const getInstantFreights = async () => {
    const response = await fetch(
      `${BASE_URL}${INSTANT_FREIGHTS}?customer_id=${user_id}&page=${page}&items_per_page=5&sort=${sortBy}&user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());
    return response;
  };

  const {
    data: instantFreights,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["instant_freights", page, sortBy],
    queryFn: getInstantFreights,
  });

  const sortOptions = [
    {
      value: "ASC",
      label: "Ascendent",
    },
    {
      value: "DESC",
      label: "Descendent",
    },
  ];

  return (
    <SidebarLayout>
      <h1 className="text-lg font-semibold border-b border-border pb-3 mb-4">
        Instant Freights
      </h1>
      <div className=" bg-white shadow rounded p-4">
        {instantFreights?.message ===
        "No instant freights found for this customer_id" ? (
          <></>
        ) : (
          <div className="mt-4 ml-auto w-full justify-end flex space-x-2 mb-4">
            <Select
              onValueChange={(val: any) => {
                setSortBy(val);
              }}
              value={sortBy}
            >
              <SelectTrigger className="w-40 h-8">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                {sortOptions.map((sort) => (
                  <SelectItem key={sort.value} value={sort.value}>
                    {sort.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              disabled={page === 1}
              onClick={() => setPage(1)}
              className={cn(
                "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                page === 1 && "bg-[#333] text-white"
              )}
            >
              <p className="text-sm font-medium">1</p>
            </Button>
            <div
              onClick={() => {
                if (page > 1) {
                  setPage((page) => page - 1);
                }
              }}
              className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
            >
              <ArrowLeft className="h-4 w-4 text-[#333]" />
            </div>
            <div className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition">
              {" "}
              <p className="text-sm font-medium">{page}</p>
            </div>
            <div
              onClick={() => setPage((page) => page + 1)}
              className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
            >
              <ArrowRight className="h-4 w-4 text-[#333]" />
            </div>
            <Button
              disabled={page === instantFreights?.data?.total_pages}
              onClick={() => setPage(instantFreights?.data?.total_pages)}
              className={cn(
                "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                page === instantFreights?.data?.total_pages &&
                  "bg-[#333] text-white"
              )}
            >
              {isLoading ? (
                <p>
                  <Loader2 className="h-5 w-5 animate-spin" />
                </p>
              ) : (
                <p className="text-sm font-medium">
                  {instantFreights?.data?.total_pages}
                </p>
              )}
            </Button>
          </div>
        )}

        <Table className="border border-border">
          <TableHeader>
            <TableRow>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Package to deliver
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Origin/Destination
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Last Updated
              </TableHead>
              <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </TableHead>
            </TableRow>
          </TableHeader>
          {isLoading ? (
            <TableBody>
              {[...Array(5)].map((_, index) => (
                <TableRow key={index} className="border-b border-gray-200">
                  <TableCell>
                    <Skeleton className="h-5 w-10 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-20 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-60 bg-gray-200 rounded" />
                    <Skeleton className="h-5 w-60 mt-4 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-24 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-32 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-28 bg-gray-200 rounded" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <>
              {instantFreights?.message ===
              "No instant freights found for this customer_id" ? (
                <TableBody className="flex items-center p-4 justify-center w-full ">
                  <TableRow>
                    <p className="text-sm font-medium text-gray-500 mt-4">
                      No instant freight found
                    </p>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {instantFreights?.data?.[0]?.length > 0 &&
                    instantFreights?.data?.[0]?.map(
                      (freight: InstantFreight) => (
                        <TableRow
                          key={freight.id}
                          className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                        >
                          <TableCell className="p-4">
                            <div className="flex items-start space-x-4">
                              <div className="flex flex-col space-y-2">
                                <p className="text-sm font-semibold">
                                  {freight.package_weight} kg
                                </p>
                                <p className="text-sm text-gray-600 capitalize">
                                  {freight.package_size}
                                </p>
                                <p className="text-sm font-medium text-gray-800">
                                  Est. {freight.estimated_price}€
                                </p>
                                {freight.package_size ? (
                                  <div
                                    onClick={() =>
                                      navigate(
                                        `/instant-freights/${freight.id}`
                                      )
                                    }
                                    className="bg-gray-100 border border-gray-300 rounded-lg p-2 hover:bg-gray-200 transition cursor-pointer"
                                  >
                                    <img
                                      src={
                                        freight.package_size === "small"
                                          ? SmallBox
                                          : freight.package_size === "medium"
                                          ? MediumBox
                                          : freight.package_size === "large"
                                          ? LargeBox
                                          : null // Optional: Handle case where package_size is not small, medium, or large
                                      }
                                      className="h-16 w-16 object-contain"
                                      alt={freight.package_size || "pet"} // Conditional alt text
                                    />
                                  </div>
                                ) : (
                                  <img
                                    alt="pet"
                                    className="h-20 w-20 object-cover rounded-lg border border-gray-300"
                                    src={`${BASE_URL_IMG_2}${freight?.pet_img}`}
                                  />
                                )}
                              </div>
                              {freight.trip_type === "pet" && (
                                <div className="flex flex-col space-y-2">
                                  <p className="text-sm font-semibold">
                                    {freight.pet_weight} kg
                                  </p>
                                  <p className="text-sm text-gray-600 capitalize">
                                    {freight.pet_type}
                                  </p>
                                  <p className="text-sm font-medium text-gray-800">
                                    {freight.has_cage === 1
                                      ? "Available Cage"
                                      : "Unavailable Cage"}
                                  </p>
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell className="p-4">
                            <div className="flex space-x-4 bg-gray-100 rounded-lg p-2 border border-gray-300">
                              <div className="flex flex-col space-y-2 ">
                                <p className="text-xs font-medium text-gray-600">
                                  Origin
                                </p>
                                <p className="text-sm font-medium text-gray-800 truncate">
                                  {freight.origin_city}
                                </p>
                              </div>
                              <div className="flex flex-col space-y-2 ">
                                <p className="text-xs font-medium text-gray-600">
                                  Destination
                                </p>
                                <p className="text-sm font-medium text-gray-800 truncate">
                                  {freight.destination_city}
                                </p>
                              </div>
                            </div>
                            <div className="flex space-x-4 mt-2 bg-gray-100 rounded-lg p-2 border border-gray-300">
                              <div className="flex flex-col space-y-2 ">
                                <p className="text-xs font-medium text-gray-600">
                                  Distance
                                </p>
                                <p className="text-sm font-medium text-gray-800">
                                  {freight.delivery_distance}
                                </p>
                              </div>
                              <div className="flex flex-col space-y-2 ">
                                <p className="text-xs font-medium text-gray-600">
                                  Duration
                                </p>
                                <p className="text-sm font-medium text-gray-800">
                                  {freight.delivery_duration}
                                </p>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="p-4">
                            <div className="flex ">
                              <div
                                className={cn(
                                  "rounded-lg px-4 py-2 text-xs font-semibold text-white text-center capitalize",
                                  freight.status === "created" && "bg-blue-500",
                                  freight.status === "assigned" &&
                                    "bg-yellow-500",
                                  freight.status === "paid" && "bg-green-500",
                                  freight.status === "picking-up" &&
                                    "bg-orange-500",
                                  freight.status === "in-transit" &&
                                    "bg-teal-500",
                                  freight.status === "finished" &&
                                    "bg-purple-500",
                                  freight.status === "confirmed" &&
                                    "bg-indigo-500",
                                  freight.status === "cancelled" &&
                                    "bg-gray-500"
                                )}
                              >
                                {freight.status}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="p-4  text-gray-500">
                            {freight.updated_at}
                          </TableCell>
                          <TableCell className="p-4 relative">
                            <SquareArrowUpRight
                              onClick={() =>
                                navigate(`/instant-freights/${freight.id}`)
                              }
                              className="absolute top-1/2 right-4 transform -translate-y-1/2 h-5 w-5 text-gray-600 cursor-pointer"
                            />
                            {freight.status !== "finished" &&
                              freight.status !== "confirmed" &&
                              freight.status !== "cancelled" && (
                                <Button
                                  className="w-32"
                                  onClick={() =>
                                    modal.onOpen(
                                      "cancelInstantFreight",
                                      freight.id.toString()
                                    )
                                  }
                                  variant="outline"
                                >
                                  <Ban className="h-4 w-4 text-gray-400 mr-2" />
                                  <span className="text-gray-400">Cancel</span>
                                </Button>
                              )}
                            {freight.is_reviewed.toString() === "1" && (
                              <div className="bg-gray-100 p-2 mt-2 rounded-lg">
                                <p className="text-sm font-medium text-gray-600 text-center">
                                  Reviewed
                                </p>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              )}
            </>
          )}
        </Table>
      </div>
      {modal.isOpen && <ModalCancelInstantFreight refetch={refetch} />}
      {modalFreightPaid.isOpen && <ModalInstantFreightPaid refetch={refetch} />}
      {modalPayInstantFreight.isOpen && (
        <ModalPayInstantFreight refetch={refetch} />
      )}
    </SidebarLayout>
  );
};

export default CustomerInstantFreights;
